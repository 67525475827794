@use '../abstracts' as *;

/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .bg-wh .img_text--child:only-child,
.lps_parts--img_text .text,
.lps_parts--img_text .img_text--txt table {
  width: 100%;
}
.lps_parts--img_text .img_text--child .img {
  height: 100%;
}
.lps_parts--img_text .img_text--img:only-child .img {
  display: table;
  @include auto-margin;
}
.lps_parts--img_text .img_text--txt {
  @include mq(sp) {
    padding-top: rem(40);
  }
}
.lps_parts--img_text .ttl-03 {
  font-size: rem(36);
  line-height: 1.555;
  @include mq(sp) {
    font-size: sprem(22);
  }
  
}
.lps_parts--img_text .bg-wh .ttl-03 {
  font-size: rem(24);
  @include mq(sp) {
    font-size: sprem(20);
  }
}

.lps_parts--img_text .bg-wh .img_text--txt {
  padding: perc(64,1200,'%');
  @include mq(sp) {
    padding: sprem(24);
  }
}
.lps_parts--img_text .inner {
  &:not(.flex-reverse) .img_text--txt {
    @include mq(sp, min, ps) {
      padding-left: perc(64,1200,'%');
    }
  }
  &.flex-reverse .img_text--txt {
    @include mq(sp, min, ps) {
      padding-right: perc(64,1200,'%');
    }
  }
}
.lps_parts--img_text .ttl_deco--wrap {
  display: inline-flex;
  align-items: flex-start;
  color: var(--clr-main);
  font-weight: 600;
  position: absolute;
  left: 0;
  top: 0;
  .num {
    line-height: .6;
    font-size: rem(160);
    font-family: var(--font-en3);
    font-weight: 500;
    letter-spacing: .045em;
    @include mq(sp) {
      font-size: sprem(70);
    }
  }
  .txt {
    display: inline-block;
    font-size: rem(11);
    letter-spacing: .16em;
    position: relative;
    font-family: var(--font-en);
    font-weight: 600;
    padding-bottom: rem(10);
    @include mq(sp) {
      margin-left: sprem(6);
    }
  }
  .txt::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: rem(24);
    height: 1px;
    background-color: var(--bg-main);
  }
}
.lps_parts--img_text .ttl-03:has(.ttl_deco--wrap) {
  padding-left: rem(190);
  padding-top: rem(62);
  @include mq(sp) {
    padding-left: sprem(90);
    padding-top: sprem(30);
  }
}

/* --- L --- */
.lps_parts--img_text .inner-lg {
  max-width: rem(1420);
  .img_text--img {
    position: absolute;
    top: 0;
    @include mq(sp, min, ps) {
      height: 100%;
    }
    @include mq(sp) {
      position: relative;
    }
  }
  &:not(.flex-reverse) .img_text--img {
    left: 0;
  }
  &.flex-reverse .img_text--img {
    right: 0;
  }
  .img_text--txt {
    @include mq(sp, min, ps) {
      min-height: rem(607.64);
    }
    @include mq(sp) {
      margin-right: auto;
    }
  }
  &:not(.flex-reverse) .img_text--txt {
    margin-left: auto;
    @include mq(sp, min, ps) {
      padding-left: perc(110,1456,'%');
      padding-right: 0;
    }
  }
  &.flex-reverse .img_text--txt {
    margin-right: auto;
    @include mq(sp, min, ps) {
      padding-left: 0;
      padding-right: perc(110,1456,'%');
    }
  }
  &.bg-wh .img_text--txt {
    @include mq(sp) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: sprem(40);
    }
  }
  .ttl-03 {
    font-size: rem(36);
    line-height: 1.555;
    @include mq(sp) {
      font-size: sprem(22);
    }
  }
}

// 角丸設定 ==================================

// /* 白背景 */
// .lps_parts--img_text .lps_parts--child.bg-wh {
//   border-radius: rem(20);
//   .img_text--img img {
//     border-radius: rem(20) 0 0 rem(20);
//     @include mq(sp) {
//       border-radius: rem(20) rem(20) 0 0;
//     }
//   }
//   @include mq(sp, min) {
//     &.flex-reverse .img_text--img img {
//       border-radius: 0 rem(20) rem(20) 0;
//     }
//   }
// }

// /* 白背景なし */
// .lps_parts--img_text .lps_parts--child:not(.bg-wh) .img_text--img img {
//   border-radius: rem(20);
// }

// /* レイアウトL
//    ※Lも全部角丸にしたかったら削除！ */
// .lps_parts--img_text .lps_parts--child.inner-lg {
//   .img_text--img img {
//     border-radius: 0;
//   }
// }
// @include mq(sp, min) {
//   .lps_parts--img_text .lps_parts--child.inner-lg:where(:not(.bg-wh)) {
//     .img_text--img img {
//       border-radius: 0 rem(20) rem(20) 0;
//     }
//     &.flex-reverse .img_text--img img {
//       border-radius: rem(20) 0 0 rem(20);
//     }
//   }
// }

// 角丸設定 ==================================

@include mq(sp, min, ps) {
  .lps_parts--img_text .img_text--txt {
    display: flex;
    align-items: center;
  }
  .lps_parts--img_text .inner-lg .img_text--img:only-child {
    position: relative;
  }
}
@include mq(sp) {
  .lps_parts--img_text .btn {
    text-align: center;
  }
  .lps_parts--img_text .bg-wh + .bg-wh {
    margin-top: 0;
  }
  .lps_parts--img_text .inner-lg .img_text--child .img {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}