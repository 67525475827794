@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
  margin-right: rem(80);
  margin-left: rem(80);
  margin-bottom: rem(100);
  @include mq(sp) {
    margin-right: 0;
    margin-left: sprem(22);
    margin-bottom: 0;
  }
}
.page_ttl--bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 1080px;
  width: 60vw;
  @include mq(sp) {
    width: 87.5vw;
    top: sprem(200);
    bottom: 0;
  }
}
.page_ttl-jp {
  font-size: rem(24);
  color: var(--clr-brn);
  font-family: var(--font-primary);
  font-weight: 700;
  @include mq(sp) {
    font-size: sprem(14);
    margin-bottom: sprem(20);
  }
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: rem(104);
  line-height: .8;
  color: var(--clr-main);
  margin-bottom: rem(26);
  margin-top: rem(74);
  @include mq(sp) {
    font-size: sprem(48);
    margin-top: 0;
  }
}
.page_ttl .scroll_line {
  position: absolute;
  bottom: 0;
  width: 5px;
  right: min(calc(60vw + 50px), 1130px);
  @include mq(sp) {
    left: 0;
    right: auto;
  }
}

.page_ttl_scroll {
  position: absolute;
  margin-top: rem(-222);
  overflow: hidden;
  @include mq(sp) {
    display: none;
  }
}
.page_ttl--bnr {
  display: inline-block;
  position: absolute;
  right: 5rem;
  transform: translateY(-30px);
  z-index: 999;
  width: rem(144);
  @include mq(sp) {
    max-width: sprem(96);
    position: fixed;
    right: 1rem;
    transform: translateY(0);
    bottom: sprem(10);
  }
}
.archive-works .page_ttl--bnr,
.page-contact .page_ttl--bnr {
  display: none;
} 

/* --- page_ttl（タイトル画像なし） --- */
.page_ttl:not(.page_ttl-has_bg) {
  padding-top: rem(54);
  padding-bottom: rem(54);
  text-align: center;
  margin-bottom: 0;
  border-top: 1px solid rgba($color: $clr-main, $alpha: .25);
  border-bottom: 1px solid rgba($color: $clr-main, $alpha: .25);
  @include mq(sp) {
    padding-top: sprem(38);
    padding-bottom: sprem(24);
    margin-right: sprem(22);
  }

  .breadcrumbs {
    position: absolute;
    left: 0;
    top: 0;
  }
  .page_ttl-en {
    font-size: rem(80);
    margin-top: 0;
    @include mq(sp) {
      font-size: sprem(48);
      margin-bottom: sprem(10);
    }
  }
  .page_ttl-jp {
    @include mq(sp) {
      margin-bottom: 0;
    }
  }
  .scroll_line {
    display: none;
  }
}
.page_ttl:not(.page_ttl-has_bg) + .page_ttl_scroll {
  margin-top: rem(-196);
}

/* --- page_ttl（タイトル画像あり） --- */
.page_ttl-has_bg {
  height: rem(514);
  @include mq(sp) {
    height: sprem(514);
  }
  & > .inner {
    height: 100%;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    @include mq(sp) {
      padding-top: sprem(50);
    }
  }
  .breadcrumbs {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: var(--font-primary);
    font-weight: 700;
    color: var(--clr-brn);
    a {
      color: var(--clr-brn);
    }
    @include mq(sp) {
      top: 0;
      bottom: auto;
    }
  }
  .breadcrumbs span span:not(:last-child)::after {
    background-color: var(--bg-brn);
  }
}


/* --- ttl --- */
.ttl-01 {
  margin-bottom: .8em;
}
.ttl-02 {
  position: relative;
  letter-spacing: .04em;
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: 1em;
    @include mq(sp) {
      margin-bottom: 2em;
    }
  }
}
.ttl-03,
.ttl-04 {
  position: relative;
  letter-spacing: .04em;
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: 1em;
  }
}

.ttl-01 {
  font-size: rem(48);
  color: var(--clr-main);
  .sub {
    font-size: rem(32); 
    letter-spacing: .08em;
  }
  .en {
    font-size: rem(88);
    line-height: .8;
    letter-spacing: .04em;
    margin-bottom: rem(10);
  }
  .jp {
    font-size: rem(18);
    letter-spacing: .05em;
    line-height: 1.666;
    color: var(--clr-sub);
  }
  @include mq(sp) {
    font-size: sprem(30);
    .sub {
      font-size: sprem(18); 
    }
    .en {
      font-size: sprem(40);
    }
    .jp {
      font-size: sprem(13);
    }
  }
  & + .ttl-01-sub {
    margin-top: rem(-30);
  }
  &.txt-wh + .ttl-01-sub {
    color: var(--clr-wht);
    &::after {
      background-color: var(--bg-wht);
    }
  }
}
.ttl-01.ttl-sm {
  .en {
    font-size: rem(64);
    @include mq(sp) {
      font-size: sprem(36);
    }
  }
}
.ttl-01-sub {
  color: var(--clr-sub);
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: rem(18);
  letter-spacing: .05em;
  line-height: 2.3;
  @include mq(sp) {
    font-size: sprem(13);
  }
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: perc(48,16,em);
    @include mq(sp) {
      margin-bottom: 2em;
    }
  }
}
.ttl-02 {
  font-size: rem(36);
  letter-spacing: 0.04em;
  line-height: 1.55;
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.ttl-03 {
  font-size: rem(24);
  @include mq(sp) {
    font-size: sprem(16);
  }
}
.ttl-04 {
  font-size: rem(24);
  @include mq(sp) {
    font-size: sprem(16);
  }
}
.lps_sec .ttl-01 span {
  font-size: rem(88);
  line-height: .8;
  letter-spacing: .02em;
  @include mq(sp) {
    font-size: sprem(40);
  }
}
.lps_sec .ttl-02 {
  font-size: rem(36);
  line-height: 1.4;
  @include mq(sp) {
    font-size: sprem(20);
  }
}