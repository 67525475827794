@use '../abstracts' as *;

/* ##############################################################################

    PAGE

############################################################################## */

/* next_page
********************************************** */
.next_page_list {
  li {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  li:nth-last-child(3) {
    background-color: #8a8570;
  }
  li:nth-last-child(2) {
    background-color: #8d684b;
  }
  li:nth-last-child(1) {
    background-color: #5a5c73;
  }
}
.next_page {
  flex: 1;
}
.next_page a {
  overflow: hidden;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  color: var(--clr-wht);
  font-size: rem(24);
  line-height: 1.5;
  padding: rem(54);
  @include mq(sp, min, ps) {
    height: 100%;
    min-height: rem(280);
  }
  @include mq(sp) {
    padding: sprem(24);
    font-size: sprem(16);
  }
  &:hover .next_page-bg {
    opacity: 0.2;
    transform: scale(1.1);
  }
  &::after {
    content: "";
    display: block;
    flex-shrink: 0;
    width: rem(24);
    aspect-ratio: 1;
    border-right: 2px solid;
    border-top: 2px solid;
    transform: rotate(45deg);
    position: absolute;
    right: 10%;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.next_page .en {
  display: block;
  margin-top: 1em;
  font-size: rem(16);
  font-family: var(--font-en2);
  line-height: var(--line-height-none);
  text-transform: uppercase;
  opacity: 0.5;
  color: var(--clr-wht);
  font-weight: 600;
  letter-spacing: 0.09em;
  @include mq(sp) {
    font-size: sprem(13);
  }
}
.next_page-bg {
  position: absolute;
  inset: 0;
  z-index: -1;
  opacity: 0.1;
  transition: var(--transit-default);
}