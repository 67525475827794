@use '../abstracts' as *;
@use 'sass:math';

/* txt
**************************************** */
.txt-lg {
  font-size: rem(18);
  @include mq(sp) {
    font-size: sprem(15);
  }
}
.txt-sm {
  font-size: rem(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
.txt-wh,
.txt-wh * { color: var(--clr-wht); }
.txt-main,
.txt-main * { color: var(--clr-main); }
.txt-sub,
.txt-sub * { color: var(--clr-sub); }

/* --- font-size --- */
// フォントサイズを8pxから140pxまでREMで生成
@for $i from 8 to 140 {
  .fz-#{$i} { font-size: math.div($i, $fz-pc) + rem}
}

/* --- font-family --- */
.font-jp {
  font-family: var(--font-jp);
  font-weight: 500;
}
.font-jp2 {
  font-family: var(--font-primary);
  font-weight: 700;
}
.font-en,
.font-en-sb,
.font-en-m {
  font-family: var(--font-en);
}
.font-en {
  font-weight: 400;
}
.font-en-m {
  font-weight: 500;
}
.font-en-sb {
  font-weight: 600;
}
.font-en2 {
  font-family: var(--font-en2);
  font-weight: 600
}
.font-en3 {
  font-family: var(--font-en3);
  font-weight: 600
}
.font-en3-m {
  font-family: var(--font-en3);
  font-weight: 500;
}

/* --- clip-txt --- */
// 省略行を1から4まで生成
@for $i from 1 through 4 {
  .clip-txt#{$i} {
    @include clip-txt($i);
  }
}

/* txt-ctr
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}
.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@include mq(sp) {
  .txt-ctr-pc,
  .txt-rgt-pc { text-align: left; }
}