@use '../abstracts' as *;

/* ##############################################################################

    SINGLE

############################################################################## */

  /* --- アイキャッチ --- */
  .eyecatch {
    margin-bottom: rem(32);
  }

  /* --- 目次 --- */
  .ez-toc-debug-messages {
    display: none;
  }
  div#ez-toc-container {
    padding: rem(24);
    background-color: #fafafa;
  }
  .ez-toc-title-container {
    margin-bottom: rem(10);
  }
  div#ez-toc-container p.ez-toc-title {
    color: var(--clr-body);
    font-weight: bold;
    font-size: rem(20);
  }
  #ez-toc-container.counter-hierarchy ul {
    font-size: rem(17);
  }
  #ez-toc-container.counter-hierarchy ul li {
    margin-top: rem(8);
  }
  #ez-toc-container.counter-hierarchy ul ul {
    margin-left: rem(16);
  }
  #ez-toc-container.counter-hierarchy ul ul a {
    position: relative;
    display: inline-block;
    padding-left: rem(16);
    &::before {
      content: "";
      position: absolute;
      top: rem(10);
      left: 0;
      width: rem(8);
      height: 0;
      border-bottom: 1px solid;
    }
  }

/* pass
**************************************** */
  #pass p {
    margin-bottom: 1em;
  }
  #pass input[name="post_password"] {
    padding: rem(5);
    border: solid 1px #aaa;
    outline: none;
  }
  #pass input[type="submit"] {
    padding: rem(4) rem(6);
    letter-spacing: .15em;
    background-color: #ddd;
    border-radius: rem(4);
    outline: none;
    border: none;
    cursor: pointer;
    transition: var(--transit-default);
  }
  #pass input[type="submit"]:hover {
    opacity: .7;
  }

/* works
**************************************** */
.single-works .works_deta {
  margin-top: rem(40);
  table {
    width: 100%;
  }
}
.single-works .works_voice {
  margin-top: rem(60);
}
.works--related {
  border-top: 1px solid rgba(97, 100, 119, 0.25);
  padding-top: rem(30);
}
.works--related .ttl-04 {
  color: var(--clr-brn);
}
.has-text-align-center {
  text-align: center;
}
#works_btn a {
  margin-left: auto;
  margin-right: auto;
}

/* blog
**************************************** */
.single-blog .post--date {
    font-size: rem(13);
  }

/* blog--share
**************************************** */
  .blog--share {
    margin-top: rem(32);
  }
  .blog--share_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(6);
    @include mq(sp) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .blog--share_list a {
    @include center-flex;
    height: rem(40);
    padding: .5em;
    line-height: 1;
    font-size: rem(12);
    font-weight: bold;
    border: 1px solid #ddd;
  }
  .blog--share_list li {
    &.twitter a { color: #2BA1F2; }
    &.facebook a { color: #2477F2; }
    &.line a { color: #27C754; }
    &.pocket a { color: #EF4056; }
    &.linkedin a { color: #2867B2; }
    &.hatena a { color: #29A4DE; }
  }
  .blog--share_list img {
    margin-right: rem(10);
  }
  .blog--share_list a:hover {
    opacity: .5;
  }

/* blog--related
**************************************** */
  .posts_scroll {
    overflow: auto;
    padding-bottom: rem(32);
  }
  .posts_scroll .post {
    display: block;
    width: 46%;
    flex-shrink: 0;
    @include mq(sp) {
      width: 70%;
    }
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
  .posts_scroll .post--img {
    margin-bottom: 1rem;
    background-image: var(--ptn-be);
  }
  .posts_scroll .post--txt {
    overflow: hidden; 
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; 
  }
  .posts_scroll::-webkit-scrollbar {
    height: rem(6);
  }
  .posts_scroll::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  .posts_scroll::-webkit-scrollbar-track-piece {
    background-color: #eee;
  }

/* works--related
**************************************** */
  .works--related .posts-works {
    @include mq(sp) {
      grid-template-columns: 1fr 1fr;
      gap: sprem(10);
    }
  }
  .works--related .posts-works .post--img {
    @include mq(sp) {
      aspect-ratio: 2/3;
    }
  }
  .works--related .posts-works .post--ttl {
    @include mq(sp) {
      font-size: sprem(12);
    }
  }


/* wp-pagenavi
**************************************** */
  .wp-pagenavi {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: rem(40);
    @include mq(sp) {
      margin-top: sprem(24);
    }
  }
  .wp-pagenavi .pages {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
  }
  .wp-pagenavi a {
    position: relative;
    padding: .5em 1em;
    background-color: var(--bg-main);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--clr-wht);
    &:hover {
      background-color: var(--bg-main-hvr);
    }
  }
  .archive .wp-pagenavi > *:not(.pages) {
    display: flex;
    justify-content: center;
    align-items: center;
    @include rect(40);
    @include mq(sp) {
      @include rect(32);
    }
  }
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    width: rem(40);
    @include mq(sp) {
      width: sprem(32);
    }
  }
  .wp-pagenavi > *:not(.pages) {
    margin-bottom: rem(4);
  }
  .wp-pagenavi > *:not(.pages):not(:last-child) {
    margin-right: rem(4);
  }
  .wp-pagenavi .extend {
    width: rem(24) !important;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-top: 2px solid;
    border-right: 2px solid;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    @include rect(8);
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .first::after {
    transform: rotate(-135deg);
  }
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .last::after {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="prev"]::before {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="next"]::before {
    transform: rotate(-135deg);
  }
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before {
    left: rem(-6);
  }
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    right: rem(-6);
  }
