@use '../abstracts' as * ;

/* ##############################################################################

    FOOTER

############################################################################## */
/* cta_section
********************************************** */
  .cta_section {
    position: relative;
    padding-bottom: rem(185);
    padding-top: rem(80);
  }
  .cta_section .bgarea {
    width: 100%;
    height: 80%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 108%;
      margin: auto;
      background-image: url(../images/common/cta-img01.jpg);
      background-size: cover;
      background-position: center;
    }
  }
  .cta--box {
    border-radius: rem(32);
    background-color: var(--bg-wht);
    padding: rem(100);
    width: 90%;
    max-width: 1640px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.1);
    @include mq(sp) {
      padding: sprem(48) sprem(30) sprem(40);
      border-radius: sprem(16);
    }
  }
  .cta--box .txt {
    margin-bottom: rem(56);
    @include mq(sp) {
      margin-bottom: sprem(30);
      text-align: left;
    }
  }
  .cta--box .btnarea {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    gap: rem(8);
    max-width: rem(928);
    margin-left: auto;
    margin-right: auto;
    @include mq(sp) {
      grid-template-columns: 1fr;
      grid-template-rows: max-content max-content;
      gap: sprem(14);
    }
  }
  .cta_btn {
    width: 100%;
    max-width: rem(460);
    background: var(--clr-be);
    padding: rem(40);
    position: relative;
    text-align: center;
    @include mq(sp) {
      display: block;
      padding: sprem(16) sprem(16) sprem(12);
    }
    a {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: rem(40);
      &::before {
        content: "";
        width: rem(40);
        height: rem(4);
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        background: var(--bg-main);
      }
    }
    .ttl {
      font-size: rem(20);
      margin-bottom: rem(24);
      line-height: 1;
      @include mq(sp) {
        font-size: sprem(15);
        margin-bottom: sprem(16);
      }
    }
  }

  .cta_btn .btn-org {
    border-radius: 9999px;
    color: var(--clr-wht);
    background-color: var(--bg-cta);
    font-size: rem(20);
    letter-spacing: 0.06em;
    padding: 1.5rem;
    line-height: 1.4;
    height: auto;
    width: 100%;
    display: block;
    max-width: 344px;
    margin-left: auto;
    margin-right: auto;
    transition: var(--transit-default);
    @include mq(sp) {
      font-size: sprem(15);
      padding: sprem(12);
      width: 84%;
    }
  }
  .cta_btn:hover .btn-org {
    background-color: var(--bg-cta-hvr);
  }

  @include mq(sp) {
    .cta_section {
      padding-bottom: 0;
      padding-top: 0;
    }
    .cta_section .bgarea {
      position: relative;
      aspect-ratio: 3/2;
      .bg {
        background-image: url(../images/common/cta-img01_sp.jpg);
      }
    }
    .cta--box {
      border-radius: 0;
      width: 100%;
      box-shadow: none;
    }
  }

/* footer
********************************************** */
  .footer {
    background-color: var(--bg-brn);
    color: var(--clr-wht);
    padding-top: rem(130);
    padding-bottom: rem(60);
    .inner {
      width: 100%;
      max-width: 100%;
      padding-left: rem(80);
      padding-right: rem(80);
    }
  }
  @include mq(sp) {
    .footer {
      padding-top: sprem(50);
      padding-bottom: sprem(30);
      .inner {
        padding-left: sprem(20);
        padding-right: sprem(20);
      }
    }
  }

/* footer_top
********************************************** */
  .footer--catch {
    font-size: rem(36);
    letter-spacing: .1em;
    margin-bottom: rem(110);
  }
  .footer--logo {
    max-width: 132px;
    svg {
      width: 100%;
      fill: #fff;
    }
  }
  .footer--info {
    border-top: 1px solid rgba(255, 255, 255, .25);
    border-bottom: 1px solid rgba(255, 255, 255, .25);
    margin-bottom: rem(30);
  }
  .footer--company {
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: rem(14);
    line-height: 1.7;
    letter-spacing: .02em;
    margin-left: vw(120);
    padding: rem(30) 0;
  }
  .footer--add .map {
    color: #fff;
    margin-left: rem(16);
    position: relative;
    display: inline-block;
    padding-right: rem(16);
    &::before,
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      content: "";
      vertical-align: middle;
    }
    &::before {
      width: 11px;
      height: 1px;
      background: #fff;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
    &::after {
      right: 1px;
      width: 7px;
      height: 7px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      bottom: 2px;
    }
  }
  .footer--tel {
    gap: rem(10);
  }
  .footer--tel a {
    color: var(--clr-wht);
  }
  .footer .sns_area {
    margin-right: rem(14);
  }
  /* ---sns_area --- */
  .sns_area {
    @include mq(sp) {
      margin-top: sprem(14);

    }
  }
  .sns--list li:not(:last-child) {
    margin-right: rem(12);
  }
  .sns--list a {
    display: grid;
    place-items: center;
    @include rect(40);
    &:hover {
      opacity: .7;
    }
  }
  .sns--list svg {
    @include rect(24);
    fill: #fff;
  }
  .sns--list li .ttl {
    display: none;
  }

  @include mq(sp) {
    .footer--catch {
      font-size: sprem(20);
      line-height: 1.5;
      margin-bottom: sprem(36);
    }
    .footer--info {
      padding-top: sprem(16);
      margin-bottom: 0;
    }
    .footer--info--lft {
      align-items: flex-start;
    }
    .footer--logo {
      max-width: 70px;
    }
    .footer--company {
      padding: sprem(2) 0 sprem(10);
      font-size: sprem(12);
      margin-left: 0;
    }
    .footer--tel {
      gap: 0;
    }
  }

/* footer_btm
*************************************************** */
  /* ---fnav --- */
  .fnav--menu a {
    display: block;
    letter-spacing: .05em;
    font-size: rem(14);
    color: var(--clr-wht);
    padding: 0 1.5em;
    .txt--wrap {
      position: relative;
      &::after {
        position: absolute;
        left: 0;
        content: '';
        width: 100%;
        height: 1px;
        background: var(--clr-wht);
        bottom: -2px;
        transform: scale(0, 1);
        transform-origin: right top; /*変形（アンダーラインの伸長）の原点がaタグ（各メニュー）の右端*/
        transition: transform 0.3s;
      }
    }
    &:hover {
      .txt--wrap::after {
        transform: scale(1, 1);     /*ホバー後、x軸方向に1（相対値）伸長*/
        transform-origin: left top;
      }
    }
    @include mq(sp) {
      position: relative;
      padding: .5em .5em .5em 0;
    }
  }
  .fnav--menu a:first-of-type {
    padding-left: 0;
  }
  .copyright {
    font-size: rem(12);
    font-family: 'Lato', sans-serif;
    color: rgba(255, 255, 255, 0.25);
  }
  .copyright p:not(:last-child) {
    @include mq(sp, min, ps) {
      margin-right: 1em;
    }
    @include mq(sp) {
      margin-bottom: .5em;
    }
  }
  .pbl a,
  .pbl img {
    display: block;
    opacity: .4;
  }
  .pbl a {
    opacity: .5;
    &:hover {
      opacity: 1;
    }
  }
  @include mq(sp) {
    .fnav {
      align-items: flex-start;
    }
    .footer .sns_area {
      margin-top: sprem(10);
      margin-right: 0;
      margin-left: auto;
    }
    .fnav--menu {
      width: 90%;
      margin-right: auto;
      margin-left: auto;
      grid-area: 10%;
    }
    .fnav--menu li {
      width: 45%;
    }
    .copyright {
      margin-left: 8%;
      margin-top: sprem(60);
      align-items: flex-start;
    }
  }
