@use '../abstracts' as *;

/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: clamp(calc($fz-pc / $wid-pc * 1700px), vw($fz-pc), rem($fz-pc));
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  @include mq(sp, min, ps) {
    @include mq(med) {
      overflow-y: auto;
      overflow-x: auto;
    }
  }
  @include mq(sp) {
    font-size: $fz-sp+px;
  }
}
body {
  font-family: var(--font-primary);
  line-height: var(--line-height-default);
  font-weight: 500;
  font-feature-settings: "palt";
  letter-spacing: var(--ltr-space-default);
  *font-size: small;
  *font: x-small;
  color: var(--clr-body);
  -webkit-font-smoothing: antialiased;
  height: 100%;
  padding-top: rem($header-hgt);
  word-break: break-word;
  @include mq(sp, min, ps) {
    @include mq(med) {
      &:not(.mce-content-body) {
        min-width: rem(1080);
      }
    }
  }
  @include mq(med) {
    padding-top: sprem($header-hgt-sp);
  }
  @include mq(sp) {
    -webkit-text-size-adjust: none;
  }
}
ul,
ol { list-style: none; }
small { 
  font-size: rem(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-link);
  text-decoration: none;
  transition: var(--transit-default);
  outline : none;
}
a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
a:focus {
  text-decoration: none !important;
}
a[href^="tel:"] {
  @include mq(sp, min, ps) {
    pointer-events: none;
  }
}
img {
  vertical-align: middle;
  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}
address,
em,
i {
  font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr);
}
svg {
  transition: var(--transit-default);
  &[preserveAspectRatio="none"] {
    height: auto;
  }
}
/* --- block --- */
.block {
  display: block;
}
/* --- scroll --- */
.scroll_line {
  height: rem(120);
  overflow: hidden;
  .line {
    position: relative;
    width: 1px;
    height: 100%;
    margin: 0 auto;
    display: block;
    background-color: var(--clr-main);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -2px;
      width: 5px;
      height: 5px;
      background-color: var(--clr-main);
      border-radius: 50%;
      animation-name: Scroll;
      animation-duration: 2s;
      animation-timing-function: linear, linear;
      animation-iteration-count: infinite;
    }
  }
  @include mq(sp) {
    height: sprem(64);
  }
}