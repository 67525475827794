@use '../abstracts' as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: rem(80);
  overflow: hidden;
}

/* --- post --- */
.post {
  position: relative;
}
.post--img .img-contain {
  max-width: 36%;
}
.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.post--date {
  color: var(--clr-main);
  font-family: var(--font-en2);
  font-weight: 600;
  letter-spacing: .1em;
}
.post--ttl {
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: rem(18);
  letter-spacing: .05em;
  line-height: 1.8;
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
} 
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: rem(13);
  background-color: var(--clr-main);
  color: var(--clr-wht);
  font-size: rem(11);
  padding: .1em 1em;
  float: left;
  white-space: nowrap;
  margin: rem(2);
}
.cat_list.tag_list a {
  background-color: transparent;
  color: var(--clr-main);
  &::before {
    content: "#";
  }
}
.cat_list a:hover {
  opacity: .7;
}
.post--txt {
  font-size: rem(14);
  color: rgba(97, 100, 119, 0.5);
  font-family: var(--font-primary);
  font-weight: 700;
  letter-spacing: .05em;
  line-height: 1.6;
}

@include mq(1100) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: rem(40);
    margin-right: 0;
  }
  .post--ttl {
    font-size: sprem(18);
  }
}
@include mq(sp) {
  .post--ttl {
    font-size: sprem(16);
  }
}

/* side_column
**************************************** */
.side_column {
  width: rem(264);
  @include mq(1100) {
    width: 100%;
  }
}
.side_section:not(:last-child) {
  margin-bottom: rem(64);
}
.side--ttl {
  letter-spacing: 0.04em;
  line-height: 1;
  margin-bottom: rem(24);
  color: var(--clr-main);
  position: relative;
  padding-bottom: rem(12);
  &::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 1.5rem;
    height: 1px;
    background-color: #bebdb9;
  }
}
.side--ttl small {
  letter-spacing: .075em;
  display: block;
  line-height: 1;
  margin-top: rem(8);
  color: var(--clr-brn);
  font-family: var(--font-primary);
  font-weight: 700;
}

/* --- list --- */
.side--list {
  padding-left: 10px;
}
.side--list a {
  display: block;
  font-size: rem(14);
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: rem(16);
}
.posts-side .post--img {
  margin-right: rem(16);
  width: rem(64);
  background-image: var(--ptn-be);
}
.posts-side .txtarea {
  flex: 1;
}
.posts-side .post--date {
  margin-bottom: rem(2);
  font-size: rem(11);
}
.posts-side .post--ttl {
  line-height: 1.4;
  font-size: rem(16);
}
.side--tag .side--list li,
.side--tag .side--list a {
  display: inline-block;
}
.side--tag .side--list a {
  font-size: rem(12);
  position: relative;
  &::before {
    content: "#";
  }
}


/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  font-size: rem(14);
  &::after {
    content: "";
    display: block;
    @include rect(8);
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(135deg);
    transition: var(--transit-default);
  }
  &.active::after {
    transform: rotate(315deg);
  }
}
.archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}
.archive-pulldown .archive_list {
  position: relative;
  &:not(:last-child) {
    margin-right: rem(24);
  }
}
.archive-pulldown .archive_list a {
  display: block;
  padding: .25em 1em;
  text-align: left;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  &:hover {
    background-color: #eee;
  }
}
.archive-pulldown .archive_list--label {
  margin-right: rem(8);
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: .25em 4em .25em 2em;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  color: inherit;
  font-family: var(--font-primary);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: .04em;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0.4em;
    right: 0.8em;
    margin: auto;
    width: 0.6em;
    height: 0.6em;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
    transition: all 0.2s ease-out;
  }
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}


/* blog
**************************************** */
.posts-blog .post {
  padding-bottom: rem(24);
  padding-top: rem(24);
  border-bottom: 1px solid #e5e5e5;
  &:first-child {
    border-top: 1px solid #e5e5e5;
  }
}
.posts-blog .post--txtarea {
  flex: 1;
  word-break: break-word;
}
.posts-blog .post--img {
  width: rem(280);
  margin-right: rem(40);
  background-image: var(--ptn-be);
}
.posts-blog .post--date {
  font-size: rem(13);
}
.posts-blog .cat_list a {
  font-size: rem(11);
  padding: .1em 1em;
}
@include mq(sp) {
  .posts-blog .post {
    display: block;
    padding-top: sprem(24);
    padding-bottom: sprem(24);
  }
  .posts-blog .post--img {
    padding-left: 0;
    @include auto-margin;
    margin-bottom: sprem(16);
    text-align: center;
  }
  .posts-blog .post--img img {
    max-width: 100%;
  }
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: rem(20);
  padding-bottom: rem(20);
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: var(--clr-wht);
}

/* --- news_list --- */
.news_list .post {
  padding-bottom: rem(16);
  padding-top: rem(16);
  margin-bottom: 0;
  border-bottom: 1px solid #dedede;
  &:first-child {
    border-top: 1px solid #dedede;
  }
}
.news_list .post--txtarea {
  padding-right: rem(116);
}
.news_list .post--info {
  @include mq(sp, min, ps) {
    margin-right: rem(32);
    width: rem(132);
    white-space: nowrap;
  }
}
.news_list .post--date {
  font-size: rem(16);
}
.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main);
}
.news_list .post--link:hover svg {
  fill: var(--clr-main);
}
.news_list .post--info.flex .post--date + .cat_list {
  margin-left: rem(16);
}
@include mq(sp) {
  .news_list .post {
    display: block;
  }
  .news_list .post--txtarea {
    padding-right: 0;
  }
  .news_list .post--date {
    font-size: sprem(12);
  }  
}

/* works
**************************************** */
.posts-works {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3%;
  row-gap: rem(40);
}
.posts-works .post--img {
  position: relative;
  background-image: var(--ptn-be);
  aspect-ratio: 428/610;
  max-width: rem(427);
}
.posts-works .post--info {
  position: absolute;
  left: 4px;
  bottom: 4px;
}
.posts-works .post .btn_more {
  display: none;
}
@include mq(sp) {
  .posts-works {
    grid-template-columns: 1fr;
    gap: sprem(30);
  }
  .posts-works .post--img {
    aspect-ratio: 4/3;
  }

}

/* works--pulldown
**************************************** */
.works--pulldown {
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  padding: rem(24) rem(56);
  border-radius: 8px;
}
.works--select select {
  font-size: rem(18);
  height: rem(56);
  width: rem(360);
  border: solid 1px #c3c2be;
  color: var(--clr-main);
  text-align: left;
  padding: 0 rem(20);
  font-family: var(--font-primary);
  font-weight: 600;
}
.works--pulldown button {
  background: var(--bg-main);
  color: #fff;
  font-size: rem(18);
  border: 0;
  height: rem(56);
  width: rem(200);
  margin-left: rem(16);
  letter-spacing: .12em;
  &:hover {
    opacity: .7;
  }
}
.works--pulldown .kakeru {
  width: rem(30);
  height: rem(30);
  position: relative;
  margin: 0 rem(8);
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    background-color:#bebdb9;
    transform: translateY(rem(14.5)) rotate(-45deg);
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #bebdb9;
    transform: translateY(rem(-14.5)) rotate(45deg);
  }
}
@include mq(sp) {
  .works--pulldown {
    padding: sprem(16) sprem(56);
  }
  .works--select select {
    width: sprem(200);
    font-size: sprem(14);
    height: sprem(40);
    text-align: center;
  }
  .works--pulldown button {
    height: sprem(40);
    width: sprem(160);
    margin-top: sprem(24);
    font-size: sprem(14);
    margin-left: 0;
  }
  .works--pulldown .kakeru {
    width: sprem(20);
    height: sprem(20);
    margin: sprem(8) sprem(8);
    &::before {
      transform: translateY(sprem(9.5)) rotate(-45deg);
    }
    &::after {
      transform: translateY(sprem(-9.5)) rotate(45deg);
    }
  }
}