@use './variables' as *;
/* ##############################################################################

    KEYFRAMES

############################################################################## */

@keyframes Scroll { 
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(7.5rem);
    }
}

@keyframes slider-border {
    0% {
        transform: scale(1, 0);
        transform-origin: 0 0;
    }
    100% {
        transform: scale(1, 1);
        transform-origin: 0 0;
    }
}
@keyframes slider-border-l {
    0% {
        transform: scale(0, 1);
        transform-origin: 0 0;
    }
    100% {
        transform: scale(1, 1);
        transform-origin: 0 0;
    }
}
