@use '../abstracts' as *;

/* section-contact
********************************************** */
.section-contact .inner {
  border-radius: rem(32);
  background: #fff;
  padding: rem(80);
  .cta_tel {
    @include auto-margin;
  }
}
.section-contact .cta_btn {
  background: transparent;
  padding: 0;
  a::before {
    content: none;
  }
}
.cta_tel a {
  padding: rem(40);
  position: relative;
  @include mq(sp) {
    display: block;
    padding: sprem(16);
  }
  &::before {
    content: none;
  }
}
.cta_tel .ttl {
  font-size: rem(20);
  margin-bottom: rem(24);
  line-height: 1;
}
.cta_tel .tel {
  color: var(--clr-cta);
  font-size: rem(24);
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: rem(10);
  display: block;
  @include mq(sp) {
    font-size: sprem(18);
    margin-bottom: sprem(6);
  }
  .num {
    font-size: rem(40);
    letter-spacing: 0;
    @include mq(sp) {
      font-size: sprem(26);
    }
  }
}
.cta_tel .hours {
  font-size: rem(13);
  letter-spacing: .03em;
  display: block;
  @include mq(sp) {
    font-size: sprem(10);
  }
}
.page-contact-simple .footer .inner {
  justify-content: flex-end
}

@include mq(sp) {
  .section-contact .inner {
    border-radius: sprem(16);
    padding: sprem(40) 0 sprem(20);
    width: 90%;
  }
}