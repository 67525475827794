@use "../abstracts" as *;

/* lps_parts--faq
********************************************** */
/* --- 共通 --- */
.lps_parts--faq {
  max-width: rem(1290);
  @include auto-margin;
  .ttl-03 {
    font-size: rem(24);
    margin-bottom: 0 !important;
    @include mq(sp) {
      font-size: sprem(15);
    }
  }
  .text {
    font-size: rem(15);
    line-height: 1.86;
    letter-spacing: .03em;
    @include mq(sp, min, ps) {
      display: flex;
      gap: perc(32,768,'%');
    }
  }
  .img {
    @include mq(sp, min, ps) {
      width: perc(300,768,'%');
    }
  }
  .mce-content-body {
    @include mq(sp, min, ps) {
      margin-bottom: 0 !important;
      flex: 1;
    }
  }
  .editor-ttl2 {
    font-size: rem(20);
  }
}

/* --- 通常 --- */
.lps_parts--faq:not(.faq-toggle) {
  .faq_item {
    border: 1px solid var(--bg-main-hvr);
    background-color: var(--bg-wht);
    &:not(:last-child) {
      margin-bottom: rem(40);
      @include mq(sp) {
        margin-bottom: sprem(16);
      }
    }
  }
  .ttl-03,
  .text {
    padding: rem(18) rem(48);
    @include mq(sp) {
      padding: sprem(24);
    }
  }
  .ttl-03 {
    background-color: var(--bg-main-hvr);
    color: var(--clr-wht);
    font-size: rem(20);
    font-family: var(--font-primary);
    font-weight: 700;
    line-height: 1.8;
    letter-spacing: .08em;
  }
}

/* --- 開閉式 --- */
.lps_parts--faq.faq-toggle {
  .faq_item {
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    padding: rem(40) rem(30);
    @include mq(sp) {
      padding: sprem(16) sprem(14);
    }
    &:not(:first-child) {
      border-top: 0;
    }
  }
  .ttl-03 {
    cursor: pointer;
    padding-right: rem(56);
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }
  .ttl-03 .toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    @include rect(48);
    color: var(--clr-wht);
    background-color: var(--bg-main);
    border-radius: 50%;
    transition: var(--transit-default);
    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      width: rem(12);
      height: 2px;
      border-bottom: 2px solid;
      transition: var(--transit-default);
    }
    &::after {
      transform: rotate(90deg);
    }
    @include mq(sp) {
      width: sprem(30);
      height: sprem(30);
    }
  }
  .ttl-03:hover .toggle {
    opacity: .5;
  }
  .ttl-03.active .toggle {
    transform: rotate(180deg);
    background-color: rgba($color: #383a4b, $alpha: .5);
    &::after {
      opacity: 0;
    }
  }
  .text {
    padding-top: rem(40);
    @include mq(sp) {
      padding-top: sprem(20);
    }
  }
}