@use '../abstracts/' as *;

/* home
********************************************** */
.scroll_txt {
  width: 100%;
  position: relative;
  z-index: -1;
}
.scroll_txt--wrap {
  font-size: rem(200);
  white-space: nowrap;
  line-height: .8;
  color: var(--clr-be);
  text-transform: uppercase;
  @include mq(sp) {
    font-size: sprem(90);
  }
}
.home .page_ttl--bnr {
  position: fixed;
  bottom: 0;
  right: 3%;
}
@include mq(sp) {
  .home .page_ttl--bnr {
    bottom: sprem(10);
    transition: var(--transit-default);
    opacity: 1;
  }
  .home .pin-active .page_ttl--bnr {
    opacity: 0;
    transition: var(--transit-default);
  }
}

/* home_catch
********************************************** */
.home_catch {
  padding-top: rem(176);
  padding-bottom: rem(16);
}
.home_catch .ttl {
  letter-spacing: .05em;
  line-height: 1.55;
  margin-bottom: rem(44);
}
.home_catch .txt {
  letter-spacing: .04em;
  line-height: 2.25;
  margin-bottom: rem(78);
}
@include mq(sp) {
  .home_catch {
    padding-top: sprem(100);
  }
  .home_catch .txt {
    margin-bottom: sprem(40);
  }
}

/* home_message
********************************************** */
.home_message .ttl-01 {
  margin-bottom: .5em;
}
.home_message .scroll_txt {
  margin-bottom: rem(-52);
  overflow: hidden;
}
.home_message > .inner {
  margin-right: 0;
  display: grid;
  grid-template-columns: 1fr 50vw;
  gap: rem(20);
  position: relative;
}
.home_message .imgarea {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: rem(16);
}
.home_message .imgarea .img_wrap {
  overflow: hidden;
  aspect-ratio: 320/936;
  position: relative;
  .img {
    position: absolute;
    width: 100%;
    height: 110%
  }
}
.home_message .txt {
  line-height: 2;
  letter-spacing: .03em;
}
.home_message  .btn {
  margin-top: rem(58);
}
.home_message .scroll_line {
  margin-top: rem(76);
}
@include mq(sp) {
  .home_message > .inner {
    grid-template-columns: 1fr;
    margin-right: auto;
  }
  .home_message .txt {
    line-height: 1.8;
  }
  .home_message  .btn {
    margin-top: sprem(38);
  }
  .home_message .imgarea {
    gap: sprem(6);
    margin-bottom: sprem(30);
    order: 1;
  }
  .home_message .txtarea {
    order: 2;
  }
}

/* home_feature
********************************************** */
.home_feature {
  overflow: hidden;
}
.home_feature .feature_item {
  gap: rem(96);
  margin-bottom: rem(128);
}
.home_feature .feature_item .imgarea {
  max-width: rem(800);
  position: relative;
  width: 100%;
}
.home_feature .feature_item .imgarea .num {
  position: absolute;
  left: 0;
  top: 0;
  font-size: vw(400);
  line-height: .5;
  color: rgba(245, 242, 237, 0.5);
  z-index: -1;
  transform: translate(-50%, -50%);
}
.home_feature .feature_item .img_wrap {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: rem(800);
  aspect-ratio: 800/532;
  .img {
    position: absolute;
    width: 100%;
    height: 110%;
  }
}
.home_feature .feature_item.flex-reverse .imgarea .num {
  right: 0;
  left: auto;
  transform: translate(50%, -50%);
}
.home_feature .feature_item .txtarea {
  max-width: rem(504);
}
.home_feature .feature_item .txtarea .ttl_en {
  font-size: rem(11);
  display: block;
  line-height: 1;
  position: relative;
  padding-bottom: rem(18);
  margin-bottom: rem(30);
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: rem(24);
    background: var(--bg-main);
  }
}
.home_feature .feature_item .txtarea .ttl-02 {
  margin-bottom: rem(64);
}
.home_feature .feature_item .txtarea .txt {
  line-height: 2.125;
  letter-spacing: .03em;
}
.home_feature .btn a {
  margin-left: auto;
  margin-right: auto;
}
@include mq(sp) {
  .home_feature .feature_item {
    gap: sprem(20);
    margin-bottom: sprem(90);
  }
  .home_feature .feature_item:nth-child(3) {
    margin-bottom: sprem(50);
  }
  .home_feature .feature_item .img_wrap {
    width: 100%;
  }
  .home_feature .feature_item .imgarea .num {
    font-size: sprem(100);
    line-height: .5;
    transform: translate(0, -70%);
    left: -6%;
  }
  .home_feature .feature_item.flex-reverse .imgarea .num {
    transform: translate(0, -70%);
    right: auto;
    left: -6%;
  }
  .home_feature .feature_item .txtarea .ttl_en {
    padding-bottom: sprem(10);
    margin-bottom: sprem(14);
  }
  .home_feature .feature_item .txtarea .ttl-02 {
    margin-bottom: sprem(30);
  }
}
/* home_kitchen
********************************************** */
.home_kitchen {
  opacity: 0;
  position: relative;
}
.home_kitchen .contentarea {
  height: calc(100vh - rem($header-hgt-fx));
  position: relative;
}
// .home_kitchen .contentarea.active {
//   opacity: 1;
// }
.home_kitchen--current {
  position: absolute;
  z-index: 1;
  left: calc(50% - rem(80));
  width: rem(80);
  padding-top: rem(38);
  li {
    writing-mode: vertical-rl;
    font-feature-settings: initial;
    color: rgba(255, 255, 255, 0.5);
    font-size: rem(10);
    margin-bottom: rem(30);
    font-size: rem(13);
    font-family: var(--font-en2);
    font-weight: 600;
  }
  li.current {
    color: #fff;
  }
}
.home_kitchen--bar_wrap {
  position: absolute;
  top: 0;
  left: calc(50% - 4px);
  width: 4px;
  height: 100%;
  z-index: 1;
  .home_kitchen--bar {
    width: 100%;
    height: 1%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--bg-main);
  }
}
.home_kitchen .kitchen_item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: var(--transit-default);
}
.home_kitchen .contentarea .kitchen_item.item_1 {
  opacity: 1;
}
.home_kitchen .kitchen_item .imgarea {
  height: 100%;
  position: relative;
  .img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.home_kitchen .kitchen_item .txtarea {
  position: relative;
  padding: rem(60) rem(64) rem(60) rem(64);
}
.home_kitchen .kitchen_item .txtarea .kitchen--ttl {
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(60);
}
.kitchen--ttl-en {
  font-size: rem(64);
  line-height: .93;
}
.home_kitchen .kitchen_item .txtarea .kitchen--ttl-rgt {
  display: flex;
  align-items: flex-start;
  .kitchen--ttl-jp {
    font-size: rem(18);
    letter-spacing: .05em;
    position: relative;
    line-height: 1;
    padding-bottom: rem(16);
    margin-right: rem(40);
    &::before {
      content: '';
      height: 1px;
      width: rem(24);
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: var(--clr-main);
    }
  }
  .kitchen--ttl-num {
    font-size: rem(120);
    line-height: .5;
    letter-spacing: 0;
  }
}
.home_kitchen .kitchen_item .txtarea--inner {
  margin-left: rem(90);
  margin-right: auto;
}
.home_kitchen .kitchen_item .txtarea .sub_ttl {
  font-size: rem(16);
  letter-spacing: .06;
  line-height: 2.125;
  margin-bottom: rem(8);
}
.home_kitchen .kitchen_item .txtarea .txt {
  margin-bottom: rem(50);
  max-width: rem(540);
}
.home_kitchen .kitchen_item .txtarea .btn + .btn {
  margin-left: rem(16);
}
.home_kitchen .scroll_line {
  position: absolute;
  right: rem(66);
  bottom: rem(200);
  z-index: 1;
  width: rem(6);
}
@media screen and (max-width: 1440px) {
  .home_kitchen .kitchen_item .txtarea {
    padding: 2rem 3rem 1.5rem;
  }
  .home_kitchen .kitchen_item .txtarea .kitchen--ttl {
    margin-bottom: 1.5rem;
  }
  .home_kitchen .kitchen_item .txtarea--inner {
    margin-left: 4rem;
  }
}
@media screen and (max-width: 1340px) {
  .home_kitchen .kitchen_item .txtarea {
    padding: 3rem 2rem;
  }
  .home_kitchen .kitchen_item .txtarea .kitchen--ttl-rgt .kitchen--ttl-jp {
    margin-right: 1rem;
  }
  .home_kitchen .kitchen_item .txtarea--inner {
    margin-left: 3rem;
  }
}
.ipad {
  .home_kitchen {
    opacity: 1;
  }
  .home_kitchen .contentarea {
    height: auto;
    padding-top: sprem(30);
    overflow-x: initial;
  }
  .home_kitchen--bar_wrap {
    display: none;
  }
  .home_kitchen .kitchen_item {
    grid-template-columns: 1fr;
    position: relative;
    opacity: 0;
  }
  .home_kitchen .contentarea .kitchen_item.item_1 {
    opacity: 0;
  }
  .home_kitchen .kitchen_item + .kitchen_item {
    margin-top: sprem(50);
  }
  .home_kitchen .kitchen_item .imgarea {
    height: auto;
    width: 100%;
    aspect-ratio: 4/2;
    order: 2;
  }
  .home_kitchen .kitchen_item .txtarea {
    padding: 7% 5% 6%;
    height: auto;
    order: 1;
  }
  .home_kitchen .kitchen_item .txtarea .kitchen--ttl {
    padding: 0;
    top: 0;
    position: relative;
    margin-bottom: 2vh;
  }
  .home_kitchen .kitchen_item .txtarea--inner {
    margin-left: 0;
    padding-top: sprem(10);
  }
  .home_kitchen .scroll_line {
    display: none;
  }
}
@include mq(sp) {
  .home_kitchen {
    opacity: 1;
  }
  .home_kitchen .contentarea {
    height: auto;
    padding-top: sprem(30);
    overflow-x: initial;
  }
  .home_kitchen--bar_wrap {
    display: none;
  }
  .home_kitchen .kitchen_item {
    grid-template-columns: 1fr;
    position: relative;
    opacity: 0;
  }
  .home_kitchen .contentarea .kitchen_item.item_1 {
    opacity: 0;
  }
  .home_kitchen .kitchen_item + .kitchen_item {
    margin-top: sprem(50);
  }
  .home_kitchen .kitchen_item .imgarea {
    height: auto;
    width: 100%;
    aspect-ratio: 4/2;
    order: 2;
  }
  .home_kitchen .kitchen_item .txtarea {
    padding: 7% 5% 6%;
    height: auto;
    order: 1;
  }
  .home_kitchen .kitchen_item .txtarea .kitchen--ttl {
    padding: 0;
    top: 0;
    position: relative;
    margin-bottom: 2vh;
  }
  .kitchen--ttl-en {
    font-size: sprem(28);
  }
  .home_kitchen .kitchen_item .txtarea .kitchen--ttl-rgt {
    .kitchen--ttl-jp {
      font-size: sprem(11);
      padding-bottom: rem(16);
      margin-right: rem(10);
    }
    .kitchen--ttl-num {
      font-size: sprem(40);
    }
  }
  .home_kitchen .kitchen_item .txtarea--inner {
    margin-left: 0;
    padding-top: sprem(10);
  }
  .home_kitchen .kitchen_item .ttl-02:not([class*=mgn-btm]):not(:last-child) {
    margin-bottom: sprem(30);
    font-size: sprem(20);
  }
  .home_kitchen .kitchen_item .txtarea .sub_ttl {
    font-size: sprem(12);
    line-height: 1;
  }
  .home_kitchen .kitchen_item .txtarea .txt {
    font-size: sprem(13);
    margin-bottom: sprem(20);
    line-height: 1.6;
  }
  .home_kitchen .kitchen_item .btn a {
    font-size: sprem(13);
    padding: sprem(10);
    margin-left: 0;
  }
  .home_kitchen .scroll_line {
    display: none;
  }
}
/* cursor
**************************************** */
#cursor {
  position: fixed;
  z-index: 99999;
  background: rgba(255,255,255,.75);
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 0;
  pointer-events: none;
  opacity: 0;
}
#cursor.is-scroll {
  padding-top: rem(225);
  animation: cursor_scroll .2s linear forwards;
}
#cursor.is-scroll::before {
  content: "SCROLL";
  display: inline-block;
  width: 100%;
  padding-left: rem(112);
  text-align: left;
  color: #cec8bf;
  font-family: var(--font-en);
  font-weight: 600;
}
#cursor.is-scroll::after {
  content: "";
  display: inline-block;
  width: rem(8);
  height: rem(8);
  border-radius: 50%;
  background-color: #cec8be;
  position: absolute;
  bottom: rem(72);
  left: rem(126);
}
@keyframes cursor_scroll {
  0% {
    width: 0;
    height: 0;
    margin: 0;
  }
  100% {
    width: rem(350);
    height: rem(350);
    margin: rem(-175) 0 0 rem(-175);
  }
}
@include mq(sp) {
  #cursor {
    display: none;
  }
}

/* home_flow
********************************************** */
.home_flow .txtarea {
  margin-bottom: rem(70);
}
.home_flow .txtarea .txt {
  font-size: rem(18);
  letter-spacing: .03em;
  font-family: var(--font-primary);
  line-height: 2;
  small {
    font-size: rem(14);
    font-family: var(--font-primary);
  }
}
.home_flow .flow_item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  counter-increment: number;
  align-items: center;
  padding-left: 0;
  border-top: solid 1px var(--clr-main);
  height: rem(186);
  position: relative;
  .num {
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background: transparent;
    border: 1px solid var(--clr-main);
    color: var(--clr-main);
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    margin-right: 3.75rem;
    &::before {
      content: "STEP";
      font-size: 0.75rem;
      letter-spacing: 0.05em;
      line-height: 1;
      font-family: "Outfit", sans-serif;
      font-family: var(--font-en2);
      font-weight: 600;
    }
    &::after {
      content: counter(number, decimal-leading-zero);
      font-size: 2rem;
      letter-spacing: 0.08em;
      line-height: 1;
      font-family: "Cormorant", serif;
      font-family: var(--font-en3);
      font-weight: 600;
    }
  }
  .illust {
    width: rem(200);
    max-height:  rem(170);
    display: flex;
    justify-content: center;
    img {
      object-fit: contain;
    }
  }
}
.home_flow .flow_item:last-child {
  border-bottom: solid 1px var(--clr-main);
}
.home_flow .flow_item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: auto;
  bottom: -0.75rem;
  left: 0;
  right: 0;
  margin: auto;
  width: 3.75rem;
  height: 0.75rem;
  border: 0;
  background: var(--bg-main);
  clip-path: polygon(100% 0, 0 0, 50% 100%);
}
.home_flow .flow_item .item--lft {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  &::after {
    content: "";
    width: 1px;
    height: rem(96);
    position: absolute;
    right: 0;
    top: rem(43);
    background-color: rgba(97, 100, 119, 0.2);
  }
  .ttl {
    width: rem(176);
    font-size: rem(20);
    letter-spacing: .08em;
  }
}
.home_flow .flow_item .item--rgt {
  position: relative;
  padding: 0 rem(90) 0 rem(74);
  height: 100%;
  display: flex;
  align-items: center;
  &::after {
    content: "";
    width: 1px;
    height: rem(96);
    position: absolute;
    right: 0;
    top: rem(43);
    background-color: rgba(97, 100, 119, 0.2);
  }
}
@include mq(sp) {
  .home_flow .txtarea {
    margin-bottom: sprem(30);
  }
  .home_flow .txtarea .txt {
    font-size: sprem(14);
    text-align: left;
    small {
      font-size: sprem(12);
    }
  }
  .home_flow .flow_item {
    grid-template-columns: 1fr;
    height: auto;
    .num {
      width: sprem(44);
      height: sprem(44);
      margin-right: sprem(8);
      &::before {
        font-size: sprem(10);
      }
      &::after {
        font-size: sprem(20);
      }
    }
    .illust {
      width: sprem(90);
      max-height: sprem(68);
      display: flex;
      justify-content: center;
      img {
        object-fit: contain;
      }
    }
  }
  .home_flow .flow_item:not(:last-child)::before {
    bottom: sprem(-8);
    width: sprem(26);
    height: sprem(8);
  }
  .home_flow .flow_item .toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: sprem(30);
    height: sprem(30);
    color: var(--clr-wht);
    background-color: var(--bg-main);
    border-radius: 50%;
    transition: var(--transit-default);
    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      width: rem(12);
      height: 2px;
      border-bottom: 2px solid;
      transition: var(--transit-default);
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(180deg);
      background-color: rgba($color: #383a4b, $alpha: .5);
      &::after {
        opacity: 0;
      }
    }
  }
  .home_flow .flow_item .item--lft {
    padding-top: sprem(12);
    padding-bottom: sprem(12);
    height: sprem(96);
    .ttl {
      width: sprem(130);
      font-size: sprem(15);
    }
    &::after {
      content: none;
    }
  }
  .home_flow .flow_item .item--rgt {
    padding: 0;
    &::after {
      content: none;
    }
    .text {
      padding: sprem(10);
    }
  }
}
/* home_company
********************************************** */
.home_company {
  position: relative;
  padding-bottom: rem(224);
  padding-top: rem(80);
}
.home_company .bgarea {
  width: 100%;
  height: 86%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 108%;
    margin: auto;
    background-image: url(../images/home/company-img01.jpg);
    background-size: cover;
    background-position: 60% 50%;
  }
}
.company--cont {
  max-width: rem(880);
  width: 50%;
  border-radius: rem(32);
  margin-left: auto;
  margin-right: 0;
  color: var(--clr-wht);
  background: var(--bg-brn);
  padding: vw(100) vw(110);
}
.company--cont .ttl-01,
.company--cont .ttl-01 .jp {
  color: var(--clr-wht);
}
.company--cont .txt {
  margin-bottom: rem(64);
}
.company--cont .btnarea {
  gap: vw(16);
}
.company--cont .btn {
  min-width: rem(168);
  a {
    color: var(--clr-wht);
    border-top: 2px solid rgba(255, 255, 255, 0.5);
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  }
}
@include mq(sp) {
  .home_company {
    position: relative;
    padding-bottom: sprem(42);
    padding-top: sprem(42);
  }
  .home_company .inner {
    width: 100%;
  }
  .home_company .bgarea {
    position: relative;
    aspect-ratio: 3/2;
    .bg {
      background-image: url(../images/home/sp/company-img01_sp.jpg);
      background-repeat: no-repeat;
    }
  }
  .company--cont {
    padding: sprem(40) sprem(24) sprem(30);
    width: 100%;
    border-radius: 0;
  }
  .company--cont .txt {
    margin-bottom: sprem(20);
  }
  .company--cont .btnarea {
    flex-wrap: wrap;
    column-gap: sprem(14);
  }
  .company--cont .btn {
    min-width: min(120px, 100%);
  }
  .company--cont .btn a {
    min-width: min(120px, 100%);
  }
}

/* home_works
********************************************** */
.home_works .posts-works {
  margin-bottom: rem(70);
  margin-top: rem(80);
}
.home_works .posts-works .post {
  margin-left: rem(20);
  margin-right: rem(20);
}
.home_works .posts-works .post--img {
  margin-bottom: 0;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(41,30,9,.5);
    mix-blend-mode: multiply;
    opacity: 0;
    transition: var(--transit-default);
  }
}
.home_works .posts-works .post--info {
  display: none;
}
.home_works .posts-works .post--txtarea {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  color: #fff;
  transition: var(--transit-default);
  max-width: rem(427);
}
.home_works .posts-works .post .btn_more {
  display: block;
  font-size: rem(15);
  letter-spacing: .26em;
}
.home_works .posts-works .post--txtarea a {
  color: #fff;
}
.home_works .posts-works .post:hover {
  .post--img::after {
    opacity: 1;
  }
  .post--txtarea {
    opacity: 1;
  }
}
@include mq(sp) {
  .home_works .posts-works  {
    margin-bottom: sprem(20);
    margin-top: sprem(40);
  }
  .home_works .posts-works .post {
    margin-left: sprem(14);
    margin-right: sprem(14);
  }
  .home_works .posts-works .post .post--ttl {
    font-size: sprem(13);
    letter-spacing: .02em;
  }
  .home_works .posts-works .post--img::after {
    content: none;
  }
  .home_works .posts-works .post--txtarea {
    position: initial;
    opacity: 1;
    padding: sprem(4) 0 0;
    align-items: flex-start;
  }
  .home_works .posts-works .post--txtarea a {
    color: var(--clr-main);
  }
  .home_works .posts-works .post .btn_more {
    display: none;
  }
}

/* home_search
********************************************** */
.home_search {
  padding-top: rem(138);
  padding-bottom: rem(138);
  background-color:rgba(255,255,255,0.75);
  background-blend-mode:lighten;
}
@include mq(sp) {
  .home_search {
    padding-top: sprem(40);
    padding-bottom: sprem(40);
  }
}

/* home_showroom
********************************************** */
.home_showroom .ttl-01 {
  margin-bottom: 1.5em;
}
.home_showroom .contentarea {
  display: flex;
  gap: 6%;
}
.home_showroom .contentarea .imgarea {
  width: 45%;
}
.home_showroom .contentarea .txtarea {
  width: 49%;
}
.home_showroom .txtarea .ttl-02 {
  font-size: rem(36);
  letter-spacing: .02em;
  line-height: 1.4;
}
.home_showroom .txtarea .txt {
  font-size: rem(15);
  line-height: 2.1;
  letter-spacing: .06em;
  margin-bottom: rem(50);
}
.home_showroom .tablearea {
  margin-bottom: rem(40);
}
.home_showroom .tablearea .ttl {
  font-size: rem(20);
  letter-spacing: .06em;
  line-height: 1.9;
  margin-bottom: rem(24);
}
.home_showroom .tablearea table {
  width: 100%;
  margin-bottom: rem(30);
  tr {
    border-top: solid 1px rgba($color: #616477, $alpha: .5);
  }
  tr:last-child {
    border-bottom: solid 1px rgba($color: #616477, $alpha: .5);
  }
  th {
    font-size: rem(15);
    text-align: left;
    width: 28%;
    padding: rem(15) rem(14);
  }
  td {
    font-size: rem(14);
    line-height: 1.85;
    padding: rem(15) rem(14);
    font-weight: 500;
  }
  
}
.home_showroom .tablearea .note {
  font-size: rem(13);
  letter-spacing: .02em;
  line-height: 1.76;
  font-weight: 500;
}
.home_showroom .tablearea .note p {
  display: flex;
  &::before {
    content: "※";
    font-size: rem(10);
    font-family: var(--font-primary);
    font-weight: 700;
    margin-top: rem(2);
    margin-right: rem(2);
  }
}
.showroom--dots {
  position: absolute;
  left: rem(-80);
  top: 0;
  width: rem(80);
  height: 100%;
  li {
    writing-mode: vertical-rl;
    font-feature-settings: initial;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(30);
    font-size: rem(13);
    color: var(--clr-sub);
    transition: var(--transit-default);
    &.slick-active {
      color: var(--clr-main);
    }
  }
}
@include mq(sp) {
  .home_showroom .contentarea .imgarea {
    width: 100%;
    margin-bottom: sprem(20);
  }
  .home_showroom .contentarea .txtarea {
    width: 100%;
  }
  .home_showroom .txtarea .ttl-02 {
    font-size: sprem(22);
    letter-spacing: .02em;
  }
  .home_showroom .txtarea .txt {
    margin-bottom: sprem(30);
  }
  .showroom--dots {
    position: relative;
    width: 100%;
    height: sprem(30);
    left: 0;
  }
  .showroom--dots .slick-dots {
    display: flex;
  }
  .showroom--dots li {
    writing-mode: initial;
    font-size: sprem(13);
    line-height: sprem(30);
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
    + li {
      margin-left: sprem(20);
    }
  }
}

/* home_news
********************************************** */
.home_news {
  padding-top: rem(180);
}
.home_news .news_list {
  flex: 1;
  max-width: 900px;
}
.news_list--ttl {
  display: block;
  position: relative;
  margin-right: rem(40);
  padding: 0;
  line-height: 1.2;
  @include mq(sp) {
    width: auto;
    float: none;
    height: auto;
    margin-bottom: sprem(16);
  }
}
.home_news .btn {
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -20%;
}
@include mq(sp) {
  .home_news {
    padding-top: sprem(70);
  }
  .home_news .btn {
    position: relative;
    display: block;
    margin-top: sprem(30);
  }
}


/* home_blog
********************************************** */
.home_blog .blog_list {
  flex: 1;
  max-width: 900px;
}
.home_blog .posts-blog .post--img {
  width: rem(150);
  margin-right: rem(26);
}
.home_blog .posts-blog .post--txt {
  -webkit-line-clamp: 1;
}
.home_blog .btn {
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 0;
}
@include mq(sp) {
  .home_blog .posts-blog .post--img {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: sprem(20);
  }
  .home_blog .btn {
    position: relative;
    display: block;
    margin-top: sprem(30);
  }
}
/* home_
********************************************** */
.home_ {
  @include mq(sp) {
  }
}

/* home_
********************************************** */
.home_ {
  @include mq(sp) {
  }
}

/* home_
********************************************** */
.home_ {
  @include mq(sp) {
  }
}


.js-fade,
.js-fade-wrap>.js-fade2 {
    opacity: 0 !important;
    translate: 0 30px;
    transition: var(--transit-default);
    transition-property: opacity, translate;
}

.js-fade.is-active,
.js-fade-wrap.is-active>.js-fade2 {
    opacity: 1 !important;
    translate: 0 0;
    pointer-events: auto;
}

.ipad {
  .js-sp-fade {
    opacity: 0;
    translate: 0 30px;
    transition: var(--transit-default);
    transition-property: opacity, translate;
  }
  .js-sp-fade.is-active {
    opacity: 1 !important;
    translate: 0 0;
    pointer-events: auto;
  }
}
@include mq(sp) {
  .js-sp-fade {
    opacity: 0;
    translate: 0 30px;
    transition: var(--transit-default);
    transition-property: opacity, translate;
  }
  .js-sp-fade.is-active {
    opacity: 1 !important;
    translate: 0 0;
    pointer-events: auto;
  }
}