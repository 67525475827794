@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 414;
$header-hgt: 128;
$header-hgt-sp: 64;
$header-hgt-fx: 88;

// 色
$clr-main: #616477;
$bg-main: #616477;

// CSS変数
:root {
  // typography
  --ltr-space-default: .03em;
  --line-height-default: 1.8;
  --line-height-hdr: 1.4;
  --line-height-none: 1;

  // font-families
  --font-primary:'Zen Kaku Gothic Antique', sans-serif;
  --font-primary-b: 'Zen Kaku Gothic Antique', sans-serif;
  --font-jp: 'Shippori Mincho B1', serif;
  --font-en: 'Cormorant', serif;
  --font-en2: 'Outfit', sans-serif;
  --font-en3: 'Cormorant Infant', serif;

  // font-sizes
  --fz-primary: 1.125rem;
  --fz-half: .5rem;

  // text colors
  --clr-main: #616477;
  --clr-sub: #bebdb9;
  --clr-body: #616477;
  --clr-link: #616477;
  --clr-wht: #fff;
  --clr-blk: #000;
  --clr-be: #f5f2ed;
  --clr-gold: #d9bf95;
  --clr-brn: #969591;
  --clr-cta: #c98758;
  --clr-darkblu: #383a4b;


  // background colors
  --bg-main: #616477;
  --bg-main-hvr: #383a4b;
  --bg-sub: #f5f2ed;
  --bg-wht: #fff;
  --bg-off_wht: #f8f8f8;
  --bg-blk: #000;
  --bg-cta: #c98758;
  --bg-cta-hvr: #b87240;
  --bg-brn: #969591;

  // animation
  --transit-default: all .4s ease-out;

  // background image
  --ptn-be: url(../images/common/bg-pt01.png);
  --ptn-gry: url(../images/common/bg-pt02.png);
  --ptn-blu: url(../images/common/bg-pt03.png);
}

// SASS Maps

$z-index: (
  "header" : 9999,
  "top": 9990,
  "aside" : 75,
  "middle" : 50,
  "main" : 1,
  "base" : 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160, // 2560px
  lg: 120, // 1920px
  pc: 85.375, // 1366px
  med: 67.5, // 1080px
  sp: 47.9375, // 767px
  sm: 40, // 640px
  xs: 34.375, // 500px
  se: 21.25, // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  'blue': (
    100: hsl(202, 73%, 82%),
    200: hsl(202, 73%, 72%),
    300: hsl(202, 73%, 62%),
    400: hsl(202, 73%, 52%),
    500: hsl(202, 73%, 42%),
    600: hsl(202, 73%, 32%),
  ),
  'red': (
    100: hsl(0, 100%, 80%),
    200: hsl(0, 100%, 70%),
    300: hsl(0, 100%, 60%),
    400: hsl(0, 100%, 50%),
    500: hsl(0, 100%, 40%),
    600: hsl(0, 100%, 30%),
  ),
  'green': (
    100: hsl(118, 100%, 80%),
    200: hsl(118, 100%, 70%),
    300: hsl(118, 100%, 60%),
    400: hsl(118, 100%, 50%),
    500: hsl(118, 100%, 40%),
    600: hsl(118, 100%, 30%),
  )
);

$aspect-ratios: (
  widescreen: '3/2',
  square: '1/1',
  portrait: '2/3'
);