@charset "UTF-8";
:root {
  --ltr-space-default: .03em;
  --line-height-default: 1.8;
  --line-height-hdr: 1.4;
  --line-height-none: 1;
  --font-primary:"Zen Kaku Gothic Antique", sans-serif;
  --font-primary-b: "Zen Kaku Gothic Antique", sans-serif;
  --font-jp: "Shippori Mincho B1", serif;
  --font-en: "Cormorant", serif;
  --font-en2: "Outfit", sans-serif;
  --font-en3: "Cormorant Infant", serif;
  --fz-primary: 1.125rem;
  --fz-half: .5rem;
  --clr-main: #616477;
  --clr-sub: #bebdb9;
  --clr-body: #616477;
  --clr-link: #616477;
  --clr-wht: #fff;
  --clr-blk: #000;
  --clr-be: #f5f2ed;
  --clr-gold: #d9bf95;
  --clr-brn: #969591;
  --clr-cta: #c98758;
  --clr-darkblu: #383a4b;
  --bg-main: #616477;
  --bg-main-hvr: #383a4b;
  --bg-sub: #f5f2ed;
  --bg-wht: #fff;
  --bg-off_wht: #f8f8f8;
  --bg-blk: #000;
  --bg-cta: #c98758;
  --bg-cta-hvr: #b87240;
  --bg-brn: #969591;
  --transit-default: all .4s ease-out;
  --ptn-be: url(../images/common/bg-pt01.png);
  --ptn-gry: url(../images/common/bg-pt02.png);
  --ptn-blu: url(../images/common/bg-pt03.png);
}

/* ##############################################################################

    KEYFRAMES

############################################################################## */
@keyframes Scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(7.5rem);
  }
}
@keyframes slider-border {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  100% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
}
@keyframes slider-border-l {
  0% {
    transform: scale(0, 1);
    transform-origin: 0 0;
  }
  100% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
}
/* ##############################################################################

    style info : リセット及びフォントサイズ

############################################################################## */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

*,
*:after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1;
  background: #fff;
  color: #333;
}

article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary, main {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ins {
  background-color: #ff9;
  color: #333;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #333;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #333;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
  -webkit-appearance: none;
  appearance: none;
}

/*-- ClearFix --*/
/* For modern browsers */
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* For IE 6/7 (trigger hasLayout) */
.clearfix {
  zoom: 1;
}

/*隙間消し*/
li img {
  vertical-align: bottom;
}

/* Reset input[type="search"] */
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

input[type=search]:focus {
  outline-offset: -2px;
}

input[type=search]::-webkit-search-decoration {
  display: none;
}

/* iOS reset */
input[type=submit] {
  -webkit-appearance: none;
}

@media print, screen and (min-width: 768px) {
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  @-moz-document url-prefix() {
    * {
      font-feature-settings: "palt";
    }
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 0) {
  * {
    font-feature-settings: "palt";
  }
}
/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: clamp(14.1666666667px, 0.8333333333vw, 1rem);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  html {
    overflow-y: auto;
    overflow-x: auto;
  }
}
@media screen and (max-width: 47.9375em) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: var(--font-primary);
  line-height: var(--line-height-default);
  font-weight: 500;
  font-feature-settings: "palt";
  letter-spacing: var(--ltr-space-default);
  *font-size: small;
  *font: x-small;
  color: var(--clr-body);
  -webkit-font-smoothing: antialiased;
  height: 100%;
  padding-top: 8rem;
  word-break: break-word;
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  body:not(.mce-content-body) {
    min-width: 67.5rem;
  }
}
@media screen and (max-width: 67.5em) {
  body {
    padding-top: 4.5714285714rem;
  }
}
@media screen and (max-width: 47.9375em) {
  body {
    -webkit-text-size-adjust: none;
  }
}

ul,
ol {
  list-style: none;
}

small {
  font-size: 0.875rem;
}
@media screen and (max-width: 47.9375em) {
  small {
    font-size: 0.9285714286rem;
  }
}

a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-link);
  text-decoration: none;
  transition: var(--transit-default);
  outline: none;
}

a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:focus {
  text-decoration: none !important;
}

@media print, screen and (min-width: 48em) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

img {
  vertical-align: middle;
}
@media screen and (max-width: 47.9375em) {
  img {
    max-width: 100%;
    height: auto;
  }
}

address,
em,
i {
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr);
}

svg {
  transition: var(--transit-default);
}
svg[preserveAspectRatio=none] {
  height: auto;
}

/* --- block --- */
.block {
  display: block;
}

/* --- scroll --- */
.scroll_line {
  height: 7.5rem;
  overflow: hidden;
}
.scroll_line .line {
  position: relative;
  width: 1px;
  height: 100%;
  margin: 0 auto;
  display: block;
  background-color: var(--clr-main);
}
.scroll_line .line::before {
  content: "";
  position: absolute;
  top: 0;
  left: -2px;
  width: 5px;
  height: 5px;
  background-color: var(--clr-main);
  border-radius: 50%;
  animation-name: Scroll;
  animation-duration: 2s;
  animation-timing-function: linear, linear;
  animation-iteration-count: infinite;
}
@media screen and (max-width: 47.9375em) {
  .scroll_line {
    height: 4.5714285714rem;
  }
}

/* txt
**************************************** */
.txt-lg {
  font-size: 1.125rem;
}
@media screen and (max-width: 47.9375em) {
  .txt-lg {
    font-size: 1.0714285714rem;
  }
}

.txt-sm {
  font-size: 0.875rem;
}
@media screen and (max-width: 47.9375em) {
  .txt-sm {
    font-size: 0.9285714286rem;
  }
}

.txt-wh,
.txt-wh * {
  color: var(--clr-wht);
}

.txt-main,
.txt-main * {
  color: var(--clr-main);
}

.txt-sub,
.txt-sub * {
  color: var(--clr-sub);
}

/* --- font-size --- */
.fz-8 {
  font-size: 0.5rem;
}

.fz-9 {
  font-size: 0.5625rem;
}

.fz-10 {
  font-size: 0.625rem;
}

.fz-11 {
  font-size: 0.6875rem;
}

.fz-12 {
  font-size: 0.75rem;
}

.fz-13 {
  font-size: 0.8125rem;
}

.fz-14 {
  font-size: 0.875rem;
}

.fz-15 {
  font-size: 0.9375rem;
}

.fz-16 {
  font-size: 1rem;
}

.fz-17 {
  font-size: 1.0625rem;
}

.fz-18 {
  font-size: 1.125rem;
}

.fz-19 {
  font-size: 1.1875rem;
}

.fz-20 {
  font-size: 1.25rem;
}

.fz-21 {
  font-size: 1.3125rem;
}

.fz-22 {
  font-size: 1.375rem;
}

.fz-23 {
  font-size: 1.4375rem;
}

.fz-24 {
  font-size: 1.5rem;
}

.fz-25 {
  font-size: 1.5625rem;
}

.fz-26 {
  font-size: 1.625rem;
}

.fz-27 {
  font-size: 1.6875rem;
}

.fz-28 {
  font-size: 1.75rem;
}

.fz-29 {
  font-size: 1.8125rem;
}

.fz-30 {
  font-size: 1.875rem;
}

.fz-31 {
  font-size: 1.9375rem;
}

.fz-32 {
  font-size: 2rem;
}

.fz-33 {
  font-size: 2.0625rem;
}

.fz-34 {
  font-size: 2.125rem;
}

.fz-35 {
  font-size: 2.1875rem;
}

.fz-36 {
  font-size: 2.25rem;
}

.fz-37 {
  font-size: 2.3125rem;
}

.fz-38 {
  font-size: 2.375rem;
}

.fz-39 {
  font-size: 2.4375rem;
}

.fz-40 {
  font-size: 2.5rem;
}

.fz-41 {
  font-size: 2.5625rem;
}

.fz-42 {
  font-size: 2.625rem;
}

.fz-43 {
  font-size: 2.6875rem;
}

.fz-44 {
  font-size: 2.75rem;
}

.fz-45 {
  font-size: 2.8125rem;
}

.fz-46 {
  font-size: 2.875rem;
}

.fz-47 {
  font-size: 2.9375rem;
}

.fz-48 {
  font-size: 3rem;
}

.fz-49 {
  font-size: 3.0625rem;
}

.fz-50 {
  font-size: 3.125rem;
}

.fz-51 {
  font-size: 3.1875rem;
}

.fz-52 {
  font-size: 3.25rem;
}

.fz-53 {
  font-size: 3.3125rem;
}

.fz-54 {
  font-size: 3.375rem;
}

.fz-55 {
  font-size: 3.4375rem;
}

.fz-56 {
  font-size: 3.5rem;
}

.fz-57 {
  font-size: 3.5625rem;
}

.fz-58 {
  font-size: 3.625rem;
}

.fz-59 {
  font-size: 3.6875rem;
}

.fz-60 {
  font-size: 3.75rem;
}

.fz-61 {
  font-size: 3.8125rem;
}

.fz-62 {
  font-size: 3.875rem;
}

.fz-63 {
  font-size: 3.9375rem;
}

.fz-64 {
  font-size: 4rem;
}

.fz-65 {
  font-size: 4.0625rem;
}

.fz-66 {
  font-size: 4.125rem;
}

.fz-67 {
  font-size: 4.1875rem;
}

.fz-68 {
  font-size: 4.25rem;
}

.fz-69 {
  font-size: 4.3125rem;
}

.fz-70 {
  font-size: 4.375rem;
}

.fz-71 {
  font-size: 4.4375rem;
}

.fz-72 {
  font-size: 4.5rem;
}

.fz-73 {
  font-size: 4.5625rem;
}

.fz-74 {
  font-size: 4.625rem;
}

.fz-75 {
  font-size: 4.6875rem;
}

.fz-76 {
  font-size: 4.75rem;
}

.fz-77 {
  font-size: 4.8125rem;
}

.fz-78 {
  font-size: 4.875rem;
}

.fz-79 {
  font-size: 4.9375rem;
}

.fz-80 {
  font-size: 5rem;
}

.fz-81 {
  font-size: 5.0625rem;
}

.fz-82 {
  font-size: 5.125rem;
}

.fz-83 {
  font-size: 5.1875rem;
}

.fz-84 {
  font-size: 5.25rem;
}

.fz-85 {
  font-size: 5.3125rem;
}

.fz-86 {
  font-size: 5.375rem;
}

.fz-87 {
  font-size: 5.4375rem;
}

.fz-88 {
  font-size: 5.5rem;
}

.fz-89 {
  font-size: 5.5625rem;
}

.fz-90 {
  font-size: 5.625rem;
}

.fz-91 {
  font-size: 5.6875rem;
}

.fz-92 {
  font-size: 5.75rem;
}

.fz-93 {
  font-size: 5.8125rem;
}

.fz-94 {
  font-size: 5.875rem;
}

.fz-95 {
  font-size: 5.9375rem;
}

.fz-96 {
  font-size: 6rem;
}

.fz-97 {
  font-size: 6.0625rem;
}

.fz-98 {
  font-size: 6.125rem;
}

.fz-99 {
  font-size: 6.1875rem;
}

.fz-100 {
  font-size: 6.25rem;
}

.fz-101 {
  font-size: 6.3125rem;
}

.fz-102 {
  font-size: 6.375rem;
}

.fz-103 {
  font-size: 6.4375rem;
}

.fz-104 {
  font-size: 6.5rem;
}

.fz-105 {
  font-size: 6.5625rem;
}

.fz-106 {
  font-size: 6.625rem;
}

.fz-107 {
  font-size: 6.6875rem;
}

.fz-108 {
  font-size: 6.75rem;
}

.fz-109 {
  font-size: 6.8125rem;
}

.fz-110 {
  font-size: 6.875rem;
}

.fz-111 {
  font-size: 6.9375rem;
}

.fz-112 {
  font-size: 7rem;
}

.fz-113 {
  font-size: 7.0625rem;
}

.fz-114 {
  font-size: 7.125rem;
}

.fz-115 {
  font-size: 7.1875rem;
}

.fz-116 {
  font-size: 7.25rem;
}

.fz-117 {
  font-size: 7.3125rem;
}

.fz-118 {
  font-size: 7.375rem;
}

.fz-119 {
  font-size: 7.4375rem;
}

.fz-120 {
  font-size: 7.5rem;
}

.fz-121 {
  font-size: 7.5625rem;
}

.fz-122 {
  font-size: 7.625rem;
}

.fz-123 {
  font-size: 7.6875rem;
}

.fz-124 {
  font-size: 7.75rem;
}

.fz-125 {
  font-size: 7.8125rem;
}

.fz-126 {
  font-size: 7.875rem;
}

.fz-127 {
  font-size: 7.9375rem;
}

.fz-128 {
  font-size: 8rem;
}

.fz-129 {
  font-size: 8.0625rem;
}

.fz-130 {
  font-size: 8.125rem;
}

.fz-131 {
  font-size: 8.1875rem;
}

.fz-132 {
  font-size: 8.25rem;
}

.fz-133 {
  font-size: 8.3125rem;
}

.fz-134 {
  font-size: 8.375rem;
}

.fz-135 {
  font-size: 8.4375rem;
}

.fz-136 {
  font-size: 8.5rem;
}

.fz-137 {
  font-size: 8.5625rem;
}

.fz-138 {
  font-size: 8.625rem;
}

.fz-139 {
  font-size: 8.6875rem;
}

/* --- font-family --- */
.font-jp {
  font-family: var(--font-jp);
  font-weight: 500;
}

.font-jp2 {
  font-family: var(--font-primary);
  font-weight: 700;
}

.font-en,
.font-en-sb,
.font-en-m {
  font-family: var(--font-en);
}

.font-en {
  font-weight: 400;
}

.font-en-m {
  font-weight: 500;
}

.font-en-sb {
  font-weight: 600;
}

.font-en2 {
  font-family: var(--font-en2);
  font-weight: 600;
}

.font-en3 {
  font-family: var(--font-en3);
  font-weight: 600;
}

.font-en3-m {
  font-family: var(--font-en3);
  font-weight: 500;
}

/* --- clip-txt --- */
.clip-txt1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.clip-txt2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.clip-txt3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.clip-txt4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

/* txt-ctr
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}

.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@media screen and (max-width: 47.9375em) {
  .txt-ctr-pc,
  .txt-rgt-pc {
    text-align: left;
  }
}
/* Wysiwyg editor
 **************************************** */
.mce-content-body ol {
  list-style: decimal;
}

.mce-content-body ul:not(:first-child),
.mce-content-body ol:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body ul:not(:last-child),
.mce-content-body ol:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  margin-left: 2.5em;
  padding-top: 0.5em;
}
.mce-content-body ul li::marker,
.mce-content-body ol li::marker {
  color: var(--clr-brn);
  font-weight: 600;
  font-family: var(--font-en2);
  letter-spacing: 0.2em;
}

.mce-content-body ul li {
  list-style: none;
}
.mce-content-body ul li::before {
  content: "";
  position: absolute;
  top: 1.0625em;
  left: -2.125em;
  width: 0.625em;
  aspect-ratio: 1/1;
  border: 1px solid var(--clr-brn);
  background-color: var(--clr-brn);
}

.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: var(--bg-wht);
}

.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}

.mce-content-body ol {
  list-style-type: decimal-leading-zero;
}

.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}

.mce-content-body .wp-caption {
  max-width: 100%;
}

.mce-content-body hr {
  border-width: 2px;
  border-color: var(--clr-darkblu);
}

.mce-content-body a img {
  transition: var(--transit-default);
}

.mce-content-body a:hover img {
  opacity: 0.8;
}

.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}

.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}

.mce-content-body .aligncenter {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}

.mce-content-body img.aligncenter {
  display: block;
}

.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}

.mce-content-body p {
  line-height: 2;
  letter-spacing: 0.06em;
}

.mce-content-body p:not(:last-child):not(.box--ttl) {
  margin-bottom: 1em;
}

.mce-content-body strong {
  font-weight: bold;
}

.mce-content-body blockquote {
  margin: 1em 0;
  padding: 1rem 2.5rem;
  border-radius: 0.1875rem;
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: #f8f8f8;
}
.mce-content-body blockquote::before, .mce-content-body blockquote::after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  opacity: 0.25;
}
.mce-content-body blockquote::before {
  background-image: url("../images/common/icon-quote_left.svg");
  top: 0.875rem;
  left: 0.75rem;
}
.mce-content-body blockquote::after {
  background-image: url("../images/common/icon-quote_right.svg");
  bottom: 0.875rem;
  right: 0.75rem;
}

.mce-content-body blockquote p {
  margin-bottom: 0;
}

.mce-content-body a {
  text-decoration: underline;
}
.mce-content-body a:hover {
  text-decoration: none;
}

.mce-content-body table {
  border: none;
  height: auto !important;
  font-size: 0.9375rem;
}
.mce-content-body table:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body table:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}

.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 1px solid #D3D3D3;
  vertical-align: top;
}

.mce-content-body th {
  min-width: 7.5rem;
  text-align: left;
  font-family: var(--font-primary);
  font-weight: 700;
}

.mce-content-body sup {
  font-size: 0.75rem;
  color: #555;
}

.bold {
  font-weight: bold;
  color: var(--clr-main);
}

/* iframe */
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
}
.mce-content-body iframe:not(:first-child) {
  margin-top: 2em;
}
.mce-content-body iframe:not(:last-child) {
  margin-bottom: 2em;
}

/* table scroll */
.table-sp-scroll {
  white-space: nowrap;
}

.scroll {
  overflow-x: scroll;
  margin-bottom: 1em;
  -webkit-overflow-scrolling: touch;
}

.scroll table {
  margin: 0;
}

.scroll--cap {
  text-align: right;
  margin: 1em 0 0 !important;
  opacity: 0.25;
  font-size: 0.875rem;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body ul li,
  .mce-content-body ol li {
    margin-left: 2em;
  }
  .mce-content-body ul li::marker,
  .mce-content-body ol li::marker {
    letter-spacing: 0.05em;
  }
  .mce-content-body ul li::before {
    left: -1.5em;
  }
  .mce-content-body p {
    line-height: 1.8;
    letter-spacing: 0.04em;
  }
  .mce-content-body .alignright,
  .mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: 1rem;
  }
  .mce-content-body blockquote::before {
    top: 0;
    left: 0;
  }
  .mce-content-body blockquote::after {
    bottom: 0;
    right: 0;
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: 0.5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body iframe[src*="google.com/maps"] {
    aspect-ratio: 4/3;
  }
}
/* AddQuicktag
*************************************************** */
/* 見出し */
.editor-ttl,
.editor-ttl2 {
  font-family: var(--font-jp);
  font-weight: 500;
}
.editor-ttl:not(:first-child),
.editor-ttl2:not(:first-child) {
  margin-top: 1em;
}
.editor-ttl:not(:last-child),
.editor-ttl2:not(:last-child) {
  margin-bottom: 1em;
}

.editor-ttl3 {
  font-family: var(--font-primary);
  font-weight: 700;
}
.editor-ttl3:not(:first-child) {
  margin-top: 1em;
}
.editor-ttl3:not(:last-child) {
  margin-bottom: 1em;
}

.editor-ttl {
  font-size: 2.25rem;
  letter-spacing: 0.05em;
  line-height: 1.6;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl {
    font-size: 1.5714285714rem;
    line-height: 1.4;
    letter-spacing: 0.015em;
  }
}

.editor-ttl2 {
  font-size: 1.75rem;
  line-height: 1.4;
  letter-spacing: 0.03em;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl2 {
    font-size: 1.4285714286rem;
    letter-spacing: 0.015em;
  }
}

.editor-ttl3 {
  font-size: 1.25rem;
  line-height: 1.7;
  letter-spacing: 0.07em;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl3 {
    font-size: 1.1428571429rem;
    line-height: 1.625;
    letter-spacing: 0.035em;
  }
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: 0.875rem;
  opacity: 0.6;
}
@media screen and (max-width: 47.9375em) {
  .txt-attention {
    font-size: 0.9285714286rem;
  }
}
.txt-attention::before {
  content: "※";
}

/* テーブル */
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
}
.table1 th::before,
.table1 td::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: calc(100% - 0.1875rem);
}

.table1 th {
  color: var(--clr-main);
}
.table1 th::before {
  left: 0;
  height: 2px;
  background-color: var(--clr-main);
}

.table1 td::before {
  right: 0;
  height: 1px;
  background-color: #D3D3D3;
}

.table2 {
  background-color: var(--bg-wht);
}
@media print, screen and (min-width: 48em) {
  .table2 th {
    border-right: 0;
  }
  .table2 td {
    border-left: 0;
  }
}

.table2 th {
  background-color: rgba(97, 100, 119, 0.1);
}

/* ボックス */
.box {
  padding: 1.875rem 2.5rem;
  background-image: var(--ptn-blu);
  color: var(--clr-wht);
}
@media screen and (max-width: 47.9375em) {
  .box {
    padding: 1.7142857143rem;
  }
}
.box:not(:first-child) {
  margin-top: 2em;
}
.box:not(:last-child) {
  margin-bottom: 2em;
}

.box * {
  color: var(--clr-wht);
}

.box .box--ttl {
  font-family: var(--font-en);
  font-weight: 400;
  color: var(--clr-gold);
  font-size: 2.5rem;
  line-height: 1.4;
  letter-spacing: 0.04em;
  margin-bottom: 0.875rem;
  position: relative;
}
.box .box--ttl::before {
  content: "";
  width: 4px;
  height: 15px;
  background-color: var(--clr-gold);
  position: absolute;
  left: -2.5rem;
  top: 0.5em;
}

.lps_sec:nth-child(even) *:not(.bg-wh) .box,
.lps_sec[style*=background-color] *:not(.bg-wh) .box {
  background-color: var(--bg-wht);
  background-image: none;
  color: var(--clr-main);
}
.lps_sec:nth-child(even) *:not(.bg-wh) .box *,
.lps_sec[style*=background-color] *:not(.bg-wh) .box * {
  color: var(--clr-main);
}

/* 2段組みボックス */
.box-col2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
}

/* リスト */
ul.check--list li::before, ul.check--list li::after {
  content: "";
  position: absolute;
}
ul.check--list li::before {
  top: 0.625em;
  left: -2.5em;
  width: 1.5em;
  aspect-ratio: 1/1;
  background-color: var(--clr-brn);
  border-radius: 50%;
}
ul.check--list li::after {
  top: 1.0625em;
  left: -2.0625em;
  width: 0.625em;
  height: 0.4375em;
  border-left: 2px solid var(--clr-wht);
  border-bottom: 2px solid var(--clr-wht);
  transform: rotate(-45deg);
}

@media screen and (max-width: 47.9375em) {
  /* テーブル */
  .table1.table-sp-block td::before {
    display: none;
  }
  .table2.table-sp-block th,
  .table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #D3D3D3;
  }
  /* ボックス */
  .box .box--ttl {
    font-size: 1.7142857143rem;
    margin-bottom: 0.4285714286rem;
  }
  .box .box--ttl::before {
    width: 2px;
    left: -1.7142857143rem;
  }
}
/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor,
.mce-content-body.wp-editor * {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
}

.mce-content-body.wp-editor {
  font-size: 16px;
  padding: 1.5rem !important;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}
.txt-decoration1,
.txt-decoration2,
.txt-decoration3 {
  font-weight: 600;
  letter-spacing: 0.12em;
  font-family: var(--font-en2);
  font-weight: 600;
  font-size: 0.875rem;
}

.txt-decoration1 {
  color: var(--clr-main);
}

.txt-decoration1 span {
  position: relative;
  display: inline-block;
  padding-bottom: 0.625rem;
}
.txt-decoration1 span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1.5rem;
  height: 1px;
  background-color: var(--bg-main);
}

.txt-decoration2 span {
  position: relative;
  display: inline-block;
  padding: 0 24px;
  color: var(--clr-main);
}
.txt-decoration2 span::before, .txt-decoration2 span::after {
  content: "";
  position: absolute;
  bottom: 0.5em;
  width: 2px;
  height: 0.875rem;
  background-color: var(--bg-main);
}
.txt-decoration2 span::before {
  left: 0;
  transform: rotate(-45deg);
}
.txt-decoration2 span::after {
  right: 0;
  transform: rotate(45deg);
}

.txt-decoration3 span {
  display: inline-block;
  padding: 0 22px;
  background-image: var(--ptn-blu);
  color: #fff;
}

.num-decoration1 .num-decoration--wrap,
.num-decoration2 .num-decoration--wrap {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: var(--ptn-blu);
  color: var(--clr-wht);
}
.num-decoration1 .num-decoration--wrap .txt,
.num-decoration2 .num-decoration--wrap .txt {
  display: block;
  font-size: 0.6875rem;
  letter-spacing: 0.16em;
  font-family: var(--font-en);
  font-weight: 600;
}
.num-decoration1 .num-decoration--wrap .num,
.num-decoration2 .num-decoration--wrap .num {
  line-height: 1;
  font-size: 2.75rem;
  font-family: var(--font-en3);
  font-weight: 500;
}

.num-decoration1 .num-decoration--wrap {
  width: 6rem;
  height: 6rem;
  padding-bottom: 0.5em;
}

.num-decoration2 .num-decoration--wrap {
  position: relative;
  width: 5.5rem;
  height: 6.25rem;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 80%, 0 100%);
  padding: 1.75rem 0.625rem 2.25rem;
}
.num-decoration2 .num-decoration--wrap .txt {
  line-height: 1;
}

.num-decoration3 .num-decoration--wrap {
  display: inline-flex;
  align-items: flex-start;
  color: var(--clr-main);
  font-weight: 600;
}
.num-decoration3 .num-decoration--wrap .txt {
  display: inline-block;
  font-size: 0.6875rem;
  letter-spacing: 0.16em;
  position: relative;
  font-family: var(--font-en);
  font-weight: 600;
  padding-bottom: 0.625rem;
}
.num-decoration3 .num-decoration--wrap .txt::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1.5rem;
  height: 1px;
  background-color: var(--bg-main);
}
.num-decoration3 .num-decoration--wrap .num {
  line-height: 0.6;
  font-size: 8rem;
  font-family: var(--font-en3);
  font-weight: 500;
  letter-spacing: 0.045em;
}

@media screen and (max-width: 47.9375em) {
  .txt-decoration1, .txt-decoration2, .txt-decoration3 {
    font-size: 0.7142857143rem;
  }
  .num-decoration1 .num-decoration--wrap {
    width: 4.2857142857rem;
    height: 4.2857142857rem;
  }
  .num-decoration2 .num-decoration--wrap {
    width: 4.2857142857rem;
    height: 5rem;
  }
  .num-decoration1 .num-decoration--wrap .num,
  .num-decoration2 .num-decoration--wrap .num {
    font-size: 1.8571428571rem;
  }
  .num-decoration3 .num-decoration--wrap .num {
    font-size: 5rem;
  }
}
/* bg
********************************************** */
.bg-main {
  background-color: var(--bg-main);
}

.bg-sub {
  background-color: var(--bg-sub);
}

.bg-wh {
  background-color: var(--bg-wht);
}

.bg-off_wh {
  background-color: var(--bg-off_wht);
}

.bg-ptn-be {
  background-image: var(--ptn-be);
}

.bg-ptn-gry {
  background-image: var(--ptn-gry);
}

.bg-ptn-blu {
  background-image: var(--ptn-blu);
}

/* --- pc or sp --- */
.pc-none,
.pc-none-inline,
.pc-none-table,
.pc-none-flex {
  display: none;
}

.sp-none {
  display: block;
}

.sp-none-inline {
  display: inline;
}

.sp-none-table {
  display: table;
}

.sp-none-flex {
  display: flex;
}

@media screen and (max-width: 47.9375em) {
  /* --- pc or sp --- */
  .sp-none,
  .sp-none-inline,
  .sp-none-table,
  .sp-none-flex {
    display: none;
  }
  .pc-none {
    display: block;
  }
  .pc-none-inline {
    display: inline;
  }
  .pc-none-table {
    display: table;
  }
  .pc-none-flex {
    display: flex;
  }
}
/* img
********************************************** */
.img-ctr {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-trim {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trim-widescreen {
  aspect-ratio: 3/2;
}

.trim-square {
  aspect-ratio: 1/1;
}

.trim-portrait {
  aspect-ratio: 2/3;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.mgn-0 {
  margin: 0rem;
}

.mgn-top0 {
  margin-top: 0rem;
}

.mgn-rgt0 {
  margin-right: 0rem;
}

.mgn-btm0 {
  margin-bottom: 0rem;
}

.mgn-lft0 {
  margin-left: 0rem;
}

.mgn-1 {
  margin: 0.0625rem;
}

.mgn-top1 {
  margin-top: 0.0625rem;
}

.mgn-rgt1 {
  margin-right: 0.0625rem;
}

.mgn-btm1 {
  margin-bottom: 0.0625rem;
}

.mgn-lft1 {
  margin-left: 0.0625rem;
}

.mgn-2 {
  margin: 0.125rem;
}

.mgn-top2 {
  margin-top: 0.125rem;
}

.mgn-rgt2 {
  margin-right: 0.125rem;
}

.mgn-btm2 {
  margin-bottom: 0.125rem;
}

.mgn-lft2 {
  margin-left: 0.125rem;
}

.mgn-3 {
  margin: 0.1875rem;
}

.mgn-top3 {
  margin-top: 0.1875rem;
}

.mgn-rgt3 {
  margin-right: 0.1875rem;
}

.mgn-btm3 {
  margin-bottom: 0.1875rem;
}

.mgn-lft3 {
  margin-left: 0.1875rem;
}

.mgn-4 {
  margin: 0.25rem;
}

.mgn-top4 {
  margin-top: 0.25rem;
}

.mgn-rgt4 {
  margin-right: 0.25rem;
}

.mgn-btm4 {
  margin-bottom: 0.25rem;
}

.mgn-lft4 {
  margin-left: 0.25rem;
}

.mgn-5 {
  margin: 0.3125rem;
}

.mgn-top5 {
  margin-top: 0.3125rem;
}

.mgn-rgt5 {
  margin-right: 0.3125rem;
}

.mgn-btm5 {
  margin-bottom: 0.3125rem;
}

.mgn-lft5 {
  margin-left: 0.3125rem;
}

.mgn-6 {
  margin: 0.375rem;
}

.mgn-top6 {
  margin-top: 0.375rem;
}

.mgn-rgt6 {
  margin-right: 0.375rem;
}

.mgn-btm6 {
  margin-bottom: 0.375rem;
}

.mgn-lft6 {
  margin-left: 0.375rem;
}

.mgn-7 {
  margin: 0.4375rem;
}

.mgn-top7 {
  margin-top: 0.4375rem;
}

.mgn-rgt7 {
  margin-right: 0.4375rem;
}

.mgn-btm7 {
  margin-bottom: 0.4375rem;
}

.mgn-lft7 {
  margin-left: 0.4375rem;
}

.mgn-8 {
  margin: 0.5rem;
}

.mgn-top8 {
  margin-top: 0.5rem;
}

.mgn-rgt8 {
  margin-right: 0.5rem;
}

.mgn-btm8 {
  margin-bottom: 0.5rem;
}

.mgn-lft8 {
  margin-left: 0.5rem;
}

.mgn-9 {
  margin: 0.5625rem;
}

.mgn-top9 {
  margin-top: 0.5625rem;
}

.mgn-rgt9 {
  margin-right: 0.5625rem;
}

.mgn-btm9 {
  margin-bottom: 0.5625rem;
}

.mgn-lft9 {
  margin-left: 0.5625rem;
}

.mgn-10 {
  margin: 0.625rem;
}

.mgn-top10 {
  margin-top: 0.625rem;
}

.mgn-rgt10 {
  margin-right: 0.625rem;
}

.mgn-btm10 {
  margin-bottom: 0.625rem;
}

.mgn-lft10 {
  margin-left: 0.625rem;
}

.mgn-11 {
  margin: 0.6875rem;
}

.mgn-top11 {
  margin-top: 0.6875rem;
}

.mgn-rgt11 {
  margin-right: 0.6875rem;
}

.mgn-btm11 {
  margin-bottom: 0.6875rem;
}

.mgn-lft11 {
  margin-left: 0.6875rem;
}

.mgn-12 {
  margin: 0.75rem;
}

.mgn-top12 {
  margin-top: 0.75rem;
}

.mgn-rgt12 {
  margin-right: 0.75rem;
}

.mgn-btm12 {
  margin-bottom: 0.75rem;
}

.mgn-lft12 {
  margin-left: 0.75rem;
}

.mgn-13 {
  margin: 0.8125rem;
}

.mgn-top13 {
  margin-top: 0.8125rem;
}

.mgn-rgt13 {
  margin-right: 0.8125rem;
}

.mgn-btm13 {
  margin-bottom: 0.8125rem;
}

.mgn-lft13 {
  margin-left: 0.8125rem;
}

.mgn-14 {
  margin: 0.875rem;
}

.mgn-top14 {
  margin-top: 0.875rem;
}

.mgn-rgt14 {
  margin-right: 0.875rem;
}

.mgn-btm14 {
  margin-bottom: 0.875rem;
}

.mgn-lft14 {
  margin-left: 0.875rem;
}

.mgn-15 {
  margin: 0.9375rem;
}

.mgn-top15 {
  margin-top: 0.9375rem;
}

.mgn-rgt15 {
  margin-right: 0.9375rem;
}

.mgn-btm15 {
  margin-bottom: 0.9375rem;
}

.mgn-lft15 {
  margin-left: 0.9375rem;
}

.mgn-16 {
  margin: 1rem;
}

.mgn-top16 {
  margin-top: 1rem;
}

.mgn-rgt16 {
  margin-right: 1rem;
}

.mgn-btm16 {
  margin-bottom: 1rem;
}

.mgn-lft16 {
  margin-left: 1rem;
}

.mgn-17 {
  margin: 1.0625rem;
}

.mgn-top17 {
  margin-top: 1.0625rem;
}

.mgn-rgt17 {
  margin-right: 1.0625rem;
}

.mgn-btm17 {
  margin-bottom: 1.0625rem;
}

.mgn-lft17 {
  margin-left: 1.0625rem;
}

.mgn-18 {
  margin: 1.125rem;
}

.mgn-top18 {
  margin-top: 1.125rem;
}

.mgn-rgt18 {
  margin-right: 1.125rem;
}

.mgn-btm18 {
  margin-bottom: 1.125rem;
}

.mgn-lft18 {
  margin-left: 1.125rem;
}

.mgn-19 {
  margin: 1.1875rem;
}

.mgn-top19 {
  margin-top: 1.1875rem;
}

.mgn-rgt19 {
  margin-right: 1.1875rem;
}

.mgn-btm19 {
  margin-bottom: 1.1875rem;
}

.mgn-lft19 {
  margin-left: 1.1875rem;
}

.mgn-20 {
  margin: 1.25rem;
}

.mgn-top20 {
  margin-top: 1.25rem;
}

.mgn-rgt20 {
  margin-right: 1.25rem;
}

.mgn-btm20 {
  margin-bottom: 1.25rem;
}

.mgn-lft20 {
  margin-left: 1.25rem;
}

.mgn-21 {
  margin: 1.3125rem;
}

.mgn-top21 {
  margin-top: 1.3125rem;
}

.mgn-rgt21 {
  margin-right: 1.3125rem;
}

.mgn-btm21 {
  margin-bottom: 1.3125rem;
}

.mgn-lft21 {
  margin-left: 1.3125rem;
}

.mgn-22 {
  margin: 1.375rem;
}

.mgn-top22 {
  margin-top: 1.375rem;
}

.mgn-rgt22 {
  margin-right: 1.375rem;
}

.mgn-btm22 {
  margin-bottom: 1.375rem;
}

.mgn-lft22 {
  margin-left: 1.375rem;
}

.mgn-23 {
  margin: 1.4375rem;
}

.mgn-top23 {
  margin-top: 1.4375rem;
}

.mgn-rgt23 {
  margin-right: 1.4375rem;
}

.mgn-btm23 {
  margin-bottom: 1.4375rem;
}

.mgn-lft23 {
  margin-left: 1.4375rem;
}

.mgn-24 {
  margin: 1.5rem;
}

.mgn-top24 {
  margin-top: 1.5rem;
}

.mgn-rgt24 {
  margin-right: 1.5rem;
}

.mgn-btm24 {
  margin-bottom: 1.5rem;
}

.mgn-lft24 {
  margin-left: 1.5rem;
}

.mgn-25 {
  margin: 1.5625rem;
}

.mgn-top25 {
  margin-top: 1.5625rem;
}

.mgn-rgt25 {
  margin-right: 1.5625rem;
}

.mgn-btm25 {
  margin-bottom: 1.5625rem;
}

.mgn-lft25 {
  margin-left: 1.5625rem;
}

.mgn-26 {
  margin: 1.625rem;
}

.mgn-top26 {
  margin-top: 1.625rem;
}

.mgn-rgt26 {
  margin-right: 1.625rem;
}

.mgn-btm26 {
  margin-bottom: 1.625rem;
}

.mgn-lft26 {
  margin-left: 1.625rem;
}

.mgn-27 {
  margin: 1.6875rem;
}

.mgn-top27 {
  margin-top: 1.6875rem;
}

.mgn-rgt27 {
  margin-right: 1.6875rem;
}

.mgn-btm27 {
  margin-bottom: 1.6875rem;
}

.mgn-lft27 {
  margin-left: 1.6875rem;
}

.mgn-28 {
  margin: 1.75rem;
}

.mgn-top28 {
  margin-top: 1.75rem;
}

.mgn-rgt28 {
  margin-right: 1.75rem;
}

.mgn-btm28 {
  margin-bottom: 1.75rem;
}

.mgn-lft28 {
  margin-left: 1.75rem;
}

.mgn-29 {
  margin: 1.8125rem;
}

.mgn-top29 {
  margin-top: 1.8125rem;
}

.mgn-rgt29 {
  margin-right: 1.8125rem;
}

.mgn-btm29 {
  margin-bottom: 1.8125rem;
}

.mgn-lft29 {
  margin-left: 1.8125rem;
}

.mgn-30 {
  margin: 1.875rem;
}

.mgn-top30 {
  margin-top: 1.875rem;
}

.mgn-rgt30 {
  margin-right: 1.875rem;
}

.mgn-btm30 {
  margin-bottom: 1.875rem;
}

.mgn-lft30 {
  margin-left: 1.875rem;
}

.mgn-31 {
  margin: 1.9375rem;
}

.mgn-top31 {
  margin-top: 1.9375rem;
}

.mgn-rgt31 {
  margin-right: 1.9375rem;
}

.mgn-btm31 {
  margin-bottom: 1.9375rem;
}

.mgn-lft31 {
  margin-left: 1.9375rem;
}

.mgn-32 {
  margin: 2rem;
}

.mgn-top32 {
  margin-top: 2rem;
}

.mgn-rgt32 {
  margin-right: 2rem;
}

.mgn-btm32 {
  margin-bottom: 2rem;
}

.mgn-lft32 {
  margin-left: 2rem;
}

.mgn-33 {
  margin: 2.0625rem;
}

.mgn-top33 {
  margin-top: 2.0625rem;
}

.mgn-rgt33 {
  margin-right: 2.0625rem;
}

.mgn-btm33 {
  margin-bottom: 2.0625rem;
}

.mgn-lft33 {
  margin-left: 2.0625rem;
}

.mgn-34 {
  margin: 2.125rem;
}

.mgn-top34 {
  margin-top: 2.125rem;
}

.mgn-rgt34 {
  margin-right: 2.125rem;
}

.mgn-btm34 {
  margin-bottom: 2.125rem;
}

.mgn-lft34 {
  margin-left: 2.125rem;
}

.mgn-35 {
  margin: 2.1875rem;
}

.mgn-top35 {
  margin-top: 2.1875rem;
}

.mgn-rgt35 {
  margin-right: 2.1875rem;
}

.mgn-btm35 {
  margin-bottom: 2.1875rem;
}

.mgn-lft35 {
  margin-left: 2.1875rem;
}

.mgn-36 {
  margin: 2.25rem;
}

.mgn-top36 {
  margin-top: 2.25rem;
}

.mgn-rgt36 {
  margin-right: 2.25rem;
}

.mgn-btm36 {
  margin-bottom: 2.25rem;
}

.mgn-lft36 {
  margin-left: 2.25rem;
}

.mgn-37 {
  margin: 2.3125rem;
}

.mgn-top37 {
  margin-top: 2.3125rem;
}

.mgn-rgt37 {
  margin-right: 2.3125rem;
}

.mgn-btm37 {
  margin-bottom: 2.3125rem;
}

.mgn-lft37 {
  margin-left: 2.3125rem;
}

.mgn-38 {
  margin: 2.375rem;
}

.mgn-top38 {
  margin-top: 2.375rem;
}

.mgn-rgt38 {
  margin-right: 2.375rem;
}

.mgn-btm38 {
  margin-bottom: 2.375rem;
}

.mgn-lft38 {
  margin-left: 2.375rem;
}

.mgn-39 {
  margin: 2.4375rem;
}

.mgn-top39 {
  margin-top: 2.4375rem;
}

.mgn-rgt39 {
  margin-right: 2.4375rem;
}

.mgn-btm39 {
  margin-bottom: 2.4375rem;
}

.mgn-lft39 {
  margin-left: 2.4375rem;
}

.mgn-40 {
  margin: 2.5rem;
}

.mgn-top40 {
  margin-top: 2.5rem;
}

.mgn-rgt40 {
  margin-right: 2.5rem;
}

.mgn-btm40 {
  margin-bottom: 2.5rem;
}

.mgn-lft40 {
  margin-left: 2.5rem;
}

.mgn-41 {
  margin: 2.5625rem;
}

.mgn-top41 {
  margin-top: 2.5625rem;
}

.mgn-rgt41 {
  margin-right: 2.5625rem;
}

.mgn-btm41 {
  margin-bottom: 2.5625rem;
}

.mgn-lft41 {
  margin-left: 2.5625rem;
}

.mgn-42 {
  margin: 2.625rem;
}

.mgn-top42 {
  margin-top: 2.625rem;
}

.mgn-rgt42 {
  margin-right: 2.625rem;
}

.mgn-btm42 {
  margin-bottom: 2.625rem;
}

.mgn-lft42 {
  margin-left: 2.625rem;
}

.mgn-43 {
  margin: 2.6875rem;
}

.mgn-top43 {
  margin-top: 2.6875rem;
}

.mgn-rgt43 {
  margin-right: 2.6875rem;
}

.mgn-btm43 {
  margin-bottom: 2.6875rem;
}

.mgn-lft43 {
  margin-left: 2.6875rem;
}

.mgn-44 {
  margin: 2.75rem;
}

.mgn-top44 {
  margin-top: 2.75rem;
}

.mgn-rgt44 {
  margin-right: 2.75rem;
}

.mgn-btm44 {
  margin-bottom: 2.75rem;
}

.mgn-lft44 {
  margin-left: 2.75rem;
}

.mgn-45 {
  margin: 2.8125rem;
}

.mgn-top45 {
  margin-top: 2.8125rem;
}

.mgn-rgt45 {
  margin-right: 2.8125rem;
}

.mgn-btm45 {
  margin-bottom: 2.8125rem;
}

.mgn-lft45 {
  margin-left: 2.8125rem;
}

.mgn-46 {
  margin: 2.875rem;
}

.mgn-top46 {
  margin-top: 2.875rem;
}

.mgn-rgt46 {
  margin-right: 2.875rem;
}

.mgn-btm46 {
  margin-bottom: 2.875rem;
}

.mgn-lft46 {
  margin-left: 2.875rem;
}

.mgn-47 {
  margin: 2.9375rem;
}

.mgn-top47 {
  margin-top: 2.9375rem;
}

.mgn-rgt47 {
  margin-right: 2.9375rem;
}

.mgn-btm47 {
  margin-bottom: 2.9375rem;
}

.mgn-lft47 {
  margin-left: 2.9375rem;
}

.mgn-48 {
  margin: 3rem;
}

.mgn-top48 {
  margin-top: 3rem;
}

.mgn-rgt48 {
  margin-right: 3rem;
}

.mgn-btm48 {
  margin-bottom: 3rem;
}

.mgn-lft48 {
  margin-left: 3rem;
}

.mgn-49 {
  margin: 3.0625rem;
}

.mgn-top49 {
  margin-top: 3.0625rem;
}

.mgn-rgt49 {
  margin-right: 3.0625rem;
}

.mgn-btm49 {
  margin-bottom: 3.0625rem;
}

.mgn-lft49 {
  margin-left: 3.0625rem;
}

.mgn-50 {
  margin: 3.125rem;
}

.mgn-top50 {
  margin-top: 3.125rem;
}

.mgn-rgt50 {
  margin-right: 3.125rem;
}

.mgn-btm50 {
  margin-bottom: 3.125rem;
}

.mgn-lft50 {
  margin-left: 3.125rem;
}

.mgn-51 {
  margin: 3.1875rem;
}

.mgn-top51 {
  margin-top: 3.1875rem;
}

.mgn-rgt51 {
  margin-right: 3.1875rem;
}

.mgn-btm51 {
  margin-bottom: 3.1875rem;
}

.mgn-lft51 {
  margin-left: 3.1875rem;
}

.mgn-52 {
  margin: 3.25rem;
}

.mgn-top52 {
  margin-top: 3.25rem;
}

.mgn-rgt52 {
  margin-right: 3.25rem;
}

.mgn-btm52 {
  margin-bottom: 3.25rem;
}

.mgn-lft52 {
  margin-left: 3.25rem;
}

.mgn-53 {
  margin: 3.3125rem;
}

.mgn-top53 {
  margin-top: 3.3125rem;
}

.mgn-rgt53 {
  margin-right: 3.3125rem;
}

.mgn-btm53 {
  margin-bottom: 3.3125rem;
}

.mgn-lft53 {
  margin-left: 3.3125rem;
}

.mgn-54 {
  margin: 3.375rem;
}

.mgn-top54 {
  margin-top: 3.375rem;
}

.mgn-rgt54 {
  margin-right: 3.375rem;
}

.mgn-btm54 {
  margin-bottom: 3.375rem;
}

.mgn-lft54 {
  margin-left: 3.375rem;
}

.mgn-55 {
  margin: 3.4375rem;
}

.mgn-top55 {
  margin-top: 3.4375rem;
}

.mgn-rgt55 {
  margin-right: 3.4375rem;
}

.mgn-btm55 {
  margin-bottom: 3.4375rem;
}

.mgn-lft55 {
  margin-left: 3.4375rem;
}

.mgn-56 {
  margin: 3.5rem;
}

.mgn-top56 {
  margin-top: 3.5rem;
}

.mgn-rgt56 {
  margin-right: 3.5rem;
}

.mgn-btm56 {
  margin-bottom: 3.5rem;
}

.mgn-lft56 {
  margin-left: 3.5rem;
}

.mgn-57 {
  margin: 3.5625rem;
}

.mgn-top57 {
  margin-top: 3.5625rem;
}

.mgn-rgt57 {
  margin-right: 3.5625rem;
}

.mgn-btm57 {
  margin-bottom: 3.5625rem;
}

.mgn-lft57 {
  margin-left: 3.5625rem;
}

.mgn-58 {
  margin: 3.625rem;
}

.mgn-top58 {
  margin-top: 3.625rem;
}

.mgn-rgt58 {
  margin-right: 3.625rem;
}

.mgn-btm58 {
  margin-bottom: 3.625rem;
}

.mgn-lft58 {
  margin-left: 3.625rem;
}

.mgn-59 {
  margin: 3.6875rem;
}

.mgn-top59 {
  margin-top: 3.6875rem;
}

.mgn-rgt59 {
  margin-right: 3.6875rem;
}

.mgn-btm59 {
  margin-bottom: 3.6875rem;
}

.mgn-lft59 {
  margin-left: 3.6875rem;
}

.mgn-60 {
  margin: 3.75rem;
}

.mgn-top60 {
  margin-top: 3.75rem;
}

.mgn-rgt60 {
  margin-right: 3.75rem;
}

.mgn-btm60 {
  margin-bottom: 3.75rem;
}

.mgn-lft60 {
  margin-left: 3.75rem;
}

.mgn-61 {
  margin: 3.8125rem;
}

.mgn-top61 {
  margin-top: 3.8125rem;
}

.mgn-rgt61 {
  margin-right: 3.8125rem;
}

.mgn-btm61 {
  margin-bottom: 3.8125rem;
}

.mgn-lft61 {
  margin-left: 3.8125rem;
}

.mgn-62 {
  margin: 3.875rem;
}

.mgn-top62 {
  margin-top: 3.875rem;
}

.mgn-rgt62 {
  margin-right: 3.875rem;
}

.mgn-btm62 {
  margin-bottom: 3.875rem;
}

.mgn-lft62 {
  margin-left: 3.875rem;
}

.mgn-63 {
  margin: 3.9375rem;
}

.mgn-top63 {
  margin-top: 3.9375rem;
}

.mgn-rgt63 {
  margin-right: 3.9375rem;
}

.mgn-btm63 {
  margin-bottom: 3.9375rem;
}

.mgn-lft63 {
  margin-left: 3.9375rem;
}

.mgn-64 {
  margin: 4rem;
}

.mgn-top64 {
  margin-top: 4rem;
}

.mgn-rgt64 {
  margin-right: 4rem;
}

.mgn-btm64 {
  margin-bottom: 4rem;
}

.mgn-lft64 {
  margin-left: 4rem;
}

.mgn-65 {
  margin: 4.0625rem;
}

.mgn-top65 {
  margin-top: 4.0625rem;
}

.mgn-rgt65 {
  margin-right: 4.0625rem;
}

.mgn-btm65 {
  margin-bottom: 4.0625rem;
}

.mgn-lft65 {
  margin-left: 4.0625rem;
}

.mgn-66 {
  margin: 4.125rem;
}

.mgn-top66 {
  margin-top: 4.125rem;
}

.mgn-rgt66 {
  margin-right: 4.125rem;
}

.mgn-btm66 {
  margin-bottom: 4.125rem;
}

.mgn-lft66 {
  margin-left: 4.125rem;
}

.mgn-67 {
  margin: 4.1875rem;
}

.mgn-top67 {
  margin-top: 4.1875rem;
}

.mgn-rgt67 {
  margin-right: 4.1875rem;
}

.mgn-btm67 {
  margin-bottom: 4.1875rem;
}

.mgn-lft67 {
  margin-left: 4.1875rem;
}

.mgn-68 {
  margin: 4.25rem;
}

.mgn-top68 {
  margin-top: 4.25rem;
}

.mgn-rgt68 {
  margin-right: 4.25rem;
}

.mgn-btm68 {
  margin-bottom: 4.25rem;
}

.mgn-lft68 {
  margin-left: 4.25rem;
}

.mgn-69 {
  margin: 4.3125rem;
}

.mgn-top69 {
  margin-top: 4.3125rem;
}

.mgn-rgt69 {
  margin-right: 4.3125rem;
}

.mgn-btm69 {
  margin-bottom: 4.3125rem;
}

.mgn-lft69 {
  margin-left: 4.3125rem;
}

.mgn-70 {
  margin: 4.375rem;
}

.mgn-top70 {
  margin-top: 4.375rem;
}

.mgn-rgt70 {
  margin-right: 4.375rem;
}

.mgn-btm70 {
  margin-bottom: 4.375rem;
}

.mgn-lft70 {
  margin-left: 4.375rem;
}

.mgn-71 {
  margin: 4.4375rem;
}

.mgn-top71 {
  margin-top: 4.4375rem;
}

.mgn-rgt71 {
  margin-right: 4.4375rem;
}

.mgn-btm71 {
  margin-bottom: 4.4375rem;
}

.mgn-lft71 {
  margin-left: 4.4375rem;
}

.mgn-72 {
  margin: 4.5rem;
}

.mgn-top72 {
  margin-top: 4.5rem;
}

.mgn-rgt72 {
  margin-right: 4.5rem;
}

.mgn-btm72 {
  margin-bottom: 4.5rem;
}

.mgn-lft72 {
  margin-left: 4.5rem;
}

.mgn-73 {
  margin: 4.5625rem;
}

.mgn-top73 {
  margin-top: 4.5625rem;
}

.mgn-rgt73 {
  margin-right: 4.5625rem;
}

.mgn-btm73 {
  margin-bottom: 4.5625rem;
}

.mgn-lft73 {
  margin-left: 4.5625rem;
}

.mgn-74 {
  margin: 4.625rem;
}

.mgn-top74 {
  margin-top: 4.625rem;
}

.mgn-rgt74 {
  margin-right: 4.625rem;
}

.mgn-btm74 {
  margin-bottom: 4.625rem;
}

.mgn-lft74 {
  margin-left: 4.625rem;
}

.mgn-75 {
  margin: 4.6875rem;
}

.mgn-top75 {
  margin-top: 4.6875rem;
}

.mgn-rgt75 {
  margin-right: 4.6875rem;
}

.mgn-btm75 {
  margin-bottom: 4.6875rem;
}

.mgn-lft75 {
  margin-left: 4.6875rem;
}

.mgn-76 {
  margin: 4.75rem;
}

.mgn-top76 {
  margin-top: 4.75rem;
}

.mgn-rgt76 {
  margin-right: 4.75rem;
}

.mgn-btm76 {
  margin-bottom: 4.75rem;
}

.mgn-lft76 {
  margin-left: 4.75rem;
}

.mgn-77 {
  margin: 4.8125rem;
}

.mgn-top77 {
  margin-top: 4.8125rem;
}

.mgn-rgt77 {
  margin-right: 4.8125rem;
}

.mgn-btm77 {
  margin-bottom: 4.8125rem;
}

.mgn-lft77 {
  margin-left: 4.8125rem;
}

.mgn-78 {
  margin: 4.875rem;
}

.mgn-top78 {
  margin-top: 4.875rem;
}

.mgn-rgt78 {
  margin-right: 4.875rem;
}

.mgn-btm78 {
  margin-bottom: 4.875rem;
}

.mgn-lft78 {
  margin-left: 4.875rem;
}

.mgn-79 {
  margin: 4.9375rem;
}

.mgn-top79 {
  margin-top: 4.9375rem;
}

.mgn-rgt79 {
  margin-right: 4.9375rem;
}

.mgn-btm79 {
  margin-bottom: 4.9375rem;
}

.mgn-lft79 {
  margin-left: 4.9375rem;
}

.mgn-80 {
  margin: 5rem;
}

.mgn-top80 {
  margin-top: 5rem;
}

.mgn-rgt80 {
  margin-right: 5rem;
}

.mgn-btm80 {
  margin-bottom: 5rem;
}

.mgn-lft80 {
  margin-left: 5rem;
}

.mgn-81 {
  margin: 5.0625rem;
}

.mgn-top81 {
  margin-top: 5.0625rem;
}

.mgn-rgt81 {
  margin-right: 5.0625rem;
}

.mgn-btm81 {
  margin-bottom: 5.0625rem;
}

.mgn-lft81 {
  margin-left: 5.0625rem;
}

.mgn-82 {
  margin: 5.125rem;
}

.mgn-top82 {
  margin-top: 5.125rem;
}

.mgn-rgt82 {
  margin-right: 5.125rem;
}

.mgn-btm82 {
  margin-bottom: 5.125rem;
}

.mgn-lft82 {
  margin-left: 5.125rem;
}

.mgn-83 {
  margin: 5.1875rem;
}

.mgn-top83 {
  margin-top: 5.1875rem;
}

.mgn-rgt83 {
  margin-right: 5.1875rem;
}

.mgn-btm83 {
  margin-bottom: 5.1875rem;
}

.mgn-lft83 {
  margin-left: 5.1875rem;
}

.mgn-84 {
  margin: 5.25rem;
}

.mgn-top84 {
  margin-top: 5.25rem;
}

.mgn-rgt84 {
  margin-right: 5.25rem;
}

.mgn-btm84 {
  margin-bottom: 5.25rem;
}

.mgn-lft84 {
  margin-left: 5.25rem;
}

.mgn-85 {
  margin: 5.3125rem;
}

.mgn-top85 {
  margin-top: 5.3125rem;
}

.mgn-rgt85 {
  margin-right: 5.3125rem;
}

.mgn-btm85 {
  margin-bottom: 5.3125rem;
}

.mgn-lft85 {
  margin-left: 5.3125rem;
}

.mgn-86 {
  margin: 5.375rem;
}

.mgn-top86 {
  margin-top: 5.375rem;
}

.mgn-rgt86 {
  margin-right: 5.375rem;
}

.mgn-btm86 {
  margin-bottom: 5.375rem;
}

.mgn-lft86 {
  margin-left: 5.375rem;
}

.mgn-87 {
  margin: 5.4375rem;
}

.mgn-top87 {
  margin-top: 5.4375rem;
}

.mgn-rgt87 {
  margin-right: 5.4375rem;
}

.mgn-btm87 {
  margin-bottom: 5.4375rem;
}

.mgn-lft87 {
  margin-left: 5.4375rem;
}

.mgn-88 {
  margin: 5.5rem;
}

.mgn-top88 {
  margin-top: 5.5rem;
}

.mgn-rgt88 {
  margin-right: 5.5rem;
}

.mgn-btm88 {
  margin-bottom: 5.5rem;
}

.mgn-lft88 {
  margin-left: 5.5rem;
}

.mgn-89 {
  margin: 5.5625rem;
}

.mgn-top89 {
  margin-top: 5.5625rem;
}

.mgn-rgt89 {
  margin-right: 5.5625rem;
}

.mgn-btm89 {
  margin-bottom: 5.5625rem;
}

.mgn-lft89 {
  margin-left: 5.5625rem;
}

.mgn-90 {
  margin: 5.625rem;
}

.mgn-top90 {
  margin-top: 5.625rem;
}

.mgn-rgt90 {
  margin-right: 5.625rem;
}

.mgn-btm90 {
  margin-bottom: 5.625rem;
}

.mgn-lft90 {
  margin-left: 5.625rem;
}

.mgn-91 {
  margin: 5.6875rem;
}

.mgn-top91 {
  margin-top: 5.6875rem;
}

.mgn-rgt91 {
  margin-right: 5.6875rem;
}

.mgn-btm91 {
  margin-bottom: 5.6875rem;
}

.mgn-lft91 {
  margin-left: 5.6875rem;
}

.mgn-92 {
  margin: 5.75rem;
}

.mgn-top92 {
  margin-top: 5.75rem;
}

.mgn-rgt92 {
  margin-right: 5.75rem;
}

.mgn-btm92 {
  margin-bottom: 5.75rem;
}

.mgn-lft92 {
  margin-left: 5.75rem;
}

.mgn-93 {
  margin: 5.8125rem;
}

.mgn-top93 {
  margin-top: 5.8125rem;
}

.mgn-rgt93 {
  margin-right: 5.8125rem;
}

.mgn-btm93 {
  margin-bottom: 5.8125rem;
}

.mgn-lft93 {
  margin-left: 5.8125rem;
}

.mgn-94 {
  margin: 5.875rem;
}

.mgn-top94 {
  margin-top: 5.875rem;
}

.mgn-rgt94 {
  margin-right: 5.875rem;
}

.mgn-btm94 {
  margin-bottom: 5.875rem;
}

.mgn-lft94 {
  margin-left: 5.875rem;
}

.mgn-95 {
  margin: 5.9375rem;
}

.mgn-top95 {
  margin-top: 5.9375rem;
}

.mgn-rgt95 {
  margin-right: 5.9375rem;
}

.mgn-btm95 {
  margin-bottom: 5.9375rem;
}

.mgn-lft95 {
  margin-left: 5.9375rem;
}

.mgn-96 {
  margin: 6rem;
}

.mgn-top96 {
  margin-top: 6rem;
}

.mgn-rgt96 {
  margin-right: 6rem;
}

.mgn-btm96 {
  margin-bottom: 6rem;
}

.mgn-lft96 {
  margin-left: 6rem;
}

.mgn-97 {
  margin: 6.0625rem;
}

.mgn-top97 {
  margin-top: 6.0625rem;
}

.mgn-rgt97 {
  margin-right: 6.0625rem;
}

.mgn-btm97 {
  margin-bottom: 6.0625rem;
}

.mgn-lft97 {
  margin-left: 6.0625rem;
}

.mgn-98 {
  margin: 6.125rem;
}

.mgn-top98 {
  margin-top: 6.125rem;
}

.mgn-rgt98 {
  margin-right: 6.125rem;
}

.mgn-btm98 {
  margin-bottom: 6.125rem;
}

.mgn-lft98 {
  margin-left: 6.125rem;
}

.mgn-99 {
  margin: 6.1875rem;
}

.mgn-top99 {
  margin-top: 6.1875rem;
}

.mgn-rgt99 {
  margin-right: 6.1875rem;
}

.mgn-btm99 {
  margin-bottom: 6.1875rem;
}

.mgn-lft99 {
  margin-left: 6.1875rem;
}

.mgn-100 {
  margin: 6.25rem;
}

.mgn-top100 {
  margin-top: 6.25rem;
}

.mgn-rgt100 {
  margin-right: 6.25rem;
}

.mgn-btm100 {
  margin-bottom: 6.25rem;
}

.mgn-lft100 {
  margin-left: 6.25rem;
}

.mgn-101 {
  margin: 6.3125rem;
}

.mgn-top101 {
  margin-top: 6.3125rem;
}

.mgn-rgt101 {
  margin-right: 6.3125rem;
}

.mgn-btm101 {
  margin-bottom: 6.3125rem;
}

.mgn-lft101 {
  margin-left: 6.3125rem;
}

.mgn-102 {
  margin: 6.375rem;
}

.mgn-top102 {
  margin-top: 6.375rem;
}

.mgn-rgt102 {
  margin-right: 6.375rem;
}

.mgn-btm102 {
  margin-bottom: 6.375rem;
}

.mgn-lft102 {
  margin-left: 6.375rem;
}

.mgn-103 {
  margin: 6.4375rem;
}

.mgn-top103 {
  margin-top: 6.4375rem;
}

.mgn-rgt103 {
  margin-right: 6.4375rem;
}

.mgn-btm103 {
  margin-bottom: 6.4375rem;
}

.mgn-lft103 {
  margin-left: 6.4375rem;
}

.mgn-104 {
  margin: 6.5rem;
}

.mgn-top104 {
  margin-top: 6.5rem;
}

.mgn-rgt104 {
  margin-right: 6.5rem;
}

.mgn-btm104 {
  margin-bottom: 6.5rem;
}

.mgn-lft104 {
  margin-left: 6.5rem;
}

.mgn-105 {
  margin: 6.5625rem;
}

.mgn-top105 {
  margin-top: 6.5625rem;
}

.mgn-rgt105 {
  margin-right: 6.5625rem;
}

.mgn-btm105 {
  margin-bottom: 6.5625rem;
}

.mgn-lft105 {
  margin-left: 6.5625rem;
}

.mgn-106 {
  margin: 6.625rem;
}

.mgn-top106 {
  margin-top: 6.625rem;
}

.mgn-rgt106 {
  margin-right: 6.625rem;
}

.mgn-btm106 {
  margin-bottom: 6.625rem;
}

.mgn-lft106 {
  margin-left: 6.625rem;
}

.mgn-107 {
  margin: 6.6875rem;
}

.mgn-top107 {
  margin-top: 6.6875rem;
}

.mgn-rgt107 {
  margin-right: 6.6875rem;
}

.mgn-btm107 {
  margin-bottom: 6.6875rem;
}

.mgn-lft107 {
  margin-left: 6.6875rem;
}

.mgn-108 {
  margin: 6.75rem;
}

.mgn-top108 {
  margin-top: 6.75rem;
}

.mgn-rgt108 {
  margin-right: 6.75rem;
}

.mgn-btm108 {
  margin-bottom: 6.75rem;
}

.mgn-lft108 {
  margin-left: 6.75rem;
}

.mgn-109 {
  margin: 6.8125rem;
}

.mgn-top109 {
  margin-top: 6.8125rem;
}

.mgn-rgt109 {
  margin-right: 6.8125rem;
}

.mgn-btm109 {
  margin-bottom: 6.8125rem;
}

.mgn-lft109 {
  margin-left: 6.8125rem;
}

.mgn-110 {
  margin: 6.875rem;
}

.mgn-top110 {
  margin-top: 6.875rem;
}

.mgn-rgt110 {
  margin-right: 6.875rem;
}

.mgn-btm110 {
  margin-bottom: 6.875rem;
}

.mgn-lft110 {
  margin-left: 6.875rem;
}

.mgn-111 {
  margin: 6.9375rem;
}

.mgn-top111 {
  margin-top: 6.9375rem;
}

.mgn-rgt111 {
  margin-right: 6.9375rem;
}

.mgn-btm111 {
  margin-bottom: 6.9375rem;
}

.mgn-lft111 {
  margin-left: 6.9375rem;
}

.mgn-112 {
  margin: 7rem;
}

.mgn-top112 {
  margin-top: 7rem;
}

.mgn-rgt112 {
  margin-right: 7rem;
}

.mgn-btm112 {
  margin-bottom: 7rem;
}

.mgn-lft112 {
  margin-left: 7rem;
}

.mgn-113 {
  margin: 7.0625rem;
}

.mgn-top113 {
  margin-top: 7.0625rem;
}

.mgn-rgt113 {
  margin-right: 7.0625rem;
}

.mgn-btm113 {
  margin-bottom: 7.0625rem;
}

.mgn-lft113 {
  margin-left: 7.0625rem;
}

.mgn-114 {
  margin: 7.125rem;
}

.mgn-top114 {
  margin-top: 7.125rem;
}

.mgn-rgt114 {
  margin-right: 7.125rem;
}

.mgn-btm114 {
  margin-bottom: 7.125rem;
}

.mgn-lft114 {
  margin-left: 7.125rem;
}

.mgn-115 {
  margin: 7.1875rem;
}

.mgn-top115 {
  margin-top: 7.1875rem;
}

.mgn-rgt115 {
  margin-right: 7.1875rem;
}

.mgn-btm115 {
  margin-bottom: 7.1875rem;
}

.mgn-lft115 {
  margin-left: 7.1875rem;
}

.mgn-116 {
  margin: 7.25rem;
}

.mgn-top116 {
  margin-top: 7.25rem;
}

.mgn-rgt116 {
  margin-right: 7.25rem;
}

.mgn-btm116 {
  margin-bottom: 7.25rem;
}

.mgn-lft116 {
  margin-left: 7.25rem;
}

.mgn-117 {
  margin: 7.3125rem;
}

.mgn-top117 {
  margin-top: 7.3125rem;
}

.mgn-rgt117 {
  margin-right: 7.3125rem;
}

.mgn-btm117 {
  margin-bottom: 7.3125rem;
}

.mgn-lft117 {
  margin-left: 7.3125rem;
}

.mgn-118 {
  margin: 7.375rem;
}

.mgn-top118 {
  margin-top: 7.375rem;
}

.mgn-rgt118 {
  margin-right: 7.375rem;
}

.mgn-btm118 {
  margin-bottom: 7.375rem;
}

.mgn-lft118 {
  margin-left: 7.375rem;
}

.mgn-119 {
  margin: 7.4375rem;
}

.mgn-top119 {
  margin-top: 7.4375rem;
}

.mgn-rgt119 {
  margin-right: 7.4375rem;
}

.mgn-btm119 {
  margin-bottom: 7.4375rem;
}

.mgn-lft119 {
  margin-left: 7.4375rem;
}

.mgn-120 {
  margin: 7.5rem;
}

.mgn-top120 {
  margin-top: 7.5rem;
}

.mgn-rgt120 {
  margin-right: 7.5rem;
}

.mgn-btm120 {
  margin-bottom: 7.5rem;
}

.mgn-lft120 {
  margin-left: 7.5rem;
}

.mgn-121 {
  margin: 7.5625rem;
}

.mgn-top121 {
  margin-top: 7.5625rem;
}

.mgn-rgt121 {
  margin-right: 7.5625rem;
}

.mgn-btm121 {
  margin-bottom: 7.5625rem;
}

.mgn-lft121 {
  margin-left: 7.5625rem;
}

.mgn-122 {
  margin: 7.625rem;
}

.mgn-top122 {
  margin-top: 7.625rem;
}

.mgn-rgt122 {
  margin-right: 7.625rem;
}

.mgn-btm122 {
  margin-bottom: 7.625rem;
}

.mgn-lft122 {
  margin-left: 7.625rem;
}

.mgn-123 {
  margin: 7.6875rem;
}

.mgn-top123 {
  margin-top: 7.6875rem;
}

.mgn-rgt123 {
  margin-right: 7.6875rem;
}

.mgn-btm123 {
  margin-bottom: 7.6875rem;
}

.mgn-lft123 {
  margin-left: 7.6875rem;
}

.mgn-124 {
  margin: 7.75rem;
}

.mgn-top124 {
  margin-top: 7.75rem;
}

.mgn-rgt124 {
  margin-right: 7.75rem;
}

.mgn-btm124 {
  margin-bottom: 7.75rem;
}

.mgn-lft124 {
  margin-left: 7.75rem;
}

.mgn-125 {
  margin: 7.8125rem;
}

.mgn-top125 {
  margin-top: 7.8125rem;
}

.mgn-rgt125 {
  margin-right: 7.8125rem;
}

.mgn-btm125 {
  margin-bottom: 7.8125rem;
}

.mgn-lft125 {
  margin-left: 7.8125rem;
}

.mgn-126 {
  margin: 7.875rem;
}

.mgn-top126 {
  margin-top: 7.875rem;
}

.mgn-rgt126 {
  margin-right: 7.875rem;
}

.mgn-btm126 {
  margin-bottom: 7.875rem;
}

.mgn-lft126 {
  margin-left: 7.875rem;
}

.mgn-127 {
  margin: 7.9375rem;
}

.mgn-top127 {
  margin-top: 7.9375rem;
}

.mgn-rgt127 {
  margin-right: 7.9375rem;
}

.mgn-btm127 {
  margin-bottom: 7.9375rem;
}

.mgn-lft127 {
  margin-left: 7.9375rem;
}

.mgn-128 {
  margin: 8rem;
}

.mgn-top128 {
  margin-top: 8rem;
}

.mgn-rgt128 {
  margin-right: 8rem;
}

.mgn-btm128 {
  margin-bottom: 8rem;
}

.mgn-lft128 {
  margin-left: 8rem;
}

.mgn-129 {
  margin: 8.0625rem;
}

.mgn-top129 {
  margin-top: 8.0625rem;
}

.mgn-rgt129 {
  margin-right: 8.0625rem;
}

.mgn-btm129 {
  margin-bottom: 8.0625rem;
}

.mgn-lft129 {
  margin-left: 8.0625rem;
}

.mgn-130 {
  margin: 8.125rem;
}

.mgn-top130 {
  margin-top: 8.125rem;
}

.mgn-rgt130 {
  margin-right: 8.125rem;
}

.mgn-btm130 {
  margin-bottom: 8.125rem;
}

.mgn-lft130 {
  margin-left: 8.125rem;
}

.mgn-131 {
  margin: 8.1875rem;
}

.mgn-top131 {
  margin-top: 8.1875rem;
}

.mgn-rgt131 {
  margin-right: 8.1875rem;
}

.mgn-btm131 {
  margin-bottom: 8.1875rem;
}

.mgn-lft131 {
  margin-left: 8.1875rem;
}

.mgn-132 {
  margin: 8.25rem;
}

.mgn-top132 {
  margin-top: 8.25rem;
}

.mgn-rgt132 {
  margin-right: 8.25rem;
}

.mgn-btm132 {
  margin-bottom: 8.25rem;
}

.mgn-lft132 {
  margin-left: 8.25rem;
}

.mgn-133 {
  margin: 8.3125rem;
}

.mgn-top133 {
  margin-top: 8.3125rem;
}

.mgn-rgt133 {
  margin-right: 8.3125rem;
}

.mgn-btm133 {
  margin-bottom: 8.3125rem;
}

.mgn-lft133 {
  margin-left: 8.3125rem;
}

.mgn-134 {
  margin: 8.375rem;
}

.mgn-top134 {
  margin-top: 8.375rem;
}

.mgn-rgt134 {
  margin-right: 8.375rem;
}

.mgn-btm134 {
  margin-bottom: 8.375rem;
}

.mgn-lft134 {
  margin-left: 8.375rem;
}

.mgn-135 {
  margin: 8.4375rem;
}

.mgn-top135 {
  margin-top: 8.4375rem;
}

.mgn-rgt135 {
  margin-right: 8.4375rem;
}

.mgn-btm135 {
  margin-bottom: 8.4375rem;
}

.mgn-lft135 {
  margin-left: 8.4375rem;
}

.mgn-136 {
  margin: 8.5rem;
}

.mgn-top136 {
  margin-top: 8.5rem;
}

.mgn-rgt136 {
  margin-right: 8.5rem;
}

.mgn-btm136 {
  margin-bottom: 8.5rem;
}

.mgn-lft136 {
  margin-left: 8.5rem;
}

.mgn-137 {
  margin: 8.5625rem;
}

.mgn-top137 {
  margin-top: 8.5625rem;
}

.mgn-rgt137 {
  margin-right: 8.5625rem;
}

.mgn-btm137 {
  margin-bottom: 8.5625rem;
}

.mgn-lft137 {
  margin-left: 8.5625rem;
}

.mgn-138 {
  margin: 8.625rem;
}

.mgn-top138 {
  margin-top: 8.625rem;
}

.mgn-rgt138 {
  margin-right: 8.625rem;
}

.mgn-btm138 {
  margin-bottom: 8.625rem;
}

.mgn-lft138 {
  margin-left: 8.625rem;
}

.mgn-139 {
  margin: 8.6875rem;
}

.mgn-top139 {
  margin-top: 8.6875rem;
}

.mgn-rgt139 {
  margin-right: 8.6875rem;
}

.mgn-btm139 {
  margin-bottom: 8.6875rem;
}

.mgn-lft139 {
  margin-left: 8.6875rem;
}

.mgn-140 {
  margin: 8.75rem;
}

.mgn-top140 {
  margin-top: 8.75rem;
}

.mgn-rgt140 {
  margin-right: 8.75rem;
}

.mgn-btm140 {
  margin-bottom: 8.75rem;
}

.mgn-lft140 {
  margin-left: 8.75rem;
}

.mgn-141 {
  margin: 8.8125rem;
}

.mgn-top141 {
  margin-top: 8.8125rem;
}

.mgn-rgt141 {
  margin-right: 8.8125rem;
}

.mgn-btm141 {
  margin-bottom: 8.8125rem;
}

.mgn-lft141 {
  margin-left: 8.8125rem;
}

.mgn-142 {
  margin: 8.875rem;
}

.mgn-top142 {
  margin-top: 8.875rem;
}

.mgn-rgt142 {
  margin-right: 8.875rem;
}

.mgn-btm142 {
  margin-bottom: 8.875rem;
}

.mgn-lft142 {
  margin-left: 8.875rem;
}

.mgn-143 {
  margin: 8.9375rem;
}

.mgn-top143 {
  margin-top: 8.9375rem;
}

.mgn-rgt143 {
  margin-right: 8.9375rem;
}

.mgn-btm143 {
  margin-bottom: 8.9375rem;
}

.mgn-lft143 {
  margin-left: 8.9375rem;
}

.mgn-144 {
  margin: 9rem;
}

.mgn-top144 {
  margin-top: 9rem;
}

.mgn-rgt144 {
  margin-right: 9rem;
}

.mgn-btm144 {
  margin-bottom: 9rem;
}

.mgn-lft144 {
  margin-left: 9rem;
}

.mgn-145 {
  margin: 9.0625rem;
}

.mgn-top145 {
  margin-top: 9.0625rem;
}

.mgn-rgt145 {
  margin-right: 9.0625rem;
}

.mgn-btm145 {
  margin-bottom: 9.0625rem;
}

.mgn-lft145 {
  margin-left: 9.0625rem;
}

.mgn-146 {
  margin: 9.125rem;
}

.mgn-top146 {
  margin-top: 9.125rem;
}

.mgn-rgt146 {
  margin-right: 9.125rem;
}

.mgn-btm146 {
  margin-bottom: 9.125rem;
}

.mgn-lft146 {
  margin-left: 9.125rem;
}

.mgn-147 {
  margin: 9.1875rem;
}

.mgn-top147 {
  margin-top: 9.1875rem;
}

.mgn-rgt147 {
  margin-right: 9.1875rem;
}

.mgn-btm147 {
  margin-bottom: 9.1875rem;
}

.mgn-lft147 {
  margin-left: 9.1875rem;
}

.mgn-148 {
  margin: 9.25rem;
}

.mgn-top148 {
  margin-top: 9.25rem;
}

.mgn-rgt148 {
  margin-right: 9.25rem;
}

.mgn-btm148 {
  margin-bottom: 9.25rem;
}

.mgn-lft148 {
  margin-left: 9.25rem;
}

.mgn-149 {
  margin: 9.3125rem;
}

.mgn-top149 {
  margin-top: 9.3125rem;
}

.mgn-rgt149 {
  margin-right: 9.3125rem;
}

.mgn-btm149 {
  margin-bottom: 9.3125rem;
}

.mgn-lft149 {
  margin-left: 9.3125rem;
}

.mgn-150 {
  margin: 9.375rem;
}

.mgn-top150 {
  margin-top: 9.375rem;
}

.mgn-rgt150 {
  margin-right: 9.375rem;
}

.mgn-btm150 {
  margin-bottom: 9.375rem;
}

.mgn-lft150 {
  margin-left: 9.375rem;
}

@media screen and (max-width: 47.9375em) {
  .mgn-0 {
    margin: 0rem;
  }
  .mgn-top0 {
    margin-top: 0rem;
  }
  .mgn-rgt0 {
    margin-right: 0rem;
  }
  .mgn-btm0 {
    margin-bottom: 0rem;
  }
  .mgn-lft0 {
    margin-left: 0rem;
  }
  .mgn-1 {
    margin: 0.0357142857rem;
  }
  .mgn-top1 {
    margin-top: 0.0357142857rem;
  }
  .mgn-rgt1 {
    margin-right: 0.0357142857rem;
  }
  .mgn-btm1 {
    margin-bottom: 0.0357142857rem;
  }
  .mgn-lft1 {
    margin-left: 0.0357142857rem;
  }
  .mgn-2 {
    margin: 0.0714285714rem;
  }
  .mgn-top2 {
    margin-top: 0.0714285714rem;
  }
  .mgn-rgt2 {
    margin-right: 0.0714285714rem;
  }
  .mgn-btm2 {
    margin-bottom: 0.0714285714rem;
  }
  .mgn-lft2 {
    margin-left: 0.0714285714rem;
  }
  .mgn-3 {
    margin: 0.1071428571rem;
  }
  .mgn-top3 {
    margin-top: 0.1071428571rem;
  }
  .mgn-rgt3 {
    margin-right: 0.1071428571rem;
  }
  .mgn-btm3 {
    margin-bottom: 0.1071428571rem;
  }
  .mgn-lft3 {
    margin-left: 0.1071428571rem;
  }
  .mgn-4 {
    margin: 0.1428571429rem;
  }
  .mgn-top4 {
    margin-top: 0.1428571429rem;
  }
  .mgn-rgt4 {
    margin-right: 0.1428571429rem;
  }
  .mgn-btm4 {
    margin-bottom: 0.1428571429rem;
  }
  .mgn-lft4 {
    margin-left: 0.1428571429rem;
  }
  .mgn-5 {
    margin: 0.1785714286rem;
  }
  .mgn-top5 {
    margin-top: 0.1785714286rem;
  }
  .mgn-rgt5 {
    margin-right: 0.1785714286rem;
  }
  .mgn-btm5 {
    margin-bottom: 0.1785714286rem;
  }
  .mgn-lft5 {
    margin-left: 0.1785714286rem;
  }
  .mgn-6 {
    margin: 0.2142857143rem;
  }
  .mgn-top6 {
    margin-top: 0.2142857143rem;
  }
  .mgn-rgt6 {
    margin-right: 0.2142857143rem;
  }
  .mgn-btm6 {
    margin-bottom: 0.2142857143rem;
  }
  .mgn-lft6 {
    margin-left: 0.2142857143rem;
  }
  .mgn-7 {
    margin: 0.25rem;
  }
  .mgn-top7 {
    margin-top: 0.25rem;
  }
  .mgn-rgt7 {
    margin-right: 0.25rem;
  }
  .mgn-btm7 {
    margin-bottom: 0.25rem;
  }
  .mgn-lft7 {
    margin-left: 0.25rem;
  }
  .mgn-8 {
    margin: 0.2857142857rem;
  }
  .mgn-top8 {
    margin-top: 0.2857142857rem;
  }
  .mgn-rgt8 {
    margin-right: 0.2857142857rem;
  }
  .mgn-btm8 {
    margin-bottom: 0.2857142857rem;
  }
  .mgn-lft8 {
    margin-left: 0.2857142857rem;
  }
  .mgn-9 {
    margin: 0.3214285714rem;
  }
  .mgn-top9 {
    margin-top: 0.3214285714rem;
  }
  .mgn-rgt9 {
    margin-right: 0.3214285714rem;
  }
  .mgn-btm9 {
    margin-bottom: 0.3214285714rem;
  }
  .mgn-lft9 {
    margin-left: 0.3214285714rem;
  }
  .mgn-10 {
    margin: 0.3571428571rem;
  }
  .mgn-top10 {
    margin-top: 0.3571428571rem;
  }
  .mgn-rgt10 {
    margin-right: 0.3571428571rem;
  }
  .mgn-btm10 {
    margin-bottom: 0.3571428571rem;
  }
  .mgn-lft10 {
    margin-left: 0.3571428571rem;
  }
  .mgn-11 {
    margin: 0.3928571429rem;
  }
  .mgn-top11 {
    margin-top: 0.3928571429rem;
  }
  .mgn-rgt11 {
    margin-right: 0.3928571429rem;
  }
  .mgn-btm11 {
    margin-bottom: 0.3928571429rem;
  }
  .mgn-lft11 {
    margin-left: 0.3928571429rem;
  }
  .mgn-12 {
    margin: 0.4285714286rem;
  }
  .mgn-top12 {
    margin-top: 0.4285714286rem;
  }
  .mgn-rgt12 {
    margin-right: 0.4285714286rem;
  }
  .mgn-btm12 {
    margin-bottom: 0.4285714286rem;
  }
  .mgn-lft12 {
    margin-left: 0.4285714286rem;
  }
  .mgn-13 {
    margin: 0.4642857143rem;
  }
  .mgn-top13 {
    margin-top: 0.4642857143rem;
  }
  .mgn-rgt13 {
    margin-right: 0.4642857143rem;
  }
  .mgn-btm13 {
    margin-bottom: 0.4642857143rem;
  }
  .mgn-lft13 {
    margin-left: 0.4642857143rem;
  }
  .mgn-14 {
    margin: 0.5rem;
  }
  .mgn-top14 {
    margin-top: 0.5rem;
  }
  .mgn-rgt14 {
    margin-right: 0.5rem;
  }
  .mgn-btm14 {
    margin-bottom: 0.5rem;
  }
  .mgn-lft14 {
    margin-left: 0.5rem;
  }
  .mgn-15 {
    margin: 0.5357142857rem;
  }
  .mgn-top15 {
    margin-top: 0.5357142857rem;
  }
  .mgn-rgt15 {
    margin-right: 0.5357142857rem;
  }
  .mgn-btm15 {
    margin-bottom: 0.5357142857rem;
  }
  .mgn-lft15 {
    margin-left: 0.5357142857rem;
  }
  .mgn-16 {
    margin: 0.5714285714rem;
  }
  .mgn-top16 {
    margin-top: 0.5714285714rem;
  }
  .mgn-rgt16 {
    margin-right: 0.5714285714rem;
  }
  .mgn-btm16 {
    margin-bottom: 0.5714285714rem;
  }
  .mgn-lft16 {
    margin-left: 0.5714285714rem;
  }
  .mgn-17 {
    margin: 0.6071428571rem;
  }
  .mgn-top17 {
    margin-top: 0.6071428571rem;
  }
  .mgn-rgt17 {
    margin-right: 0.6071428571rem;
  }
  .mgn-btm17 {
    margin-bottom: 0.6071428571rem;
  }
  .mgn-lft17 {
    margin-left: 0.6071428571rem;
  }
  .mgn-18 {
    margin: 0.6428571429rem;
  }
  .mgn-top18 {
    margin-top: 0.6428571429rem;
  }
  .mgn-rgt18 {
    margin-right: 0.6428571429rem;
  }
  .mgn-btm18 {
    margin-bottom: 0.6428571429rem;
  }
  .mgn-lft18 {
    margin-left: 0.6428571429rem;
  }
  .mgn-19 {
    margin: 0.6785714286rem;
  }
  .mgn-top19 {
    margin-top: 0.6785714286rem;
  }
  .mgn-rgt19 {
    margin-right: 0.6785714286rem;
  }
  .mgn-btm19 {
    margin-bottom: 0.6785714286rem;
  }
  .mgn-lft19 {
    margin-left: 0.6785714286rem;
  }
  .mgn-20 {
    margin: 0.7142857143rem;
  }
  .mgn-top20 {
    margin-top: 0.7142857143rem;
  }
  .mgn-rgt20 {
    margin-right: 0.7142857143rem;
  }
  .mgn-btm20 {
    margin-bottom: 0.7142857143rem;
  }
  .mgn-lft20 {
    margin-left: 0.7142857143rem;
  }
  .mgn-21 {
    margin: 0.75rem;
  }
  .mgn-top21 {
    margin-top: 0.75rem;
  }
  .mgn-rgt21 {
    margin-right: 0.75rem;
  }
  .mgn-btm21 {
    margin-bottom: 0.75rem;
  }
  .mgn-lft21 {
    margin-left: 0.75rem;
  }
  .mgn-22 {
    margin: 0.7857142857rem;
  }
  .mgn-top22 {
    margin-top: 0.7857142857rem;
  }
  .mgn-rgt22 {
    margin-right: 0.7857142857rem;
  }
  .mgn-btm22 {
    margin-bottom: 0.7857142857rem;
  }
  .mgn-lft22 {
    margin-left: 0.7857142857rem;
  }
  .mgn-23 {
    margin: 0.8214285714rem;
  }
  .mgn-top23 {
    margin-top: 0.8214285714rem;
  }
  .mgn-rgt23 {
    margin-right: 0.8214285714rem;
  }
  .mgn-btm23 {
    margin-bottom: 0.8214285714rem;
  }
  .mgn-lft23 {
    margin-left: 0.8214285714rem;
  }
  .mgn-24 {
    margin: 0.8571428571rem;
  }
  .mgn-top24 {
    margin-top: 0.8571428571rem;
  }
  .mgn-rgt24 {
    margin-right: 0.8571428571rem;
  }
  .mgn-btm24 {
    margin-bottom: 0.8571428571rem;
  }
  .mgn-lft24 {
    margin-left: 0.8571428571rem;
  }
  .mgn-25 {
    margin: 0.8928571429rem;
  }
  .mgn-top25 {
    margin-top: 0.8928571429rem;
  }
  .mgn-rgt25 {
    margin-right: 0.8928571429rem;
  }
  .mgn-btm25 {
    margin-bottom: 0.8928571429rem;
  }
  .mgn-lft25 {
    margin-left: 0.8928571429rem;
  }
  .mgn-26 {
    margin: 0.9285714286rem;
  }
  .mgn-top26 {
    margin-top: 0.9285714286rem;
  }
  .mgn-rgt26 {
    margin-right: 0.9285714286rem;
  }
  .mgn-btm26 {
    margin-bottom: 0.9285714286rem;
  }
  .mgn-lft26 {
    margin-left: 0.9285714286rem;
  }
  .mgn-27 {
    margin: 0.9642857143rem;
  }
  .mgn-top27 {
    margin-top: 0.9642857143rem;
  }
  .mgn-rgt27 {
    margin-right: 0.9642857143rem;
  }
  .mgn-btm27 {
    margin-bottom: 0.9642857143rem;
  }
  .mgn-lft27 {
    margin-left: 0.9642857143rem;
  }
  .mgn-28 {
    margin: 1rem;
  }
  .mgn-top28 {
    margin-top: 1rem;
  }
  .mgn-rgt28 {
    margin-right: 1rem;
  }
  .mgn-btm28 {
    margin-bottom: 1rem;
  }
  .mgn-lft28 {
    margin-left: 1rem;
  }
  .mgn-29 {
    margin: 1.0357142857rem;
  }
  .mgn-top29 {
    margin-top: 1.0357142857rem;
  }
  .mgn-rgt29 {
    margin-right: 1.0357142857rem;
  }
  .mgn-btm29 {
    margin-bottom: 1.0357142857rem;
  }
  .mgn-lft29 {
    margin-left: 1.0357142857rem;
  }
  .mgn-30 {
    margin: 1.0714285714rem;
  }
  .mgn-top30 {
    margin-top: 1.0714285714rem;
  }
  .mgn-rgt30 {
    margin-right: 1.0714285714rem;
  }
  .mgn-btm30 {
    margin-bottom: 1.0714285714rem;
  }
  .mgn-lft30 {
    margin-left: 1.0714285714rem;
  }
  .mgn-31 {
    margin: 1.1071428571rem;
  }
  .mgn-top31 {
    margin-top: 1.1071428571rem;
  }
  .mgn-rgt31 {
    margin-right: 1.1071428571rem;
  }
  .mgn-btm31 {
    margin-bottom: 1.1071428571rem;
  }
  .mgn-lft31 {
    margin-left: 1.1071428571rem;
  }
  .mgn-32 {
    margin: 1.1428571429rem;
  }
  .mgn-top32 {
    margin-top: 1.1428571429rem;
  }
  .mgn-rgt32 {
    margin-right: 1.1428571429rem;
  }
  .mgn-btm32 {
    margin-bottom: 1.1428571429rem;
  }
  .mgn-lft32 {
    margin-left: 1.1428571429rem;
  }
  .mgn-33 {
    margin: 1.1785714286rem;
  }
  .mgn-top33 {
    margin-top: 1.1785714286rem;
  }
  .mgn-rgt33 {
    margin-right: 1.1785714286rem;
  }
  .mgn-btm33 {
    margin-bottom: 1.1785714286rem;
  }
  .mgn-lft33 {
    margin-left: 1.1785714286rem;
  }
  .mgn-34 {
    margin: 1.2142857143rem;
  }
  .mgn-top34 {
    margin-top: 1.2142857143rem;
  }
  .mgn-rgt34 {
    margin-right: 1.2142857143rem;
  }
  .mgn-btm34 {
    margin-bottom: 1.2142857143rem;
  }
  .mgn-lft34 {
    margin-left: 1.2142857143rem;
  }
  .mgn-35 {
    margin: 1.25rem;
  }
  .mgn-top35 {
    margin-top: 1.25rem;
  }
  .mgn-rgt35 {
    margin-right: 1.25rem;
  }
  .mgn-btm35 {
    margin-bottom: 1.25rem;
  }
  .mgn-lft35 {
    margin-left: 1.25rem;
  }
  .mgn-36 {
    margin: 1.2857142857rem;
  }
  .mgn-top36 {
    margin-top: 1.2857142857rem;
  }
  .mgn-rgt36 {
    margin-right: 1.2857142857rem;
  }
  .mgn-btm36 {
    margin-bottom: 1.2857142857rem;
  }
  .mgn-lft36 {
    margin-left: 1.2857142857rem;
  }
  .mgn-37 {
    margin: 1.3214285714rem;
  }
  .mgn-top37 {
    margin-top: 1.3214285714rem;
  }
  .mgn-rgt37 {
    margin-right: 1.3214285714rem;
  }
  .mgn-btm37 {
    margin-bottom: 1.3214285714rem;
  }
  .mgn-lft37 {
    margin-left: 1.3214285714rem;
  }
  .mgn-38 {
    margin: 1.3571428571rem;
  }
  .mgn-top38 {
    margin-top: 1.3571428571rem;
  }
  .mgn-rgt38 {
    margin-right: 1.3571428571rem;
  }
  .mgn-btm38 {
    margin-bottom: 1.3571428571rem;
  }
  .mgn-lft38 {
    margin-left: 1.3571428571rem;
  }
  .mgn-39 {
    margin: 1.3928571429rem;
  }
  .mgn-top39 {
    margin-top: 1.3928571429rem;
  }
  .mgn-rgt39 {
    margin-right: 1.3928571429rem;
  }
  .mgn-btm39 {
    margin-bottom: 1.3928571429rem;
  }
  .mgn-lft39 {
    margin-left: 1.3928571429rem;
  }
  .mgn-40 {
    margin: 1.4285714286rem;
  }
  .mgn-top40 {
    margin-top: 1.4285714286rem;
  }
  .mgn-rgt40 {
    margin-right: 1.4285714286rem;
  }
  .mgn-btm40 {
    margin-bottom: 1.4285714286rem;
  }
  .mgn-lft40 {
    margin-left: 1.4285714286rem;
  }
  .mgn-41 {
    margin: 1.4642857143rem;
  }
  .mgn-top41 {
    margin-top: 1.4642857143rem;
  }
  .mgn-rgt41 {
    margin-right: 1.4642857143rem;
  }
  .mgn-btm41 {
    margin-bottom: 1.4642857143rem;
  }
  .mgn-lft41 {
    margin-left: 1.4642857143rem;
  }
  .mgn-42 {
    margin: 1.5rem;
  }
  .mgn-top42 {
    margin-top: 1.5rem;
  }
  .mgn-rgt42 {
    margin-right: 1.5rem;
  }
  .mgn-btm42 {
    margin-bottom: 1.5rem;
  }
  .mgn-lft42 {
    margin-left: 1.5rem;
  }
  .mgn-43 {
    margin: 1.5357142857rem;
  }
  .mgn-top43 {
    margin-top: 1.5357142857rem;
  }
  .mgn-rgt43 {
    margin-right: 1.5357142857rem;
  }
  .mgn-btm43 {
    margin-bottom: 1.5357142857rem;
  }
  .mgn-lft43 {
    margin-left: 1.5357142857rem;
  }
  .mgn-44 {
    margin: 1.5714285714rem;
  }
  .mgn-top44 {
    margin-top: 1.5714285714rem;
  }
  .mgn-rgt44 {
    margin-right: 1.5714285714rem;
  }
  .mgn-btm44 {
    margin-bottom: 1.5714285714rem;
  }
  .mgn-lft44 {
    margin-left: 1.5714285714rem;
  }
  .mgn-45 {
    margin: 1.6071428571rem;
  }
  .mgn-top45 {
    margin-top: 1.6071428571rem;
  }
  .mgn-rgt45 {
    margin-right: 1.6071428571rem;
  }
  .mgn-btm45 {
    margin-bottom: 1.6071428571rem;
  }
  .mgn-lft45 {
    margin-left: 1.6071428571rem;
  }
  .mgn-46 {
    margin: 1.6428571429rem;
  }
  .mgn-top46 {
    margin-top: 1.6428571429rem;
  }
  .mgn-rgt46 {
    margin-right: 1.6428571429rem;
  }
  .mgn-btm46 {
    margin-bottom: 1.6428571429rem;
  }
  .mgn-lft46 {
    margin-left: 1.6428571429rem;
  }
  .mgn-47 {
    margin: 1.6785714286rem;
  }
  .mgn-top47 {
    margin-top: 1.6785714286rem;
  }
  .mgn-rgt47 {
    margin-right: 1.6785714286rem;
  }
  .mgn-btm47 {
    margin-bottom: 1.6785714286rem;
  }
  .mgn-lft47 {
    margin-left: 1.6785714286rem;
  }
  .mgn-48 {
    margin: 1.7142857143rem;
  }
  .mgn-top48 {
    margin-top: 1.7142857143rem;
  }
  .mgn-rgt48 {
    margin-right: 1.7142857143rem;
  }
  .mgn-btm48 {
    margin-bottom: 1.7142857143rem;
  }
  .mgn-lft48 {
    margin-left: 1.7142857143rem;
  }
  .mgn-49 {
    margin: 1.75rem;
  }
  .mgn-top49 {
    margin-top: 1.75rem;
  }
  .mgn-rgt49 {
    margin-right: 1.75rem;
  }
  .mgn-btm49 {
    margin-bottom: 1.75rem;
  }
  .mgn-lft49 {
    margin-left: 1.75rem;
  }
  .mgn-50 {
    margin: 1.7857142857rem;
  }
  .mgn-top50 {
    margin-top: 1.7857142857rem;
  }
  .mgn-rgt50 {
    margin-right: 1.7857142857rem;
  }
  .mgn-btm50 {
    margin-bottom: 1.7857142857rem;
  }
  .mgn-lft50 {
    margin-left: 1.7857142857rem;
  }
  .mgn-51 {
    margin: 1.8214285714rem;
  }
  .mgn-top51 {
    margin-top: 1.8214285714rem;
  }
  .mgn-rgt51 {
    margin-right: 1.8214285714rem;
  }
  .mgn-btm51 {
    margin-bottom: 1.8214285714rem;
  }
  .mgn-lft51 {
    margin-left: 1.8214285714rem;
  }
  .mgn-52 {
    margin: 1.8571428571rem;
  }
  .mgn-top52 {
    margin-top: 1.8571428571rem;
  }
  .mgn-rgt52 {
    margin-right: 1.8571428571rem;
  }
  .mgn-btm52 {
    margin-bottom: 1.8571428571rem;
  }
  .mgn-lft52 {
    margin-left: 1.8571428571rem;
  }
  .mgn-53 {
    margin: 1.8928571429rem;
  }
  .mgn-top53 {
    margin-top: 1.8928571429rem;
  }
  .mgn-rgt53 {
    margin-right: 1.8928571429rem;
  }
  .mgn-btm53 {
    margin-bottom: 1.8928571429rem;
  }
  .mgn-lft53 {
    margin-left: 1.8928571429rem;
  }
  .mgn-54 {
    margin: 1.9285714286rem;
  }
  .mgn-top54 {
    margin-top: 1.9285714286rem;
  }
  .mgn-rgt54 {
    margin-right: 1.9285714286rem;
  }
  .mgn-btm54 {
    margin-bottom: 1.9285714286rem;
  }
  .mgn-lft54 {
    margin-left: 1.9285714286rem;
  }
  .mgn-55 {
    margin: 1.9642857143rem;
  }
  .mgn-top55 {
    margin-top: 1.9642857143rem;
  }
  .mgn-rgt55 {
    margin-right: 1.9642857143rem;
  }
  .mgn-btm55 {
    margin-bottom: 1.9642857143rem;
  }
  .mgn-lft55 {
    margin-left: 1.9642857143rem;
  }
  .mgn-56 {
    margin: 2rem;
  }
  .mgn-top56 {
    margin-top: 2rem;
  }
  .mgn-rgt56 {
    margin-right: 2rem;
  }
  .mgn-btm56 {
    margin-bottom: 2rem;
  }
  .mgn-lft56 {
    margin-left: 2rem;
  }
  .mgn-57 {
    margin: 2.0357142857rem;
  }
  .mgn-top57 {
    margin-top: 2.0357142857rem;
  }
  .mgn-rgt57 {
    margin-right: 2.0357142857rem;
  }
  .mgn-btm57 {
    margin-bottom: 2.0357142857rem;
  }
  .mgn-lft57 {
    margin-left: 2.0357142857rem;
  }
  .mgn-58 {
    margin: 2.0714285714rem;
  }
  .mgn-top58 {
    margin-top: 2.0714285714rem;
  }
  .mgn-rgt58 {
    margin-right: 2.0714285714rem;
  }
  .mgn-btm58 {
    margin-bottom: 2.0714285714rem;
  }
  .mgn-lft58 {
    margin-left: 2.0714285714rem;
  }
  .mgn-59 {
    margin: 2.1071428571rem;
  }
  .mgn-top59 {
    margin-top: 2.1071428571rem;
  }
  .mgn-rgt59 {
    margin-right: 2.1071428571rem;
  }
  .mgn-btm59 {
    margin-bottom: 2.1071428571rem;
  }
  .mgn-lft59 {
    margin-left: 2.1071428571rem;
  }
  .mgn-60 {
    margin: 2.1428571429rem;
  }
  .mgn-top60 {
    margin-top: 2.1428571429rem;
  }
  .mgn-rgt60 {
    margin-right: 2.1428571429rem;
  }
  .mgn-btm60 {
    margin-bottom: 2.1428571429rem;
  }
  .mgn-lft60 {
    margin-left: 2.1428571429rem;
  }
  .mgn-61 {
    margin: 2.1785714286rem;
  }
  .mgn-top61 {
    margin-top: 2.1785714286rem;
  }
  .mgn-rgt61 {
    margin-right: 2.1785714286rem;
  }
  .mgn-btm61 {
    margin-bottom: 2.1785714286rem;
  }
  .mgn-lft61 {
    margin-left: 2.1785714286rem;
  }
  .mgn-62 {
    margin: 2.2142857143rem;
  }
  .mgn-top62 {
    margin-top: 2.2142857143rem;
  }
  .mgn-rgt62 {
    margin-right: 2.2142857143rem;
  }
  .mgn-btm62 {
    margin-bottom: 2.2142857143rem;
  }
  .mgn-lft62 {
    margin-left: 2.2142857143rem;
  }
  .mgn-63 {
    margin: 2.25rem;
  }
  .mgn-top63 {
    margin-top: 2.25rem;
  }
  .mgn-rgt63 {
    margin-right: 2.25rem;
  }
  .mgn-btm63 {
    margin-bottom: 2.25rem;
  }
  .mgn-lft63 {
    margin-left: 2.25rem;
  }
  .mgn-64 {
    margin: 2.2857142857rem;
  }
  .mgn-top64 {
    margin-top: 2.2857142857rem;
  }
  .mgn-rgt64 {
    margin-right: 2.2857142857rem;
  }
  .mgn-btm64 {
    margin-bottom: 2.2857142857rem;
  }
  .mgn-lft64 {
    margin-left: 2.2857142857rem;
  }
  .mgn-65 {
    margin: 2.3214285714rem;
  }
  .mgn-top65 {
    margin-top: 2.3214285714rem;
  }
  .mgn-rgt65 {
    margin-right: 2.3214285714rem;
  }
  .mgn-btm65 {
    margin-bottom: 2.3214285714rem;
  }
  .mgn-lft65 {
    margin-left: 2.3214285714rem;
  }
  .mgn-66 {
    margin: 2.3571428571rem;
  }
  .mgn-top66 {
    margin-top: 2.3571428571rem;
  }
  .mgn-rgt66 {
    margin-right: 2.3571428571rem;
  }
  .mgn-btm66 {
    margin-bottom: 2.3571428571rem;
  }
  .mgn-lft66 {
    margin-left: 2.3571428571rem;
  }
  .mgn-67 {
    margin: 2.3928571429rem;
  }
  .mgn-top67 {
    margin-top: 2.3928571429rem;
  }
  .mgn-rgt67 {
    margin-right: 2.3928571429rem;
  }
  .mgn-btm67 {
    margin-bottom: 2.3928571429rem;
  }
  .mgn-lft67 {
    margin-left: 2.3928571429rem;
  }
  .mgn-68 {
    margin: 2.4285714286rem;
  }
  .mgn-top68 {
    margin-top: 2.4285714286rem;
  }
  .mgn-rgt68 {
    margin-right: 2.4285714286rem;
  }
  .mgn-btm68 {
    margin-bottom: 2.4285714286rem;
  }
  .mgn-lft68 {
    margin-left: 2.4285714286rem;
  }
  .mgn-69 {
    margin: 2.4642857143rem;
  }
  .mgn-top69 {
    margin-top: 2.4642857143rem;
  }
  .mgn-rgt69 {
    margin-right: 2.4642857143rem;
  }
  .mgn-btm69 {
    margin-bottom: 2.4642857143rem;
  }
  .mgn-lft69 {
    margin-left: 2.4642857143rem;
  }
  .mgn-70 {
    margin: 2.5rem;
  }
  .mgn-top70 {
    margin-top: 2.5rem;
  }
  .mgn-rgt70 {
    margin-right: 2.5rem;
  }
  .mgn-btm70 {
    margin-bottom: 2.5rem;
  }
  .mgn-lft70 {
    margin-left: 2.5rem;
  }
  .mgn-71 {
    margin: 2.5357142857rem;
  }
  .mgn-top71 {
    margin-top: 2.5357142857rem;
  }
  .mgn-rgt71 {
    margin-right: 2.5357142857rem;
  }
  .mgn-btm71 {
    margin-bottom: 2.5357142857rem;
  }
  .mgn-lft71 {
    margin-left: 2.5357142857rem;
  }
  .mgn-72 {
    margin: 2.5714285714rem;
  }
  .mgn-top72 {
    margin-top: 2.5714285714rem;
  }
  .mgn-rgt72 {
    margin-right: 2.5714285714rem;
  }
  .mgn-btm72 {
    margin-bottom: 2.5714285714rem;
  }
  .mgn-lft72 {
    margin-left: 2.5714285714rem;
  }
  .mgn-73 {
    margin: 2.6071428571rem;
  }
  .mgn-top73 {
    margin-top: 2.6071428571rem;
  }
  .mgn-rgt73 {
    margin-right: 2.6071428571rem;
  }
  .mgn-btm73 {
    margin-bottom: 2.6071428571rem;
  }
  .mgn-lft73 {
    margin-left: 2.6071428571rem;
  }
  .mgn-74 {
    margin: 2.6428571429rem;
  }
  .mgn-top74 {
    margin-top: 2.6428571429rem;
  }
  .mgn-rgt74 {
    margin-right: 2.6428571429rem;
  }
  .mgn-btm74 {
    margin-bottom: 2.6428571429rem;
  }
  .mgn-lft74 {
    margin-left: 2.6428571429rem;
  }
  .mgn-75 {
    margin: 2.6785714286rem;
  }
  .mgn-top75 {
    margin-top: 2.6785714286rem;
  }
  .mgn-rgt75 {
    margin-right: 2.6785714286rem;
  }
  .mgn-btm75 {
    margin-bottom: 2.6785714286rem;
  }
  .mgn-lft75 {
    margin-left: 2.6785714286rem;
  }
  .mgn-76 {
    margin: 2.7142857143rem;
  }
  .mgn-top76 {
    margin-top: 2.7142857143rem;
  }
  .mgn-rgt76 {
    margin-right: 2.7142857143rem;
  }
  .mgn-btm76 {
    margin-bottom: 2.7142857143rem;
  }
  .mgn-lft76 {
    margin-left: 2.7142857143rem;
  }
  .mgn-77 {
    margin: 2.75rem;
  }
  .mgn-top77 {
    margin-top: 2.75rem;
  }
  .mgn-rgt77 {
    margin-right: 2.75rem;
  }
  .mgn-btm77 {
    margin-bottom: 2.75rem;
  }
  .mgn-lft77 {
    margin-left: 2.75rem;
  }
  .mgn-78 {
    margin: 2.7857142857rem;
  }
  .mgn-top78 {
    margin-top: 2.7857142857rem;
  }
  .mgn-rgt78 {
    margin-right: 2.7857142857rem;
  }
  .mgn-btm78 {
    margin-bottom: 2.7857142857rem;
  }
  .mgn-lft78 {
    margin-left: 2.7857142857rem;
  }
  .mgn-79 {
    margin: 2.8214285714rem;
  }
  .mgn-top79 {
    margin-top: 2.8214285714rem;
  }
  .mgn-rgt79 {
    margin-right: 2.8214285714rem;
  }
  .mgn-btm79 {
    margin-bottom: 2.8214285714rem;
  }
  .mgn-lft79 {
    margin-left: 2.8214285714rem;
  }
  .mgn-80 {
    margin: 2.8571428571rem;
  }
  .mgn-top80 {
    margin-top: 2.8571428571rem;
  }
  .mgn-rgt80 {
    margin-right: 2.8571428571rem;
  }
  .mgn-btm80 {
    margin-bottom: 2.8571428571rem;
  }
  .mgn-lft80 {
    margin-left: 2.8571428571rem;
  }
  .mgn-81 {
    margin: 2.8928571429rem;
  }
  .mgn-top81 {
    margin-top: 2.8928571429rem;
  }
  .mgn-rgt81 {
    margin-right: 2.8928571429rem;
  }
  .mgn-btm81 {
    margin-bottom: 2.8928571429rem;
  }
  .mgn-lft81 {
    margin-left: 2.8928571429rem;
  }
  .mgn-82 {
    margin: 2.9285714286rem;
  }
  .mgn-top82 {
    margin-top: 2.9285714286rem;
  }
  .mgn-rgt82 {
    margin-right: 2.9285714286rem;
  }
  .mgn-btm82 {
    margin-bottom: 2.9285714286rem;
  }
  .mgn-lft82 {
    margin-left: 2.9285714286rem;
  }
  .mgn-83 {
    margin: 2.9642857143rem;
  }
  .mgn-top83 {
    margin-top: 2.9642857143rem;
  }
  .mgn-rgt83 {
    margin-right: 2.9642857143rem;
  }
  .mgn-btm83 {
    margin-bottom: 2.9642857143rem;
  }
  .mgn-lft83 {
    margin-left: 2.9642857143rem;
  }
  .mgn-84 {
    margin: 3rem;
  }
  .mgn-top84 {
    margin-top: 3rem;
  }
  .mgn-rgt84 {
    margin-right: 3rem;
  }
  .mgn-btm84 {
    margin-bottom: 3rem;
  }
  .mgn-lft84 {
    margin-left: 3rem;
  }
  .mgn-85 {
    margin: 3.0357142857rem;
  }
  .mgn-top85 {
    margin-top: 3.0357142857rem;
  }
  .mgn-rgt85 {
    margin-right: 3.0357142857rem;
  }
  .mgn-btm85 {
    margin-bottom: 3.0357142857rem;
  }
  .mgn-lft85 {
    margin-left: 3.0357142857rem;
  }
  .mgn-86 {
    margin: 3.0714285714rem;
  }
  .mgn-top86 {
    margin-top: 3.0714285714rem;
  }
  .mgn-rgt86 {
    margin-right: 3.0714285714rem;
  }
  .mgn-btm86 {
    margin-bottom: 3.0714285714rem;
  }
  .mgn-lft86 {
    margin-left: 3.0714285714rem;
  }
  .mgn-87 {
    margin: 3.1071428571rem;
  }
  .mgn-top87 {
    margin-top: 3.1071428571rem;
  }
  .mgn-rgt87 {
    margin-right: 3.1071428571rem;
  }
  .mgn-btm87 {
    margin-bottom: 3.1071428571rem;
  }
  .mgn-lft87 {
    margin-left: 3.1071428571rem;
  }
  .mgn-88 {
    margin: 3.1428571429rem;
  }
  .mgn-top88 {
    margin-top: 3.1428571429rem;
  }
  .mgn-rgt88 {
    margin-right: 3.1428571429rem;
  }
  .mgn-btm88 {
    margin-bottom: 3.1428571429rem;
  }
  .mgn-lft88 {
    margin-left: 3.1428571429rem;
  }
  .mgn-89 {
    margin: 3.1785714286rem;
  }
  .mgn-top89 {
    margin-top: 3.1785714286rem;
  }
  .mgn-rgt89 {
    margin-right: 3.1785714286rem;
  }
  .mgn-btm89 {
    margin-bottom: 3.1785714286rem;
  }
  .mgn-lft89 {
    margin-left: 3.1785714286rem;
  }
  .mgn-90 {
    margin: 3.2142857143rem;
  }
  .mgn-top90 {
    margin-top: 3.2142857143rem;
  }
  .mgn-rgt90 {
    margin-right: 3.2142857143rem;
  }
  .mgn-btm90 {
    margin-bottom: 3.2142857143rem;
  }
  .mgn-lft90 {
    margin-left: 3.2142857143rem;
  }
  .mgn-91 {
    margin: 3.25rem;
  }
  .mgn-top91 {
    margin-top: 3.25rem;
  }
  .mgn-rgt91 {
    margin-right: 3.25rem;
  }
  .mgn-btm91 {
    margin-bottom: 3.25rem;
  }
  .mgn-lft91 {
    margin-left: 3.25rem;
  }
  .mgn-92 {
    margin: 3.2857142857rem;
  }
  .mgn-top92 {
    margin-top: 3.2857142857rem;
  }
  .mgn-rgt92 {
    margin-right: 3.2857142857rem;
  }
  .mgn-btm92 {
    margin-bottom: 3.2857142857rem;
  }
  .mgn-lft92 {
    margin-left: 3.2857142857rem;
  }
  .mgn-93 {
    margin: 3.3214285714rem;
  }
  .mgn-top93 {
    margin-top: 3.3214285714rem;
  }
  .mgn-rgt93 {
    margin-right: 3.3214285714rem;
  }
  .mgn-btm93 {
    margin-bottom: 3.3214285714rem;
  }
  .mgn-lft93 {
    margin-left: 3.3214285714rem;
  }
  .mgn-94 {
    margin: 3.3571428571rem;
  }
  .mgn-top94 {
    margin-top: 3.3571428571rem;
  }
  .mgn-rgt94 {
    margin-right: 3.3571428571rem;
  }
  .mgn-btm94 {
    margin-bottom: 3.3571428571rem;
  }
  .mgn-lft94 {
    margin-left: 3.3571428571rem;
  }
  .mgn-95 {
    margin: 3.3928571429rem;
  }
  .mgn-top95 {
    margin-top: 3.3928571429rem;
  }
  .mgn-rgt95 {
    margin-right: 3.3928571429rem;
  }
  .mgn-btm95 {
    margin-bottom: 3.3928571429rem;
  }
  .mgn-lft95 {
    margin-left: 3.3928571429rem;
  }
  .mgn-96 {
    margin: 3.4285714286rem;
  }
  .mgn-top96 {
    margin-top: 3.4285714286rem;
  }
  .mgn-rgt96 {
    margin-right: 3.4285714286rem;
  }
  .mgn-btm96 {
    margin-bottom: 3.4285714286rem;
  }
  .mgn-lft96 {
    margin-left: 3.4285714286rem;
  }
  .mgn-97 {
    margin: 3.4642857143rem;
  }
  .mgn-top97 {
    margin-top: 3.4642857143rem;
  }
  .mgn-rgt97 {
    margin-right: 3.4642857143rem;
  }
  .mgn-btm97 {
    margin-bottom: 3.4642857143rem;
  }
  .mgn-lft97 {
    margin-left: 3.4642857143rem;
  }
  .mgn-98 {
    margin: 3.5rem;
  }
  .mgn-top98 {
    margin-top: 3.5rem;
  }
  .mgn-rgt98 {
    margin-right: 3.5rem;
  }
  .mgn-btm98 {
    margin-bottom: 3.5rem;
  }
  .mgn-lft98 {
    margin-left: 3.5rem;
  }
  .mgn-99 {
    margin: 3.5357142857rem;
  }
  .mgn-top99 {
    margin-top: 3.5357142857rem;
  }
  .mgn-rgt99 {
    margin-right: 3.5357142857rem;
  }
  .mgn-btm99 {
    margin-bottom: 3.5357142857rem;
  }
  .mgn-lft99 {
    margin-left: 3.5357142857rem;
  }
  .mgn-100 {
    margin: 3.5714285714rem;
  }
  .mgn-top100 {
    margin-top: 3.5714285714rem;
  }
  .mgn-rgt100 {
    margin-right: 3.5714285714rem;
  }
  .mgn-btm100 {
    margin-bottom: 3.5714285714rem;
  }
  .mgn-lft100 {
    margin-left: 3.5714285714rem;
  }
  .mgn-101 {
    margin: 3.6071428571rem;
  }
  .mgn-top101 {
    margin-top: 3.6071428571rem;
  }
  .mgn-rgt101 {
    margin-right: 3.6071428571rem;
  }
  .mgn-btm101 {
    margin-bottom: 3.6071428571rem;
  }
  .mgn-lft101 {
    margin-left: 3.6071428571rem;
  }
  .mgn-102 {
    margin: 3.6428571429rem;
  }
  .mgn-top102 {
    margin-top: 3.6428571429rem;
  }
  .mgn-rgt102 {
    margin-right: 3.6428571429rem;
  }
  .mgn-btm102 {
    margin-bottom: 3.6428571429rem;
  }
  .mgn-lft102 {
    margin-left: 3.6428571429rem;
  }
  .mgn-103 {
    margin: 3.6785714286rem;
  }
  .mgn-top103 {
    margin-top: 3.6785714286rem;
  }
  .mgn-rgt103 {
    margin-right: 3.6785714286rem;
  }
  .mgn-btm103 {
    margin-bottom: 3.6785714286rem;
  }
  .mgn-lft103 {
    margin-left: 3.6785714286rem;
  }
  .mgn-104 {
    margin: 3.7142857143rem;
  }
  .mgn-top104 {
    margin-top: 3.7142857143rem;
  }
  .mgn-rgt104 {
    margin-right: 3.7142857143rem;
  }
  .mgn-btm104 {
    margin-bottom: 3.7142857143rem;
  }
  .mgn-lft104 {
    margin-left: 3.7142857143rem;
  }
  .mgn-105 {
    margin: 3.75rem;
  }
  .mgn-top105 {
    margin-top: 3.75rem;
  }
  .mgn-rgt105 {
    margin-right: 3.75rem;
  }
  .mgn-btm105 {
    margin-bottom: 3.75rem;
  }
  .mgn-lft105 {
    margin-left: 3.75rem;
  }
  .mgn-106 {
    margin: 3.7857142857rem;
  }
  .mgn-top106 {
    margin-top: 3.7857142857rem;
  }
  .mgn-rgt106 {
    margin-right: 3.7857142857rem;
  }
  .mgn-btm106 {
    margin-bottom: 3.7857142857rem;
  }
  .mgn-lft106 {
    margin-left: 3.7857142857rem;
  }
  .mgn-107 {
    margin: 3.8214285714rem;
  }
  .mgn-top107 {
    margin-top: 3.8214285714rem;
  }
  .mgn-rgt107 {
    margin-right: 3.8214285714rem;
  }
  .mgn-btm107 {
    margin-bottom: 3.8214285714rem;
  }
  .mgn-lft107 {
    margin-left: 3.8214285714rem;
  }
  .mgn-108 {
    margin: 3.8571428571rem;
  }
  .mgn-top108 {
    margin-top: 3.8571428571rem;
  }
  .mgn-rgt108 {
    margin-right: 3.8571428571rem;
  }
  .mgn-btm108 {
    margin-bottom: 3.8571428571rem;
  }
  .mgn-lft108 {
    margin-left: 3.8571428571rem;
  }
  .mgn-109 {
    margin: 3.8928571429rem;
  }
  .mgn-top109 {
    margin-top: 3.8928571429rem;
  }
  .mgn-rgt109 {
    margin-right: 3.8928571429rem;
  }
  .mgn-btm109 {
    margin-bottom: 3.8928571429rem;
  }
  .mgn-lft109 {
    margin-left: 3.8928571429rem;
  }
  .mgn-110 {
    margin: 3.9285714286rem;
  }
  .mgn-top110 {
    margin-top: 3.9285714286rem;
  }
  .mgn-rgt110 {
    margin-right: 3.9285714286rem;
  }
  .mgn-btm110 {
    margin-bottom: 3.9285714286rem;
  }
  .mgn-lft110 {
    margin-left: 3.9285714286rem;
  }
  .mgn-111 {
    margin: 3.9642857143rem;
  }
  .mgn-top111 {
    margin-top: 3.9642857143rem;
  }
  .mgn-rgt111 {
    margin-right: 3.9642857143rem;
  }
  .mgn-btm111 {
    margin-bottom: 3.9642857143rem;
  }
  .mgn-lft111 {
    margin-left: 3.9642857143rem;
  }
  .mgn-112 {
    margin: 4rem;
  }
  .mgn-top112 {
    margin-top: 4rem;
  }
  .mgn-rgt112 {
    margin-right: 4rem;
  }
  .mgn-btm112 {
    margin-bottom: 4rem;
  }
  .mgn-lft112 {
    margin-left: 4rem;
  }
  .mgn-113 {
    margin: 4.0357142857rem;
  }
  .mgn-top113 {
    margin-top: 4.0357142857rem;
  }
  .mgn-rgt113 {
    margin-right: 4.0357142857rem;
  }
  .mgn-btm113 {
    margin-bottom: 4.0357142857rem;
  }
  .mgn-lft113 {
    margin-left: 4.0357142857rem;
  }
  .mgn-114 {
    margin: 4.0714285714rem;
  }
  .mgn-top114 {
    margin-top: 4.0714285714rem;
  }
  .mgn-rgt114 {
    margin-right: 4.0714285714rem;
  }
  .mgn-btm114 {
    margin-bottom: 4.0714285714rem;
  }
  .mgn-lft114 {
    margin-left: 4.0714285714rem;
  }
  .mgn-115 {
    margin: 4.1071428571rem;
  }
  .mgn-top115 {
    margin-top: 4.1071428571rem;
  }
  .mgn-rgt115 {
    margin-right: 4.1071428571rem;
  }
  .mgn-btm115 {
    margin-bottom: 4.1071428571rem;
  }
  .mgn-lft115 {
    margin-left: 4.1071428571rem;
  }
  .mgn-116 {
    margin: 4.1428571429rem;
  }
  .mgn-top116 {
    margin-top: 4.1428571429rem;
  }
  .mgn-rgt116 {
    margin-right: 4.1428571429rem;
  }
  .mgn-btm116 {
    margin-bottom: 4.1428571429rem;
  }
  .mgn-lft116 {
    margin-left: 4.1428571429rem;
  }
  .mgn-117 {
    margin: 4.1785714286rem;
  }
  .mgn-top117 {
    margin-top: 4.1785714286rem;
  }
  .mgn-rgt117 {
    margin-right: 4.1785714286rem;
  }
  .mgn-btm117 {
    margin-bottom: 4.1785714286rem;
  }
  .mgn-lft117 {
    margin-left: 4.1785714286rem;
  }
  .mgn-118 {
    margin: 4.2142857143rem;
  }
  .mgn-top118 {
    margin-top: 4.2142857143rem;
  }
  .mgn-rgt118 {
    margin-right: 4.2142857143rem;
  }
  .mgn-btm118 {
    margin-bottom: 4.2142857143rem;
  }
  .mgn-lft118 {
    margin-left: 4.2142857143rem;
  }
  .mgn-119 {
    margin: 4.25rem;
  }
  .mgn-top119 {
    margin-top: 4.25rem;
  }
  .mgn-rgt119 {
    margin-right: 4.25rem;
  }
  .mgn-btm119 {
    margin-bottom: 4.25rem;
  }
  .mgn-lft119 {
    margin-left: 4.25rem;
  }
  .mgn-120 {
    margin: 4.2857142857rem;
  }
  .mgn-top120 {
    margin-top: 4.2857142857rem;
  }
  .mgn-rgt120 {
    margin-right: 4.2857142857rem;
  }
  .mgn-btm120 {
    margin-bottom: 4.2857142857rem;
  }
  .mgn-lft120 {
    margin-left: 4.2857142857rem;
  }
  .mgn-121 {
    margin: 4.3214285714rem;
  }
  .mgn-top121 {
    margin-top: 4.3214285714rem;
  }
  .mgn-rgt121 {
    margin-right: 4.3214285714rem;
  }
  .mgn-btm121 {
    margin-bottom: 4.3214285714rem;
  }
  .mgn-lft121 {
    margin-left: 4.3214285714rem;
  }
  .mgn-122 {
    margin: 4.3571428571rem;
  }
  .mgn-top122 {
    margin-top: 4.3571428571rem;
  }
  .mgn-rgt122 {
    margin-right: 4.3571428571rem;
  }
  .mgn-btm122 {
    margin-bottom: 4.3571428571rem;
  }
  .mgn-lft122 {
    margin-left: 4.3571428571rem;
  }
  .mgn-123 {
    margin: 4.3928571429rem;
  }
  .mgn-top123 {
    margin-top: 4.3928571429rem;
  }
  .mgn-rgt123 {
    margin-right: 4.3928571429rem;
  }
  .mgn-btm123 {
    margin-bottom: 4.3928571429rem;
  }
  .mgn-lft123 {
    margin-left: 4.3928571429rem;
  }
  .mgn-124 {
    margin: 4.4285714286rem;
  }
  .mgn-top124 {
    margin-top: 4.4285714286rem;
  }
  .mgn-rgt124 {
    margin-right: 4.4285714286rem;
  }
  .mgn-btm124 {
    margin-bottom: 4.4285714286rem;
  }
  .mgn-lft124 {
    margin-left: 4.4285714286rem;
  }
  .mgn-125 {
    margin: 4.4642857143rem;
  }
  .mgn-top125 {
    margin-top: 4.4642857143rem;
  }
  .mgn-rgt125 {
    margin-right: 4.4642857143rem;
  }
  .mgn-btm125 {
    margin-bottom: 4.4642857143rem;
  }
  .mgn-lft125 {
    margin-left: 4.4642857143rem;
  }
  .mgn-126 {
    margin: 4.5rem;
  }
  .mgn-top126 {
    margin-top: 4.5rem;
  }
  .mgn-rgt126 {
    margin-right: 4.5rem;
  }
  .mgn-btm126 {
    margin-bottom: 4.5rem;
  }
  .mgn-lft126 {
    margin-left: 4.5rem;
  }
  .mgn-127 {
    margin: 4.5357142857rem;
  }
  .mgn-top127 {
    margin-top: 4.5357142857rem;
  }
  .mgn-rgt127 {
    margin-right: 4.5357142857rem;
  }
  .mgn-btm127 {
    margin-bottom: 4.5357142857rem;
  }
  .mgn-lft127 {
    margin-left: 4.5357142857rem;
  }
  .mgn-128 {
    margin: 4.5714285714rem;
  }
  .mgn-top128 {
    margin-top: 4.5714285714rem;
  }
  .mgn-rgt128 {
    margin-right: 4.5714285714rem;
  }
  .mgn-btm128 {
    margin-bottom: 4.5714285714rem;
  }
  .mgn-lft128 {
    margin-left: 4.5714285714rem;
  }
  .mgn-129 {
    margin: 4.6071428571rem;
  }
  .mgn-top129 {
    margin-top: 4.6071428571rem;
  }
  .mgn-rgt129 {
    margin-right: 4.6071428571rem;
  }
  .mgn-btm129 {
    margin-bottom: 4.6071428571rem;
  }
  .mgn-lft129 {
    margin-left: 4.6071428571rem;
  }
  .mgn-130 {
    margin: 4.6428571429rem;
  }
  .mgn-top130 {
    margin-top: 4.6428571429rem;
  }
  .mgn-rgt130 {
    margin-right: 4.6428571429rem;
  }
  .mgn-btm130 {
    margin-bottom: 4.6428571429rem;
  }
  .mgn-lft130 {
    margin-left: 4.6428571429rem;
  }
  .mgn-131 {
    margin: 4.6785714286rem;
  }
  .mgn-top131 {
    margin-top: 4.6785714286rem;
  }
  .mgn-rgt131 {
    margin-right: 4.6785714286rem;
  }
  .mgn-btm131 {
    margin-bottom: 4.6785714286rem;
  }
  .mgn-lft131 {
    margin-left: 4.6785714286rem;
  }
  .mgn-132 {
    margin: 4.7142857143rem;
  }
  .mgn-top132 {
    margin-top: 4.7142857143rem;
  }
  .mgn-rgt132 {
    margin-right: 4.7142857143rem;
  }
  .mgn-btm132 {
    margin-bottom: 4.7142857143rem;
  }
  .mgn-lft132 {
    margin-left: 4.7142857143rem;
  }
  .mgn-133 {
    margin: 4.75rem;
  }
  .mgn-top133 {
    margin-top: 4.75rem;
  }
  .mgn-rgt133 {
    margin-right: 4.75rem;
  }
  .mgn-btm133 {
    margin-bottom: 4.75rem;
  }
  .mgn-lft133 {
    margin-left: 4.75rem;
  }
  .mgn-134 {
    margin: 4.7857142857rem;
  }
  .mgn-top134 {
    margin-top: 4.7857142857rem;
  }
  .mgn-rgt134 {
    margin-right: 4.7857142857rem;
  }
  .mgn-btm134 {
    margin-bottom: 4.7857142857rem;
  }
  .mgn-lft134 {
    margin-left: 4.7857142857rem;
  }
  .mgn-135 {
    margin: 4.8214285714rem;
  }
  .mgn-top135 {
    margin-top: 4.8214285714rem;
  }
  .mgn-rgt135 {
    margin-right: 4.8214285714rem;
  }
  .mgn-btm135 {
    margin-bottom: 4.8214285714rem;
  }
  .mgn-lft135 {
    margin-left: 4.8214285714rem;
  }
  .mgn-136 {
    margin: 4.8571428571rem;
  }
  .mgn-top136 {
    margin-top: 4.8571428571rem;
  }
  .mgn-rgt136 {
    margin-right: 4.8571428571rem;
  }
  .mgn-btm136 {
    margin-bottom: 4.8571428571rem;
  }
  .mgn-lft136 {
    margin-left: 4.8571428571rem;
  }
  .mgn-137 {
    margin: 4.8928571429rem;
  }
  .mgn-top137 {
    margin-top: 4.8928571429rem;
  }
  .mgn-rgt137 {
    margin-right: 4.8928571429rem;
  }
  .mgn-btm137 {
    margin-bottom: 4.8928571429rem;
  }
  .mgn-lft137 {
    margin-left: 4.8928571429rem;
  }
  .mgn-138 {
    margin: 4.9285714286rem;
  }
  .mgn-top138 {
    margin-top: 4.9285714286rem;
  }
  .mgn-rgt138 {
    margin-right: 4.9285714286rem;
  }
  .mgn-btm138 {
    margin-bottom: 4.9285714286rem;
  }
  .mgn-lft138 {
    margin-left: 4.9285714286rem;
  }
  .mgn-139 {
    margin: 4.9642857143rem;
  }
  .mgn-top139 {
    margin-top: 4.9642857143rem;
  }
  .mgn-rgt139 {
    margin-right: 4.9642857143rem;
  }
  .mgn-btm139 {
    margin-bottom: 4.9642857143rem;
  }
  .mgn-lft139 {
    margin-left: 4.9642857143rem;
  }
  .mgn-140 {
    margin: 5rem;
  }
  .mgn-top140 {
    margin-top: 5rem;
  }
  .mgn-rgt140 {
    margin-right: 5rem;
  }
  .mgn-btm140 {
    margin-bottom: 5rem;
  }
  .mgn-lft140 {
    margin-left: 5rem;
  }
  .mgn-141 {
    margin: 5.0357142857rem;
  }
  .mgn-top141 {
    margin-top: 5.0357142857rem;
  }
  .mgn-rgt141 {
    margin-right: 5.0357142857rem;
  }
  .mgn-btm141 {
    margin-bottom: 5.0357142857rem;
  }
  .mgn-lft141 {
    margin-left: 5.0357142857rem;
  }
  .mgn-142 {
    margin: 5.0714285714rem;
  }
  .mgn-top142 {
    margin-top: 5.0714285714rem;
  }
  .mgn-rgt142 {
    margin-right: 5.0714285714rem;
  }
  .mgn-btm142 {
    margin-bottom: 5.0714285714rem;
  }
  .mgn-lft142 {
    margin-left: 5.0714285714rem;
  }
  .mgn-143 {
    margin: 5.1071428571rem;
  }
  .mgn-top143 {
    margin-top: 5.1071428571rem;
  }
  .mgn-rgt143 {
    margin-right: 5.1071428571rem;
  }
  .mgn-btm143 {
    margin-bottom: 5.1071428571rem;
  }
  .mgn-lft143 {
    margin-left: 5.1071428571rem;
  }
  .mgn-144 {
    margin: 5.1428571429rem;
  }
  .mgn-top144 {
    margin-top: 5.1428571429rem;
  }
  .mgn-rgt144 {
    margin-right: 5.1428571429rem;
  }
  .mgn-btm144 {
    margin-bottom: 5.1428571429rem;
  }
  .mgn-lft144 {
    margin-left: 5.1428571429rem;
  }
  .mgn-145 {
    margin: 5.1785714286rem;
  }
  .mgn-top145 {
    margin-top: 5.1785714286rem;
  }
  .mgn-rgt145 {
    margin-right: 5.1785714286rem;
  }
  .mgn-btm145 {
    margin-bottom: 5.1785714286rem;
  }
  .mgn-lft145 {
    margin-left: 5.1785714286rem;
  }
  .mgn-146 {
    margin: 5.2142857143rem;
  }
  .mgn-top146 {
    margin-top: 5.2142857143rem;
  }
  .mgn-rgt146 {
    margin-right: 5.2142857143rem;
  }
  .mgn-btm146 {
    margin-bottom: 5.2142857143rem;
  }
  .mgn-lft146 {
    margin-left: 5.2142857143rem;
  }
  .mgn-147 {
    margin: 5.25rem;
  }
  .mgn-top147 {
    margin-top: 5.25rem;
  }
  .mgn-rgt147 {
    margin-right: 5.25rem;
  }
  .mgn-btm147 {
    margin-bottom: 5.25rem;
  }
  .mgn-lft147 {
    margin-left: 5.25rem;
  }
  .mgn-148 {
    margin: 5.2857142857rem;
  }
  .mgn-top148 {
    margin-top: 5.2857142857rem;
  }
  .mgn-rgt148 {
    margin-right: 5.2857142857rem;
  }
  .mgn-btm148 {
    margin-bottom: 5.2857142857rem;
  }
  .mgn-lft148 {
    margin-left: 5.2857142857rem;
  }
  .mgn-149 {
    margin: 5.3214285714rem;
  }
  .mgn-top149 {
    margin-top: 5.3214285714rem;
  }
  .mgn-rgt149 {
    margin-right: 5.3214285714rem;
  }
  .mgn-btm149 {
    margin-bottom: 5.3214285714rem;
  }
  .mgn-lft149 {
    margin-left: 5.3214285714rem;
  }
  .mgn-150 {
    margin: 5.3571428571rem;
  }
  .mgn-top150 {
    margin-top: 5.3571428571rem;
  }
  .mgn-rgt150 {
    margin-right: 5.3571428571rem;
  }
  .mgn-btm150 {
    margin-bottom: 5.3571428571rem;
  }
  .mgn-lft150 {
    margin-left: 5.3571428571rem;
  }
}
.mgn-0_em {
  margin: 0em;
}

.mgn-top0_em {
  margin-top: 0em;
}

.mgn-rgt0_em {
  margin-right: 0em;
}

.mgn-btm0_em {
  margin-bottom: 0em;
}

.mgn-lft0_em {
  margin-left: 0em;
}

.mgn-1_em {
  margin: 1em;
}

.mgn-top1_em {
  margin-top: 1em;
}

.mgn-rgt1_em {
  margin-right: 1em;
}

.mgn-btm1_em {
  margin-bottom: 1em;
}

.mgn-lft1_em {
  margin-left: 1em;
}

.mgn-2_em {
  margin: 2em;
}

.mgn-top2_em {
  margin-top: 2em;
}

.mgn-rgt2_em {
  margin-right: 2em;
}

.mgn-btm2_em {
  margin-bottom: 2em;
}

.mgn-lft2_em {
  margin-left: 2em;
}

.mgn-3_em {
  margin: 3em;
}

.mgn-top3_em {
  margin-top: 3em;
}

.mgn-rgt3_em {
  margin-right: 3em;
}

.mgn-btm3_em {
  margin-bottom: 3em;
}

.mgn-lft3_em {
  margin-left: 3em;
}

.mgn-4_em {
  margin: 4em;
}

.mgn-top4_em {
  margin-top: 4em;
}

.mgn-rgt4_em {
  margin-right: 4em;
}

.mgn-btm4_em {
  margin-bottom: 4em;
}

.mgn-lft4_em {
  margin-left: 4em;
}

.mgn-5_em {
  margin: 5em;
}

.mgn-top5_em {
  margin-top: 5em;
}

.mgn-rgt5_em {
  margin-right: 5em;
}

.mgn-btm5_em {
  margin-bottom: 5em;
}

.mgn-lft5_em {
  margin-left: 5em;
}

.mgn-6_em {
  margin: 6em;
}

.mgn-top6_em {
  margin-top: 6em;
}

.mgn-rgt6_em {
  margin-right: 6em;
}

.mgn-btm6_em {
  margin-bottom: 6em;
}

.mgn-lft6_em {
  margin-left: 6em;
}

.mgn-7_em {
  margin: 7em;
}

.mgn-top7_em {
  margin-top: 7em;
}

.mgn-rgt7_em {
  margin-right: 7em;
}

.mgn-btm7_em {
  margin-bottom: 7em;
}

.mgn-lft7_em {
  margin-left: 7em;
}

.mgn-8_em {
  margin: 8em;
}

.mgn-top8_em {
  margin-top: 8em;
}

.mgn-rgt8_em {
  margin-right: 8em;
}

.mgn-btm8_em {
  margin-bottom: 8em;
}

.mgn-lft8_em {
  margin-left: 8em;
}

.mgn-9_em {
  margin: 9em;
}

.mgn-top9_em {
  margin-top: 9em;
}

.mgn-rgt9_em {
  margin-right: 9em;
}

.mgn-btm9_em {
  margin-bottom: 9em;
}

.mgn-lft9_em {
  margin-left: 9em;
}

.mgn-10_em {
  margin: 10em;
}

.mgn-top10_em {
  margin-top: 10em;
}

.mgn-rgt10_em {
  margin-right: 10em;
}

.mgn-btm10_em {
  margin-bottom: 10em;
}

.mgn-lft10_em {
  margin-left: 10em;
}

.mgn-11_em {
  margin: 11em;
}

.mgn-top11_em {
  margin-top: 11em;
}

.mgn-rgt11_em {
  margin-right: 11em;
}

.mgn-btm11_em {
  margin-bottom: 11em;
}

.mgn-lft11_em {
  margin-left: 11em;
}

.mgn-12_em {
  margin: 12em;
}

.mgn-top12_em {
  margin-top: 12em;
}

.mgn-rgt12_em {
  margin-right: 12em;
}

.mgn-btm12_em {
  margin-bottom: 12em;
}

.mgn-lft12_em {
  margin-left: 12em;
}

.mgn-13_em {
  margin: 13em;
}

.mgn-top13_em {
  margin-top: 13em;
}

.mgn-rgt13_em {
  margin-right: 13em;
}

.mgn-btm13_em {
  margin-bottom: 13em;
}

.mgn-lft13_em {
  margin-left: 13em;
}

.mgn-14_em {
  margin: 14em;
}

.mgn-top14_em {
  margin-top: 14em;
}

.mgn-rgt14_em {
  margin-right: 14em;
}

.mgn-btm14_em {
  margin-bottom: 14em;
}

.mgn-lft14_em {
  margin-left: 14em;
}

.mgn-15_em {
  margin: 15em;
}

.mgn-top15_em {
  margin-top: 15em;
}

.mgn-rgt15_em {
  margin-right: 15em;
}

.mgn-btm15_em {
  margin-bottom: 15em;
}

.mgn-lft15_em {
  margin-left: 15em;
}

.pdg-0 {
  padding: 0rem;
}

.pdg-top0 {
  padding-top: 0rem;
}

.pdg-rgt0 {
  padding-right: 0rem;
}

.pdg-btm0 {
  padding-bottom: 0rem;
}

.pdg-lft0 {
  padding-left: 0rem;
}

.pdg-1 {
  padding: 0.0625rem;
}

.pdg-top1 {
  padding-top: 0.0625rem;
}

.pdg-rgt1 {
  padding-right: 0.0625rem;
}

.pdg-btm1 {
  padding-bottom: 0.0625rem;
}

.pdg-lft1 {
  padding-left: 0.0625rem;
}

.pdg-2 {
  padding: 0.125rem;
}

.pdg-top2 {
  padding-top: 0.125rem;
}

.pdg-rgt2 {
  padding-right: 0.125rem;
}

.pdg-btm2 {
  padding-bottom: 0.125rem;
}

.pdg-lft2 {
  padding-left: 0.125rem;
}

.pdg-3 {
  padding: 0.1875rem;
}

.pdg-top3 {
  padding-top: 0.1875rem;
}

.pdg-rgt3 {
  padding-right: 0.1875rem;
}

.pdg-btm3 {
  padding-bottom: 0.1875rem;
}

.pdg-lft3 {
  padding-left: 0.1875rem;
}

.pdg-4 {
  padding: 0.25rem;
}

.pdg-top4 {
  padding-top: 0.25rem;
}

.pdg-rgt4 {
  padding-right: 0.25rem;
}

.pdg-btm4 {
  padding-bottom: 0.25rem;
}

.pdg-lft4 {
  padding-left: 0.25rem;
}

.pdg-5 {
  padding: 0.3125rem;
}

.pdg-top5 {
  padding-top: 0.3125rem;
}

.pdg-rgt5 {
  padding-right: 0.3125rem;
}

.pdg-btm5 {
  padding-bottom: 0.3125rem;
}

.pdg-lft5 {
  padding-left: 0.3125rem;
}

.pdg-6 {
  padding: 0.375rem;
}

.pdg-top6 {
  padding-top: 0.375rem;
}

.pdg-rgt6 {
  padding-right: 0.375rem;
}

.pdg-btm6 {
  padding-bottom: 0.375rem;
}

.pdg-lft6 {
  padding-left: 0.375rem;
}

.pdg-7 {
  padding: 0.4375rem;
}

.pdg-top7 {
  padding-top: 0.4375rem;
}

.pdg-rgt7 {
  padding-right: 0.4375rem;
}

.pdg-btm7 {
  padding-bottom: 0.4375rem;
}

.pdg-lft7 {
  padding-left: 0.4375rem;
}

.pdg-8 {
  padding: 0.5rem;
}

.pdg-top8 {
  padding-top: 0.5rem;
}

.pdg-rgt8 {
  padding-right: 0.5rem;
}

.pdg-btm8 {
  padding-bottom: 0.5rem;
}

.pdg-lft8 {
  padding-left: 0.5rem;
}

.pdg-9 {
  padding: 0.5625rem;
}

.pdg-top9 {
  padding-top: 0.5625rem;
}

.pdg-rgt9 {
  padding-right: 0.5625rem;
}

.pdg-btm9 {
  padding-bottom: 0.5625rem;
}

.pdg-lft9 {
  padding-left: 0.5625rem;
}

.pdg-10 {
  padding: 0.625rem;
}

.pdg-top10 {
  padding-top: 0.625rem;
}

.pdg-rgt10 {
  padding-right: 0.625rem;
}

.pdg-btm10 {
  padding-bottom: 0.625rem;
}

.pdg-lft10 {
  padding-left: 0.625rem;
}

.pdg-11 {
  padding: 0.6875rem;
}

.pdg-top11 {
  padding-top: 0.6875rem;
}

.pdg-rgt11 {
  padding-right: 0.6875rem;
}

.pdg-btm11 {
  padding-bottom: 0.6875rem;
}

.pdg-lft11 {
  padding-left: 0.6875rem;
}

.pdg-12 {
  padding: 0.75rem;
}

.pdg-top12 {
  padding-top: 0.75rem;
}

.pdg-rgt12 {
  padding-right: 0.75rem;
}

.pdg-btm12 {
  padding-bottom: 0.75rem;
}

.pdg-lft12 {
  padding-left: 0.75rem;
}

.pdg-13 {
  padding: 0.8125rem;
}

.pdg-top13 {
  padding-top: 0.8125rem;
}

.pdg-rgt13 {
  padding-right: 0.8125rem;
}

.pdg-btm13 {
  padding-bottom: 0.8125rem;
}

.pdg-lft13 {
  padding-left: 0.8125rem;
}

.pdg-14 {
  padding: 0.875rem;
}

.pdg-top14 {
  padding-top: 0.875rem;
}

.pdg-rgt14 {
  padding-right: 0.875rem;
}

.pdg-btm14 {
  padding-bottom: 0.875rem;
}

.pdg-lft14 {
  padding-left: 0.875rem;
}

.pdg-15 {
  padding: 0.9375rem;
}

.pdg-top15 {
  padding-top: 0.9375rem;
}

.pdg-rgt15 {
  padding-right: 0.9375rem;
}

.pdg-btm15 {
  padding-bottom: 0.9375rem;
}

.pdg-lft15 {
  padding-left: 0.9375rem;
}

.pdg-16 {
  padding: 1rem;
}

.pdg-top16 {
  padding-top: 1rem;
}

.pdg-rgt16 {
  padding-right: 1rem;
}

.pdg-btm16 {
  padding-bottom: 1rem;
}

.pdg-lft16 {
  padding-left: 1rem;
}

.pdg-17 {
  padding: 1.0625rem;
}

.pdg-top17 {
  padding-top: 1.0625rem;
}

.pdg-rgt17 {
  padding-right: 1.0625rem;
}

.pdg-btm17 {
  padding-bottom: 1.0625rem;
}

.pdg-lft17 {
  padding-left: 1.0625rem;
}

.pdg-18 {
  padding: 1.125rem;
}

.pdg-top18 {
  padding-top: 1.125rem;
}

.pdg-rgt18 {
  padding-right: 1.125rem;
}

.pdg-btm18 {
  padding-bottom: 1.125rem;
}

.pdg-lft18 {
  padding-left: 1.125rem;
}

.pdg-19 {
  padding: 1.1875rem;
}

.pdg-top19 {
  padding-top: 1.1875rem;
}

.pdg-rgt19 {
  padding-right: 1.1875rem;
}

.pdg-btm19 {
  padding-bottom: 1.1875rem;
}

.pdg-lft19 {
  padding-left: 1.1875rem;
}

.pdg-20 {
  padding: 1.25rem;
}

.pdg-top20 {
  padding-top: 1.25rem;
}

.pdg-rgt20 {
  padding-right: 1.25rem;
}

.pdg-btm20 {
  padding-bottom: 1.25rem;
}

.pdg-lft20 {
  padding-left: 1.25rem;
}

.pdg-21 {
  padding: 1.3125rem;
}

.pdg-top21 {
  padding-top: 1.3125rem;
}

.pdg-rgt21 {
  padding-right: 1.3125rem;
}

.pdg-btm21 {
  padding-bottom: 1.3125rem;
}

.pdg-lft21 {
  padding-left: 1.3125rem;
}

.pdg-22 {
  padding: 1.375rem;
}

.pdg-top22 {
  padding-top: 1.375rem;
}

.pdg-rgt22 {
  padding-right: 1.375rem;
}

.pdg-btm22 {
  padding-bottom: 1.375rem;
}

.pdg-lft22 {
  padding-left: 1.375rem;
}

.pdg-23 {
  padding: 1.4375rem;
}

.pdg-top23 {
  padding-top: 1.4375rem;
}

.pdg-rgt23 {
  padding-right: 1.4375rem;
}

.pdg-btm23 {
  padding-bottom: 1.4375rem;
}

.pdg-lft23 {
  padding-left: 1.4375rem;
}

.pdg-24 {
  padding: 1.5rem;
}

.pdg-top24 {
  padding-top: 1.5rem;
}

.pdg-rgt24 {
  padding-right: 1.5rem;
}

.pdg-btm24 {
  padding-bottom: 1.5rem;
}

.pdg-lft24 {
  padding-left: 1.5rem;
}

.pdg-25 {
  padding: 1.5625rem;
}

.pdg-top25 {
  padding-top: 1.5625rem;
}

.pdg-rgt25 {
  padding-right: 1.5625rem;
}

.pdg-btm25 {
  padding-bottom: 1.5625rem;
}

.pdg-lft25 {
  padding-left: 1.5625rem;
}

.pdg-26 {
  padding: 1.625rem;
}

.pdg-top26 {
  padding-top: 1.625rem;
}

.pdg-rgt26 {
  padding-right: 1.625rem;
}

.pdg-btm26 {
  padding-bottom: 1.625rem;
}

.pdg-lft26 {
  padding-left: 1.625rem;
}

.pdg-27 {
  padding: 1.6875rem;
}

.pdg-top27 {
  padding-top: 1.6875rem;
}

.pdg-rgt27 {
  padding-right: 1.6875rem;
}

.pdg-btm27 {
  padding-bottom: 1.6875rem;
}

.pdg-lft27 {
  padding-left: 1.6875rem;
}

.pdg-28 {
  padding: 1.75rem;
}

.pdg-top28 {
  padding-top: 1.75rem;
}

.pdg-rgt28 {
  padding-right: 1.75rem;
}

.pdg-btm28 {
  padding-bottom: 1.75rem;
}

.pdg-lft28 {
  padding-left: 1.75rem;
}

.pdg-29 {
  padding: 1.8125rem;
}

.pdg-top29 {
  padding-top: 1.8125rem;
}

.pdg-rgt29 {
  padding-right: 1.8125rem;
}

.pdg-btm29 {
  padding-bottom: 1.8125rem;
}

.pdg-lft29 {
  padding-left: 1.8125rem;
}

.pdg-30 {
  padding: 1.875rem;
}

.pdg-top30 {
  padding-top: 1.875rem;
}

.pdg-rgt30 {
  padding-right: 1.875rem;
}

.pdg-btm30 {
  padding-bottom: 1.875rem;
}

.pdg-lft30 {
  padding-left: 1.875rem;
}

.pdg-31 {
  padding: 1.9375rem;
}

.pdg-top31 {
  padding-top: 1.9375rem;
}

.pdg-rgt31 {
  padding-right: 1.9375rem;
}

.pdg-btm31 {
  padding-bottom: 1.9375rem;
}

.pdg-lft31 {
  padding-left: 1.9375rem;
}

.pdg-32 {
  padding: 2rem;
}

.pdg-top32 {
  padding-top: 2rem;
}

.pdg-rgt32 {
  padding-right: 2rem;
}

.pdg-btm32 {
  padding-bottom: 2rem;
}

.pdg-lft32 {
  padding-left: 2rem;
}

.pdg-33 {
  padding: 2.0625rem;
}

.pdg-top33 {
  padding-top: 2.0625rem;
}

.pdg-rgt33 {
  padding-right: 2.0625rem;
}

.pdg-btm33 {
  padding-bottom: 2.0625rem;
}

.pdg-lft33 {
  padding-left: 2.0625rem;
}

.pdg-34 {
  padding: 2.125rem;
}

.pdg-top34 {
  padding-top: 2.125rem;
}

.pdg-rgt34 {
  padding-right: 2.125rem;
}

.pdg-btm34 {
  padding-bottom: 2.125rem;
}

.pdg-lft34 {
  padding-left: 2.125rem;
}

.pdg-35 {
  padding: 2.1875rem;
}

.pdg-top35 {
  padding-top: 2.1875rem;
}

.pdg-rgt35 {
  padding-right: 2.1875rem;
}

.pdg-btm35 {
  padding-bottom: 2.1875rem;
}

.pdg-lft35 {
  padding-left: 2.1875rem;
}

.pdg-36 {
  padding: 2.25rem;
}

.pdg-top36 {
  padding-top: 2.25rem;
}

.pdg-rgt36 {
  padding-right: 2.25rem;
}

.pdg-btm36 {
  padding-bottom: 2.25rem;
}

.pdg-lft36 {
  padding-left: 2.25rem;
}

.pdg-37 {
  padding: 2.3125rem;
}

.pdg-top37 {
  padding-top: 2.3125rem;
}

.pdg-rgt37 {
  padding-right: 2.3125rem;
}

.pdg-btm37 {
  padding-bottom: 2.3125rem;
}

.pdg-lft37 {
  padding-left: 2.3125rem;
}

.pdg-38 {
  padding: 2.375rem;
}

.pdg-top38 {
  padding-top: 2.375rem;
}

.pdg-rgt38 {
  padding-right: 2.375rem;
}

.pdg-btm38 {
  padding-bottom: 2.375rem;
}

.pdg-lft38 {
  padding-left: 2.375rem;
}

.pdg-39 {
  padding: 2.4375rem;
}

.pdg-top39 {
  padding-top: 2.4375rem;
}

.pdg-rgt39 {
  padding-right: 2.4375rem;
}

.pdg-btm39 {
  padding-bottom: 2.4375rem;
}

.pdg-lft39 {
  padding-left: 2.4375rem;
}

.pdg-40 {
  padding: 2.5rem;
}

.pdg-top40 {
  padding-top: 2.5rem;
}

.pdg-rgt40 {
  padding-right: 2.5rem;
}

.pdg-btm40 {
  padding-bottom: 2.5rem;
}

.pdg-lft40 {
  padding-left: 2.5rem;
}

.pdg-41 {
  padding: 2.5625rem;
}

.pdg-top41 {
  padding-top: 2.5625rem;
}

.pdg-rgt41 {
  padding-right: 2.5625rem;
}

.pdg-btm41 {
  padding-bottom: 2.5625rem;
}

.pdg-lft41 {
  padding-left: 2.5625rem;
}

.pdg-42 {
  padding: 2.625rem;
}

.pdg-top42 {
  padding-top: 2.625rem;
}

.pdg-rgt42 {
  padding-right: 2.625rem;
}

.pdg-btm42 {
  padding-bottom: 2.625rem;
}

.pdg-lft42 {
  padding-left: 2.625rem;
}

.pdg-43 {
  padding: 2.6875rem;
}

.pdg-top43 {
  padding-top: 2.6875rem;
}

.pdg-rgt43 {
  padding-right: 2.6875rem;
}

.pdg-btm43 {
  padding-bottom: 2.6875rem;
}

.pdg-lft43 {
  padding-left: 2.6875rem;
}

.pdg-44 {
  padding: 2.75rem;
}

.pdg-top44 {
  padding-top: 2.75rem;
}

.pdg-rgt44 {
  padding-right: 2.75rem;
}

.pdg-btm44 {
  padding-bottom: 2.75rem;
}

.pdg-lft44 {
  padding-left: 2.75rem;
}

.pdg-45 {
  padding: 2.8125rem;
}

.pdg-top45 {
  padding-top: 2.8125rem;
}

.pdg-rgt45 {
  padding-right: 2.8125rem;
}

.pdg-btm45 {
  padding-bottom: 2.8125rem;
}

.pdg-lft45 {
  padding-left: 2.8125rem;
}

.pdg-46 {
  padding: 2.875rem;
}

.pdg-top46 {
  padding-top: 2.875rem;
}

.pdg-rgt46 {
  padding-right: 2.875rem;
}

.pdg-btm46 {
  padding-bottom: 2.875rem;
}

.pdg-lft46 {
  padding-left: 2.875rem;
}

.pdg-47 {
  padding: 2.9375rem;
}

.pdg-top47 {
  padding-top: 2.9375rem;
}

.pdg-rgt47 {
  padding-right: 2.9375rem;
}

.pdg-btm47 {
  padding-bottom: 2.9375rem;
}

.pdg-lft47 {
  padding-left: 2.9375rem;
}

.pdg-48 {
  padding: 3rem;
}

.pdg-top48 {
  padding-top: 3rem;
}

.pdg-rgt48 {
  padding-right: 3rem;
}

.pdg-btm48 {
  padding-bottom: 3rem;
}

.pdg-lft48 {
  padding-left: 3rem;
}

.pdg-49 {
  padding: 3.0625rem;
}

.pdg-top49 {
  padding-top: 3.0625rem;
}

.pdg-rgt49 {
  padding-right: 3.0625rem;
}

.pdg-btm49 {
  padding-bottom: 3.0625rem;
}

.pdg-lft49 {
  padding-left: 3.0625rem;
}

.pdg-50 {
  padding: 3.125rem;
}

.pdg-top50 {
  padding-top: 3.125rem;
}

.pdg-rgt50 {
  padding-right: 3.125rem;
}

.pdg-btm50 {
  padding-bottom: 3.125rem;
}

.pdg-lft50 {
  padding-left: 3.125rem;
}

.pdg-51 {
  padding: 3.1875rem;
}

.pdg-top51 {
  padding-top: 3.1875rem;
}

.pdg-rgt51 {
  padding-right: 3.1875rem;
}

.pdg-btm51 {
  padding-bottom: 3.1875rem;
}

.pdg-lft51 {
  padding-left: 3.1875rem;
}

.pdg-52 {
  padding: 3.25rem;
}

.pdg-top52 {
  padding-top: 3.25rem;
}

.pdg-rgt52 {
  padding-right: 3.25rem;
}

.pdg-btm52 {
  padding-bottom: 3.25rem;
}

.pdg-lft52 {
  padding-left: 3.25rem;
}

.pdg-53 {
  padding: 3.3125rem;
}

.pdg-top53 {
  padding-top: 3.3125rem;
}

.pdg-rgt53 {
  padding-right: 3.3125rem;
}

.pdg-btm53 {
  padding-bottom: 3.3125rem;
}

.pdg-lft53 {
  padding-left: 3.3125rem;
}

.pdg-54 {
  padding: 3.375rem;
}

.pdg-top54 {
  padding-top: 3.375rem;
}

.pdg-rgt54 {
  padding-right: 3.375rem;
}

.pdg-btm54 {
  padding-bottom: 3.375rem;
}

.pdg-lft54 {
  padding-left: 3.375rem;
}

.pdg-55 {
  padding: 3.4375rem;
}

.pdg-top55 {
  padding-top: 3.4375rem;
}

.pdg-rgt55 {
  padding-right: 3.4375rem;
}

.pdg-btm55 {
  padding-bottom: 3.4375rem;
}

.pdg-lft55 {
  padding-left: 3.4375rem;
}

.pdg-56 {
  padding: 3.5rem;
}

.pdg-top56 {
  padding-top: 3.5rem;
}

.pdg-rgt56 {
  padding-right: 3.5rem;
}

.pdg-btm56 {
  padding-bottom: 3.5rem;
}

.pdg-lft56 {
  padding-left: 3.5rem;
}

.pdg-57 {
  padding: 3.5625rem;
}

.pdg-top57 {
  padding-top: 3.5625rem;
}

.pdg-rgt57 {
  padding-right: 3.5625rem;
}

.pdg-btm57 {
  padding-bottom: 3.5625rem;
}

.pdg-lft57 {
  padding-left: 3.5625rem;
}

.pdg-58 {
  padding: 3.625rem;
}

.pdg-top58 {
  padding-top: 3.625rem;
}

.pdg-rgt58 {
  padding-right: 3.625rem;
}

.pdg-btm58 {
  padding-bottom: 3.625rem;
}

.pdg-lft58 {
  padding-left: 3.625rem;
}

.pdg-59 {
  padding: 3.6875rem;
}

.pdg-top59 {
  padding-top: 3.6875rem;
}

.pdg-rgt59 {
  padding-right: 3.6875rem;
}

.pdg-btm59 {
  padding-bottom: 3.6875rem;
}

.pdg-lft59 {
  padding-left: 3.6875rem;
}

.pdg-60 {
  padding: 3.75rem;
}

.pdg-top60 {
  padding-top: 3.75rem;
}

.pdg-rgt60 {
  padding-right: 3.75rem;
}

.pdg-btm60 {
  padding-bottom: 3.75rem;
}

.pdg-lft60 {
  padding-left: 3.75rem;
}

.pdg-61 {
  padding: 3.8125rem;
}

.pdg-top61 {
  padding-top: 3.8125rem;
}

.pdg-rgt61 {
  padding-right: 3.8125rem;
}

.pdg-btm61 {
  padding-bottom: 3.8125rem;
}

.pdg-lft61 {
  padding-left: 3.8125rem;
}

.pdg-62 {
  padding: 3.875rem;
}

.pdg-top62 {
  padding-top: 3.875rem;
}

.pdg-rgt62 {
  padding-right: 3.875rem;
}

.pdg-btm62 {
  padding-bottom: 3.875rem;
}

.pdg-lft62 {
  padding-left: 3.875rem;
}

.pdg-63 {
  padding: 3.9375rem;
}

.pdg-top63 {
  padding-top: 3.9375rem;
}

.pdg-rgt63 {
  padding-right: 3.9375rem;
}

.pdg-btm63 {
  padding-bottom: 3.9375rem;
}

.pdg-lft63 {
  padding-left: 3.9375rem;
}

.pdg-64 {
  padding: 4rem;
}

.pdg-top64 {
  padding-top: 4rem;
}

.pdg-rgt64 {
  padding-right: 4rem;
}

.pdg-btm64 {
  padding-bottom: 4rem;
}

.pdg-lft64 {
  padding-left: 4rem;
}

.pdg-65 {
  padding: 4.0625rem;
}

.pdg-top65 {
  padding-top: 4.0625rem;
}

.pdg-rgt65 {
  padding-right: 4.0625rem;
}

.pdg-btm65 {
  padding-bottom: 4.0625rem;
}

.pdg-lft65 {
  padding-left: 4.0625rem;
}

.pdg-66 {
  padding: 4.125rem;
}

.pdg-top66 {
  padding-top: 4.125rem;
}

.pdg-rgt66 {
  padding-right: 4.125rem;
}

.pdg-btm66 {
  padding-bottom: 4.125rem;
}

.pdg-lft66 {
  padding-left: 4.125rem;
}

.pdg-67 {
  padding: 4.1875rem;
}

.pdg-top67 {
  padding-top: 4.1875rem;
}

.pdg-rgt67 {
  padding-right: 4.1875rem;
}

.pdg-btm67 {
  padding-bottom: 4.1875rem;
}

.pdg-lft67 {
  padding-left: 4.1875rem;
}

.pdg-68 {
  padding: 4.25rem;
}

.pdg-top68 {
  padding-top: 4.25rem;
}

.pdg-rgt68 {
  padding-right: 4.25rem;
}

.pdg-btm68 {
  padding-bottom: 4.25rem;
}

.pdg-lft68 {
  padding-left: 4.25rem;
}

.pdg-69 {
  padding: 4.3125rem;
}

.pdg-top69 {
  padding-top: 4.3125rem;
}

.pdg-rgt69 {
  padding-right: 4.3125rem;
}

.pdg-btm69 {
  padding-bottom: 4.3125rem;
}

.pdg-lft69 {
  padding-left: 4.3125rem;
}

.pdg-70 {
  padding: 4.375rem;
}

.pdg-top70 {
  padding-top: 4.375rem;
}

.pdg-rgt70 {
  padding-right: 4.375rem;
}

.pdg-btm70 {
  padding-bottom: 4.375rem;
}

.pdg-lft70 {
  padding-left: 4.375rem;
}

.pdg-71 {
  padding: 4.4375rem;
}

.pdg-top71 {
  padding-top: 4.4375rem;
}

.pdg-rgt71 {
  padding-right: 4.4375rem;
}

.pdg-btm71 {
  padding-bottom: 4.4375rem;
}

.pdg-lft71 {
  padding-left: 4.4375rem;
}

.pdg-72 {
  padding: 4.5rem;
}

.pdg-top72 {
  padding-top: 4.5rem;
}

.pdg-rgt72 {
  padding-right: 4.5rem;
}

.pdg-btm72 {
  padding-bottom: 4.5rem;
}

.pdg-lft72 {
  padding-left: 4.5rem;
}

.pdg-73 {
  padding: 4.5625rem;
}

.pdg-top73 {
  padding-top: 4.5625rem;
}

.pdg-rgt73 {
  padding-right: 4.5625rem;
}

.pdg-btm73 {
  padding-bottom: 4.5625rem;
}

.pdg-lft73 {
  padding-left: 4.5625rem;
}

.pdg-74 {
  padding: 4.625rem;
}

.pdg-top74 {
  padding-top: 4.625rem;
}

.pdg-rgt74 {
  padding-right: 4.625rem;
}

.pdg-btm74 {
  padding-bottom: 4.625rem;
}

.pdg-lft74 {
  padding-left: 4.625rem;
}

.pdg-75 {
  padding: 4.6875rem;
}

.pdg-top75 {
  padding-top: 4.6875rem;
}

.pdg-rgt75 {
  padding-right: 4.6875rem;
}

.pdg-btm75 {
  padding-bottom: 4.6875rem;
}

.pdg-lft75 {
  padding-left: 4.6875rem;
}

.pdg-76 {
  padding: 4.75rem;
}

.pdg-top76 {
  padding-top: 4.75rem;
}

.pdg-rgt76 {
  padding-right: 4.75rem;
}

.pdg-btm76 {
  padding-bottom: 4.75rem;
}

.pdg-lft76 {
  padding-left: 4.75rem;
}

.pdg-77 {
  padding: 4.8125rem;
}

.pdg-top77 {
  padding-top: 4.8125rem;
}

.pdg-rgt77 {
  padding-right: 4.8125rem;
}

.pdg-btm77 {
  padding-bottom: 4.8125rem;
}

.pdg-lft77 {
  padding-left: 4.8125rem;
}

.pdg-78 {
  padding: 4.875rem;
}

.pdg-top78 {
  padding-top: 4.875rem;
}

.pdg-rgt78 {
  padding-right: 4.875rem;
}

.pdg-btm78 {
  padding-bottom: 4.875rem;
}

.pdg-lft78 {
  padding-left: 4.875rem;
}

.pdg-79 {
  padding: 4.9375rem;
}

.pdg-top79 {
  padding-top: 4.9375rem;
}

.pdg-rgt79 {
  padding-right: 4.9375rem;
}

.pdg-btm79 {
  padding-bottom: 4.9375rem;
}

.pdg-lft79 {
  padding-left: 4.9375rem;
}

.pdg-80 {
  padding: 5rem;
}

.pdg-top80 {
  padding-top: 5rem;
}

.pdg-rgt80 {
  padding-right: 5rem;
}

.pdg-btm80 {
  padding-bottom: 5rem;
}

.pdg-lft80 {
  padding-left: 5rem;
}

.pdg-81 {
  padding: 5.0625rem;
}

.pdg-top81 {
  padding-top: 5.0625rem;
}

.pdg-rgt81 {
  padding-right: 5.0625rem;
}

.pdg-btm81 {
  padding-bottom: 5.0625rem;
}

.pdg-lft81 {
  padding-left: 5.0625rem;
}

.pdg-82 {
  padding: 5.125rem;
}

.pdg-top82 {
  padding-top: 5.125rem;
}

.pdg-rgt82 {
  padding-right: 5.125rem;
}

.pdg-btm82 {
  padding-bottom: 5.125rem;
}

.pdg-lft82 {
  padding-left: 5.125rem;
}

.pdg-83 {
  padding: 5.1875rem;
}

.pdg-top83 {
  padding-top: 5.1875rem;
}

.pdg-rgt83 {
  padding-right: 5.1875rem;
}

.pdg-btm83 {
  padding-bottom: 5.1875rem;
}

.pdg-lft83 {
  padding-left: 5.1875rem;
}

.pdg-84 {
  padding: 5.25rem;
}

.pdg-top84 {
  padding-top: 5.25rem;
}

.pdg-rgt84 {
  padding-right: 5.25rem;
}

.pdg-btm84 {
  padding-bottom: 5.25rem;
}

.pdg-lft84 {
  padding-left: 5.25rem;
}

.pdg-85 {
  padding: 5.3125rem;
}

.pdg-top85 {
  padding-top: 5.3125rem;
}

.pdg-rgt85 {
  padding-right: 5.3125rem;
}

.pdg-btm85 {
  padding-bottom: 5.3125rem;
}

.pdg-lft85 {
  padding-left: 5.3125rem;
}

.pdg-86 {
  padding: 5.375rem;
}

.pdg-top86 {
  padding-top: 5.375rem;
}

.pdg-rgt86 {
  padding-right: 5.375rem;
}

.pdg-btm86 {
  padding-bottom: 5.375rem;
}

.pdg-lft86 {
  padding-left: 5.375rem;
}

.pdg-87 {
  padding: 5.4375rem;
}

.pdg-top87 {
  padding-top: 5.4375rem;
}

.pdg-rgt87 {
  padding-right: 5.4375rem;
}

.pdg-btm87 {
  padding-bottom: 5.4375rem;
}

.pdg-lft87 {
  padding-left: 5.4375rem;
}

.pdg-88 {
  padding: 5.5rem;
}

.pdg-top88 {
  padding-top: 5.5rem;
}

.pdg-rgt88 {
  padding-right: 5.5rem;
}

.pdg-btm88 {
  padding-bottom: 5.5rem;
}

.pdg-lft88 {
  padding-left: 5.5rem;
}

.pdg-89 {
  padding: 5.5625rem;
}

.pdg-top89 {
  padding-top: 5.5625rem;
}

.pdg-rgt89 {
  padding-right: 5.5625rem;
}

.pdg-btm89 {
  padding-bottom: 5.5625rem;
}

.pdg-lft89 {
  padding-left: 5.5625rem;
}

.pdg-90 {
  padding: 5.625rem;
}

.pdg-top90 {
  padding-top: 5.625rem;
}

.pdg-rgt90 {
  padding-right: 5.625rem;
}

.pdg-btm90 {
  padding-bottom: 5.625rem;
}

.pdg-lft90 {
  padding-left: 5.625rem;
}

.pdg-91 {
  padding: 5.6875rem;
}

.pdg-top91 {
  padding-top: 5.6875rem;
}

.pdg-rgt91 {
  padding-right: 5.6875rem;
}

.pdg-btm91 {
  padding-bottom: 5.6875rem;
}

.pdg-lft91 {
  padding-left: 5.6875rem;
}

.pdg-92 {
  padding: 5.75rem;
}

.pdg-top92 {
  padding-top: 5.75rem;
}

.pdg-rgt92 {
  padding-right: 5.75rem;
}

.pdg-btm92 {
  padding-bottom: 5.75rem;
}

.pdg-lft92 {
  padding-left: 5.75rem;
}

.pdg-93 {
  padding: 5.8125rem;
}

.pdg-top93 {
  padding-top: 5.8125rem;
}

.pdg-rgt93 {
  padding-right: 5.8125rem;
}

.pdg-btm93 {
  padding-bottom: 5.8125rem;
}

.pdg-lft93 {
  padding-left: 5.8125rem;
}

.pdg-94 {
  padding: 5.875rem;
}

.pdg-top94 {
  padding-top: 5.875rem;
}

.pdg-rgt94 {
  padding-right: 5.875rem;
}

.pdg-btm94 {
  padding-bottom: 5.875rem;
}

.pdg-lft94 {
  padding-left: 5.875rem;
}

.pdg-95 {
  padding: 5.9375rem;
}

.pdg-top95 {
  padding-top: 5.9375rem;
}

.pdg-rgt95 {
  padding-right: 5.9375rem;
}

.pdg-btm95 {
  padding-bottom: 5.9375rem;
}

.pdg-lft95 {
  padding-left: 5.9375rem;
}

.pdg-96 {
  padding: 6rem;
}

.pdg-top96 {
  padding-top: 6rem;
}

.pdg-rgt96 {
  padding-right: 6rem;
}

.pdg-btm96 {
  padding-bottom: 6rem;
}

.pdg-lft96 {
  padding-left: 6rem;
}

.pdg-97 {
  padding: 6.0625rem;
}

.pdg-top97 {
  padding-top: 6.0625rem;
}

.pdg-rgt97 {
  padding-right: 6.0625rem;
}

.pdg-btm97 {
  padding-bottom: 6.0625rem;
}

.pdg-lft97 {
  padding-left: 6.0625rem;
}

.pdg-98 {
  padding: 6.125rem;
}

.pdg-top98 {
  padding-top: 6.125rem;
}

.pdg-rgt98 {
  padding-right: 6.125rem;
}

.pdg-btm98 {
  padding-bottom: 6.125rem;
}

.pdg-lft98 {
  padding-left: 6.125rem;
}

.pdg-99 {
  padding: 6.1875rem;
}

.pdg-top99 {
  padding-top: 6.1875rem;
}

.pdg-rgt99 {
  padding-right: 6.1875rem;
}

.pdg-btm99 {
  padding-bottom: 6.1875rem;
}

.pdg-lft99 {
  padding-left: 6.1875rem;
}

.pdg-100 {
  padding: 6.25rem;
}

.pdg-top100 {
  padding-top: 6.25rem;
}

.pdg-rgt100 {
  padding-right: 6.25rem;
}

.pdg-btm100 {
  padding-bottom: 6.25rem;
}

.pdg-lft100 {
  padding-left: 6.25rem;
}

.pdg-101 {
  padding: 6.3125rem;
}

.pdg-top101 {
  padding-top: 6.3125rem;
}

.pdg-rgt101 {
  padding-right: 6.3125rem;
}

.pdg-btm101 {
  padding-bottom: 6.3125rem;
}

.pdg-lft101 {
  padding-left: 6.3125rem;
}

.pdg-102 {
  padding: 6.375rem;
}

.pdg-top102 {
  padding-top: 6.375rem;
}

.pdg-rgt102 {
  padding-right: 6.375rem;
}

.pdg-btm102 {
  padding-bottom: 6.375rem;
}

.pdg-lft102 {
  padding-left: 6.375rem;
}

.pdg-103 {
  padding: 6.4375rem;
}

.pdg-top103 {
  padding-top: 6.4375rem;
}

.pdg-rgt103 {
  padding-right: 6.4375rem;
}

.pdg-btm103 {
  padding-bottom: 6.4375rem;
}

.pdg-lft103 {
  padding-left: 6.4375rem;
}

.pdg-104 {
  padding: 6.5rem;
}

.pdg-top104 {
  padding-top: 6.5rem;
}

.pdg-rgt104 {
  padding-right: 6.5rem;
}

.pdg-btm104 {
  padding-bottom: 6.5rem;
}

.pdg-lft104 {
  padding-left: 6.5rem;
}

.pdg-105 {
  padding: 6.5625rem;
}

.pdg-top105 {
  padding-top: 6.5625rem;
}

.pdg-rgt105 {
  padding-right: 6.5625rem;
}

.pdg-btm105 {
  padding-bottom: 6.5625rem;
}

.pdg-lft105 {
  padding-left: 6.5625rem;
}

.pdg-106 {
  padding: 6.625rem;
}

.pdg-top106 {
  padding-top: 6.625rem;
}

.pdg-rgt106 {
  padding-right: 6.625rem;
}

.pdg-btm106 {
  padding-bottom: 6.625rem;
}

.pdg-lft106 {
  padding-left: 6.625rem;
}

.pdg-107 {
  padding: 6.6875rem;
}

.pdg-top107 {
  padding-top: 6.6875rem;
}

.pdg-rgt107 {
  padding-right: 6.6875rem;
}

.pdg-btm107 {
  padding-bottom: 6.6875rem;
}

.pdg-lft107 {
  padding-left: 6.6875rem;
}

.pdg-108 {
  padding: 6.75rem;
}

.pdg-top108 {
  padding-top: 6.75rem;
}

.pdg-rgt108 {
  padding-right: 6.75rem;
}

.pdg-btm108 {
  padding-bottom: 6.75rem;
}

.pdg-lft108 {
  padding-left: 6.75rem;
}

.pdg-109 {
  padding: 6.8125rem;
}

.pdg-top109 {
  padding-top: 6.8125rem;
}

.pdg-rgt109 {
  padding-right: 6.8125rem;
}

.pdg-btm109 {
  padding-bottom: 6.8125rem;
}

.pdg-lft109 {
  padding-left: 6.8125rem;
}

.pdg-110 {
  padding: 6.875rem;
}

.pdg-top110 {
  padding-top: 6.875rem;
}

.pdg-rgt110 {
  padding-right: 6.875rem;
}

.pdg-btm110 {
  padding-bottom: 6.875rem;
}

.pdg-lft110 {
  padding-left: 6.875rem;
}

.pdg-111 {
  padding: 6.9375rem;
}

.pdg-top111 {
  padding-top: 6.9375rem;
}

.pdg-rgt111 {
  padding-right: 6.9375rem;
}

.pdg-btm111 {
  padding-bottom: 6.9375rem;
}

.pdg-lft111 {
  padding-left: 6.9375rem;
}

.pdg-112 {
  padding: 7rem;
}

.pdg-top112 {
  padding-top: 7rem;
}

.pdg-rgt112 {
  padding-right: 7rem;
}

.pdg-btm112 {
  padding-bottom: 7rem;
}

.pdg-lft112 {
  padding-left: 7rem;
}

.pdg-113 {
  padding: 7.0625rem;
}

.pdg-top113 {
  padding-top: 7.0625rem;
}

.pdg-rgt113 {
  padding-right: 7.0625rem;
}

.pdg-btm113 {
  padding-bottom: 7.0625rem;
}

.pdg-lft113 {
  padding-left: 7.0625rem;
}

.pdg-114 {
  padding: 7.125rem;
}

.pdg-top114 {
  padding-top: 7.125rem;
}

.pdg-rgt114 {
  padding-right: 7.125rem;
}

.pdg-btm114 {
  padding-bottom: 7.125rem;
}

.pdg-lft114 {
  padding-left: 7.125rem;
}

.pdg-115 {
  padding: 7.1875rem;
}

.pdg-top115 {
  padding-top: 7.1875rem;
}

.pdg-rgt115 {
  padding-right: 7.1875rem;
}

.pdg-btm115 {
  padding-bottom: 7.1875rem;
}

.pdg-lft115 {
  padding-left: 7.1875rem;
}

.pdg-116 {
  padding: 7.25rem;
}

.pdg-top116 {
  padding-top: 7.25rem;
}

.pdg-rgt116 {
  padding-right: 7.25rem;
}

.pdg-btm116 {
  padding-bottom: 7.25rem;
}

.pdg-lft116 {
  padding-left: 7.25rem;
}

.pdg-117 {
  padding: 7.3125rem;
}

.pdg-top117 {
  padding-top: 7.3125rem;
}

.pdg-rgt117 {
  padding-right: 7.3125rem;
}

.pdg-btm117 {
  padding-bottom: 7.3125rem;
}

.pdg-lft117 {
  padding-left: 7.3125rem;
}

.pdg-118 {
  padding: 7.375rem;
}

.pdg-top118 {
  padding-top: 7.375rem;
}

.pdg-rgt118 {
  padding-right: 7.375rem;
}

.pdg-btm118 {
  padding-bottom: 7.375rem;
}

.pdg-lft118 {
  padding-left: 7.375rem;
}

.pdg-119 {
  padding: 7.4375rem;
}

.pdg-top119 {
  padding-top: 7.4375rem;
}

.pdg-rgt119 {
  padding-right: 7.4375rem;
}

.pdg-btm119 {
  padding-bottom: 7.4375rem;
}

.pdg-lft119 {
  padding-left: 7.4375rem;
}

.pdg-120 {
  padding: 7.5rem;
}

.pdg-top120 {
  padding-top: 7.5rem;
}

.pdg-rgt120 {
  padding-right: 7.5rem;
}

.pdg-btm120 {
  padding-bottom: 7.5rem;
}

.pdg-lft120 {
  padding-left: 7.5rem;
}

.pdg-121 {
  padding: 7.5625rem;
}

.pdg-top121 {
  padding-top: 7.5625rem;
}

.pdg-rgt121 {
  padding-right: 7.5625rem;
}

.pdg-btm121 {
  padding-bottom: 7.5625rem;
}

.pdg-lft121 {
  padding-left: 7.5625rem;
}

.pdg-122 {
  padding: 7.625rem;
}

.pdg-top122 {
  padding-top: 7.625rem;
}

.pdg-rgt122 {
  padding-right: 7.625rem;
}

.pdg-btm122 {
  padding-bottom: 7.625rem;
}

.pdg-lft122 {
  padding-left: 7.625rem;
}

.pdg-123 {
  padding: 7.6875rem;
}

.pdg-top123 {
  padding-top: 7.6875rem;
}

.pdg-rgt123 {
  padding-right: 7.6875rem;
}

.pdg-btm123 {
  padding-bottom: 7.6875rem;
}

.pdg-lft123 {
  padding-left: 7.6875rem;
}

.pdg-124 {
  padding: 7.75rem;
}

.pdg-top124 {
  padding-top: 7.75rem;
}

.pdg-rgt124 {
  padding-right: 7.75rem;
}

.pdg-btm124 {
  padding-bottom: 7.75rem;
}

.pdg-lft124 {
  padding-left: 7.75rem;
}

.pdg-125 {
  padding: 7.8125rem;
}

.pdg-top125 {
  padding-top: 7.8125rem;
}

.pdg-rgt125 {
  padding-right: 7.8125rem;
}

.pdg-btm125 {
  padding-bottom: 7.8125rem;
}

.pdg-lft125 {
  padding-left: 7.8125rem;
}

.pdg-126 {
  padding: 7.875rem;
}

.pdg-top126 {
  padding-top: 7.875rem;
}

.pdg-rgt126 {
  padding-right: 7.875rem;
}

.pdg-btm126 {
  padding-bottom: 7.875rem;
}

.pdg-lft126 {
  padding-left: 7.875rem;
}

.pdg-127 {
  padding: 7.9375rem;
}

.pdg-top127 {
  padding-top: 7.9375rem;
}

.pdg-rgt127 {
  padding-right: 7.9375rem;
}

.pdg-btm127 {
  padding-bottom: 7.9375rem;
}

.pdg-lft127 {
  padding-left: 7.9375rem;
}

.pdg-128 {
  padding: 8rem;
}

.pdg-top128 {
  padding-top: 8rem;
}

.pdg-rgt128 {
  padding-right: 8rem;
}

.pdg-btm128 {
  padding-bottom: 8rem;
}

.pdg-lft128 {
  padding-left: 8rem;
}

.pdg-129 {
  padding: 8.0625rem;
}

.pdg-top129 {
  padding-top: 8.0625rem;
}

.pdg-rgt129 {
  padding-right: 8.0625rem;
}

.pdg-btm129 {
  padding-bottom: 8.0625rem;
}

.pdg-lft129 {
  padding-left: 8.0625rem;
}

.pdg-130 {
  padding: 8.125rem;
}

.pdg-top130 {
  padding-top: 8.125rem;
}

.pdg-rgt130 {
  padding-right: 8.125rem;
}

.pdg-btm130 {
  padding-bottom: 8.125rem;
}

.pdg-lft130 {
  padding-left: 8.125rem;
}

.pdg-131 {
  padding: 8.1875rem;
}

.pdg-top131 {
  padding-top: 8.1875rem;
}

.pdg-rgt131 {
  padding-right: 8.1875rem;
}

.pdg-btm131 {
  padding-bottom: 8.1875rem;
}

.pdg-lft131 {
  padding-left: 8.1875rem;
}

.pdg-132 {
  padding: 8.25rem;
}

.pdg-top132 {
  padding-top: 8.25rem;
}

.pdg-rgt132 {
  padding-right: 8.25rem;
}

.pdg-btm132 {
  padding-bottom: 8.25rem;
}

.pdg-lft132 {
  padding-left: 8.25rem;
}

.pdg-133 {
  padding: 8.3125rem;
}

.pdg-top133 {
  padding-top: 8.3125rem;
}

.pdg-rgt133 {
  padding-right: 8.3125rem;
}

.pdg-btm133 {
  padding-bottom: 8.3125rem;
}

.pdg-lft133 {
  padding-left: 8.3125rem;
}

.pdg-134 {
  padding: 8.375rem;
}

.pdg-top134 {
  padding-top: 8.375rem;
}

.pdg-rgt134 {
  padding-right: 8.375rem;
}

.pdg-btm134 {
  padding-bottom: 8.375rem;
}

.pdg-lft134 {
  padding-left: 8.375rem;
}

.pdg-135 {
  padding: 8.4375rem;
}

.pdg-top135 {
  padding-top: 8.4375rem;
}

.pdg-rgt135 {
  padding-right: 8.4375rem;
}

.pdg-btm135 {
  padding-bottom: 8.4375rem;
}

.pdg-lft135 {
  padding-left: 8.4375rem;
}

.pdg-136 {
  padding: 8.5rem;
}

.pdg-top136 {
  padding-top: 8.5rem;
}

.pdg-rgt136 {
  padding-right: 8.5rem;
}

.pdg-btm136 {
  padding-bottom: 8.5rem;
}

.pdg-lft136 {
  padding-left: 8.5rem;
}

.pdg-137 {
  padding: 8.5625rem;
}

.pdg-top137 {
  padding-top: 8.5625rem;
}

.pdg-rgt137 {
  padding-right: 8.5625rem;
}

.pdg-btm137 {
  padding-bottom: 8.5625rem;
}

.pdg-lft137 {
  padding-left: 8.5625rem;
}

.pdg-138 {
  padding: 8.625rem;
}

.pdg-top138 {
  padding-top: 8.625rem;
}

.pdg-rgt138 {
  padding-right: 8.625rem;
}

.pdg-btm138 {
  padding-bottom: 8.625rem;
}

.pdg-lft138 {
  padding-left: 8.625rem;
}

.pdg-139 {
  padding: 8.6875rem;
}

.pdg-top139 {
  padding-top: 8.6875rem;
}

.pdg-rgt139 {
  padding-right: 8.6875rem;
}

.pdg-btm139 {
  padding-bottom: 8.6875rem;
}

.pdg-lft139 {
  padding-left: 8.6875rem;
}

.pdg-140 {
  padding: 8.75rem;
}

.pdg-top140 {
  padding-top: 8.75rem;
}

.pdg-rgt140 {
  padding-right: 8.75rem;
}

.pdg-btm140 {
  padding-bottom: 8.75rem;
}

.pdg-lft140 {
  padding-left: 8.75rem;
}

.pdg-141 {
  padding: 8.8125rem;
}

.pdg-top141 {
  padding-top: 8.8125rem;
}

.pdg-rgt141 {
  padding-right: 8.8125rem;
}

.pdg-btm141 {
  padding-bottom: 8.8125rem;
}

.pdg-lft141 {
  padding-left: 8.8125rem;
}

.pdg-142 {
  padding: 8.875rem;
}

.pdg-top142 {
  padding-top: 8.875rem;
}

.pdg-rgt142 {
  padding-right: 8.875rem;
}

.pdg-btm142 {
  padding-bottom: 8.875rem;
}

.pdg-lft142 {
  padding-left: 8.875rem;
}

.pdg-143 {
  padding: 8.9375rem;
}

.pdg-top143 {
  padding-top: 8.9375rem;
}

.pdg-rgt143 {
  padding-right: 8.9375rem;
}

.pdg-btm143 {
  padding-bottom: 8.9375rem;
}

.pdg-lft143 {
  padding-left: 8.9375rem;
}

.pdg-144 {
  padding: 9rem;
}

.pdg-top144 {
  padding-top: 9rem;
}

.pdg-rgt144 {
  padding-right: 9rem;
}

.pdg-btm144 {
  padding-bottom: 9rem;
}

.pdg-lft144 {
  padding-left: 9rem;
}

.pdg-145 {
  padding: 9.0625rem;
}

.pdg-top145 {
  padding-top: 9.0625rem;
}

.pdg-rgt145 {
  padding-right: 9.0625rem;
}

.pdg-btm145 {
  padding-bottom: 9.0625rem;
}

.pdg-lft145 {
  padding-left: 9.0625rem;
}

.pdg-146 {
  padding: 9.125rem;
}

.pdg-top146 {
  padding-top: 9.125rem;
}

.pdg-rgt146 {
  padding-right: 9.125rem;
}

.pdg-btm146 {
  padding-bottom: 9.125rem;
}

.pdg-lft146 {
  padding-left: 9.125rem;
}

.pdg-147 {
  padding: 9.1875rem;
}

.pdg-top147 {
  padding-top: 9.1875rem;
}

.pdg-rgt147 {
  padding-right: 9.1875rem;
}

.pdg-btm147 {
  padding-bottom: 9.1875rem;
}

.pdg-lft147 {
  padding-left: 9.1875rem;
}

.pdg-148 {
  padding: 9.25rem;
}

.pdg-top148 {
  padding-top: 9.25rem;
}

.pdg-rgt148 {
  padding-right: 9.25rem;
}

.pdg-btm148 {
  padding-bottom: 9.25rem;
}

.pdg-lft148 {
  padding-left: 9.25rem;
}

.pdg-149 {
  padding: 9.3125rem;
}

.pdg-top149 {
  padding-top: 9.3125rem;
}

.pdg-rgt149 {
  padding-right: 9.3125rem;
}

.pdg-btm149 {
  padding-bottom: 9.3125rem;
}

.pdg-lft149 {
  padding-left: 9.3125rem;
}

.pdg-150 {
  padding: 9.375rem;
}

.pdg-top150 {
  padding-top: 9.375rem;
}

.pdg-rgt150 {
  padding-right: 9.375rem;
}

.pdg-btm150 {
  padding-bottom: 9.375rem;
}

.pdg-lft150 {
  padding-left: 9.375rem;
}

/* --- position --- */
.pos_rel {
  position: relative;
}

.pos_ab {
  position: absolute;
}

/* --- float --- */
.flt-rgt {
  float: right;
}

.flt-lft {
  float: left;
}

/* breadcrumbs
**************************************** */
.breadcrumbs {
  padding: 1rem 0;
  white-space: nowrap;
  font-family: var(--font-primary);
  font-weight: 700;
  color: var(--clr-brn);
}
@media print, screen and (min-width: 48em) {
  .breadcrumbs {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (max-width: 47.9375em) {
  .breadcrumbs {
    overflow: auto;
    padding: 0.5rem 0;
    font-size: 0.7142857143rem;
  }
}

.breadcrumbs a {
  color: var(--clr-brn);
}
.breadcrumbs a:hover {
  color: var(--clr-main);
}

.breadcrumbs span span:not(:last-child)::after {
  content: "";
  display: inline-block;
  width: 0.3125rem;
  height: 0.4375rem;
  clip-path: polygon(0 100%, 0 0, 100% 50%);
  background-color: #ccc;
  margin: 0.15em 0.5em 0.15em 1em;
}

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 0.5rem;
}

.btn a {
  overflow: hidden;
  font-size: 0.875rem;
  border-top: 2px solid rgba(97, 100, 119, 0.5);
  border-bottom: 2px solid rgba(97, 100, 119, 0.5);
  text-align: center;
  font-family: var(--font-primary);
  font-weight: 700;
  height: auto;
  line-height: 1.3;
  padding: 0.75rem;
  position: relative;
  z-index: 1;
  display: block;
  width: fit-content;
  min-width: min(10.5rem, 100%);
  color: var(--clr-main);
}
@media screen and (max-width: 47.9375em) {
  .btn a {
    line-height: 1.3;
    padding: 0.8571428571rem;
    height: auto;
    min-width: min(12rem, 100%);
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.btn a:hover {
  background-color: var(--bg-main-hvr);
  border-top: 2px solid var(--bg-main-hvr);
  border-bottom: 2px solid var(--bg-main-hvr);
  color: var(--clr-wht);
}
.btn a svg {
  right: 0.5rem;
  fill: var(--clr-main);
}
.btn a:hover svg {
  fill: var(--clr-wht);
}
.btn a span {
  display: block;
  line-height: 2.75rem;
}
.btn a span.sp-none {
  display: inline-block;
}
@media screen and (max-width: 47.9375em) {
  .btn a span.sp-none {
    display: none;
  }
}

.flex > .btn a {
  min-width: 100%;
}

.btn.btn-ctr a {
  margin-left: auto;
  margin-right: auto;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--clr-main);
  background-color: var(--bg-wht);
}
.btn-wh a:hover {
  color: var(--clr-wht);
  background-color: var(--bg-main);
}
.btn-wh a svg {
  fill: var(--clr-main);
}
.btn-wh a:hover svg {
  fill: var(--clr-wht);
}

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--bg-cta);
}
.btn-cta a:hover {
  background-color: var(--bg-cta-hvr);
}

/* --- btn-sm --- */
.btn-sm a {
  font-size: 0.8125rem;
  width: 9.375rem;
  padding: 0.5rem;
  font-family: var(--font-en2);
  font-weight: 600;
}
@media screen and (max-width: 47.9375em) {
  .btn-sm a {
    width: 10.7142857143rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 0.5714285714rem !important;
  }
}
/* --- btn-cir --- */
.btn-cir a {
  border-radius: 9999px;
  color: var(--clr-wht);
  background-color: var(--bg-cta);
  font-size: 1.25rem;
  letter-spacing: 0.06em;
  border: 0;
  padding: 1.5rem;
  line-height: 1.4;
  height: auto;
}
.btn-cir a:hover {
  background-color: var(--bg-cta-hvr);
  border: 0;
}

/* --- btn-cir --- */
.btn-icon a {
  padding-right: 2rem;
}

/* ##############################################################################

  FORMY

############################################################################## */
#formy_form {
  max-width: min(60%, 1400px);
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  #formy_form {
    max-width: none;
  }
}

#formy_form table {
  width: 100%;
}
@media screen and (max-width: 47.9375em) {
  #formy_form table {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

#formy_form th,
#formy_form td {
  padding: 1.5rem 1rem;
  vertical-align: middle;
  border-bottom: solid 1px rgba(97, 100, 119, 0.5);
}
@media screen and (max-width: 47.9375em) {
  #formy_form th,
  #formy_form td {
    display: block;
    width: auto !important;
    padding-left: 0;
    padding-right: 0;
    padding: 1rem 0.7142857143rem 0.7142857143rem;
  }
}

#formy_form th {
  font-weight: 500;
  white-space: nowrap;
  text-align: left;
}
@media print, screen and (min-width: 48em) {
  #formy_form th {
    width: 34%;
  }
}
@media screen and (max-width: 47.9375em) {
  #formy_form th {
    width: 100%;
    border-bottom: none;
    padding-bottom: 0;
    white-space: normal;
    font-weight: bold;
    padding-bottom: 0;
  }
}

#formy_form th label {
  width: 90%;
}
@media screen and (max-width: 47.9375em) {
  #formy_form th label {
    width: 100%;
    font-size: 0.9285714286rem;
    margin-bottom: 0.4285714286rem;
    padding: 0;
  }
}

@media screen and (max-width: 47.9375em) {
  #formy_form td {
    padding-top: 0;
  }
}

.requiredIcon {
  position: absolute;
  right: 0;
}

#formy_form tr:first-of-type {
  border-top: solid 1px rgba(97, 100, 119, 0.5);
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form table textarea {
  width: 100%;
  border-radius: 0.1875rem;
  vertical-align: bottom;
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form select,
#formy_form textarea {
  margin: 0;
  padding: 0.3125rem 0.9375rem;
  border: 1px solid #ccc;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  font-size: 1rem;
  transition: var(--transit-default);
}

#formy_form textarea {
  height: 6.25rem;
}

#formy_form select {
  height: 2.5rem;
}

#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#formy_form input:hover {
  opacity: 0.7;
}

#formy_form textarea:hover {
  opacity: 0.7;
}

#formy_form input:focus {
  outline: none;
}

#formy_form .parsley-validated {
  background-color: #eee;
}

#formy_form .parsley-error {
  background-color: #fee;
}

#formy_form .parsley-success {
  background-color: var(--bg-wht);
}

.help_text {
  font-size: 0.875rem;
  color: #999;
}
@media screen and (max-width: 47.9375em) {
  .help_text {
    font-size: 0.7857142857rem;
  }
}

.hidden_help {
  display: none;
}

.formy_privacy div {
  overflow-y: scroll;
  height: 8.75rem;
  border: solid 1px #ccc;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.requiredIcon {
  background-color: var(--clr-cta);
  color: var(--clr-wht);
  margin: 0 0 0 1em;
  font-size: 0.75rem;
  padding: 0.125rem 0.3125rem;
  border-radius: 0.1875rem;
  float: right;
}

#formy_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: 2rem;
  text-align: center;
}

#formy_btn p {
  display: grid;
}

#formy_btn input {
  font-size: inherit;
  border: none;
  cursor: pointer;
  color: var(--clr-wht);
  border-radius: 0.1875rem;
  padding: 1rem 2rem;
  -webkit-appearance: none;
  appearance: none;
  font-family: var(--font-primary);
  transition: var(--transit-default);
}
#formy_btn input:not(:first-child) {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  #formy_btn input {
    width: auto;
    background-size: contain;
    background-position: center;
  }
}

#formy_form input[type=radio],
#formy_form input[type=checkbox] {
  display: none !important;
}

#formy_form label {
  position: relative;
  display: inline-block;
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
  font-family: var(--font-primary);
  font-weight: 700;
}
@media screen and (max-width: 47.9375em) {
  #formy_form label {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
}

#formy_form .wpcf7-radio label,
#formy_form .wpcf7-checkbox label {
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  #formy_form .wpcf7-radio label,
  #formy_form .wpcf7-checkbox label {
    padding: 0.4285714286rem 0.5714285714rem 0.4285714286rem 1.4285714286rem;
  }
}

#formy_form .wpcf7-list-item {
  margin: 0;
  display: block;
}

#formy_form .wpcf7-list-item-label:hover {
  opacity: 0.7;
}

#formy_form .wpcf7-list-item-label::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  border: 1px solid #c9c9c9;
  left: 0;
  top: 0.75rem;
}
@media screen and (max-width: 47.9375em) {
  #formy_form .wpcf7-list-item-label::before {
    width: 0.8571428571rem;
    height: 0.8571428571rem;
    top: 0.8571428571rem;
  }
}

#formy_form input[type=radio] + .wpcf7-list-item-label::before {
  border-radius: 0.625rem;
}

#formy_form input[type=radio]:checked + .wpcf7-list-item-label,
#formy_form input[type=checkbox]:checked + .wpcf7-list-item-label {
  color: var(--clr-cta);
  font-weight: bold;
}

#formy_form input[type=radio]:checked + .wpcf7-list-item-label::before,
#formy_form input[type=checkbox]:checked + .wpcf7-list-item-label::before {
  border-color: var(--clr-cta);
}

#formy_form input[type=radio]:checked + .wpcf7-list-item-label::after,
#formy_form input[type=checkbox]:checked + .wpcf7-list-item-label::after {
  content: "";
  width: 0.625rem;
  height: 1.125rem;
  top: 0.25rem;
  left: 0.25rem;
  border-right: 2px solid var(--clr-cta);
  border-bottom: 2px solid var(--clr-cta);
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}
@media screen and (max-width: 47.9375em) {
  #formy_form input[type=radio]:checked + .wpcf7-list-item-label::after,
  #formy_form input[type=checkbox]:checked + .wpcf7-list-item-label::after {
    left: 0.2857142857rem;
  }
}

.formy_confirm {
  background-color: #4dbaff;
}

.formy_submit_disabled {
  background-color: #ccc;
}

#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}

#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: 0.5rem 1.6875rem;
  min-width: auto;
  font-size: 0.9375rem;
  border: 1px solid #aaa;
  border-radius: 2rem;
}

.formy_send,
.wpcf7-submit {
  background-color: var(--clr-cta);
}

#total_required {
  padding: 1rem;
  color: var(--clr-cta);
  text-align: center;
}

@media print, screen and (min-width: 48em) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@media screen and (max-width: 47.9375em) {
  .autoConfirmBack {
    margin-bottom: 0.5714285714rem;
  }
}
/* リキャプチャ・キャプション
**************************************** */
.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha_policy {
  padding: 0;
  margin-top: 2rem;
  color: #333;
  font-size: 0.625rem; /* 10px */
  text-align: center;
  letter-spacing: 0;
}

.recaptcha_policy a {
  color: #69ABDB;
  font-size: 0.75rem; /* 12px */
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .recaptcha_policy {
    text-align: left;
    margin-top: 0.7142857143rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
/* --- section_pdg --- */
.section_pdg {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg {
    padding-top: 2.8571428571rem;
    padding-bottom: 2.8571428571rem;
  }
}

.section_pdg-sm {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg-sm {
    padding-top: 1.7142857143rem;
    padding-bottom: 1.7142857143rem;
  }
}

.section_pdg-lg {
  padding-top: 11.875rem;
  padding-bottom: 11.875rem;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg-lg {
    padding-top: 4.2857142857rem;
    padding-bottom: 5rem;
  }
}

/* --- inner --- */
.inner {
  width: 90%;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .inner {
    width: 86%;
  }
}

.inner-sm {
  max-width: 45rem;
}

.inner-md {
  max-width: 87.5rem;
}

.inner-lg {
  max-width: 103.75rem;
}

.inner-xl {
  max-width: calc(100% - 15rem);
}
@media screen and (max-width: 67.5em) {
  .inner-xl {
    max-width: 90%;
  }
}
@media screen and (max-width: 47.9375em) {
  .inner-xl {
    max-width: 86%;
  }
}

/* --- anchor --- */
.anchor {
  position: absolute;
  top: -5.5rem;
  left: 0;
  pointer-events: none;
}
@media screen and (max-width: 67.5em) {
  .anchor .anchor {
    top: -4rem;
  }
}

/* section-contact
********************************************** */
.section-contact .inner {
  border-radius: 2rem;
  background: #fff;
  padding: 5rem;
}
.section-contact .inner .cta_tel {
  margin-left: auto;
  margin-right: auto;
}

.section-contact .cta_btn {
  background: transparent;
  padding: 0;
}
.section-contact .cta_btn a::before {
  content: none;
}

.cta_tel a {
  padding: 2.5rem;
  position: relative;
}
@media screen and (max-width: 47.9375em) {
  .cta_tel a {
    display: block;
    padding: 1.1428571429rem;
  }
}
.cta_tel a::before {
  content: none;
}

.cta_tel .ttl {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  line-height: 1;
}

.cta_tel .tel {
  color: var(--clr-cta);
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 0.625rem;
  display: block;
}
@media screen and (max-width: 47.9375em) {
  .cta_tel .tel {
    font-size: 1.2857142857rem;
    margin-bottom: 0.4285714286rem;
  }
}
.cta_tel .tel .num {
  font-size: 2.5rem;
  letter-spacing: 0;
}
@media screen and (max-width: 47.9375em) {
  .cta_tel .tel .num {
    font-size: 1.8571428571rem;
  }
}

.cta_tel .hours {
  font-size: 0.8125rem;
  letter-spacing: 0.03em;
  display: block;
}
@media screen and (max-width: 47.9375em) {
  .cta_tel .hours {
    font-size: 0.7142857143rem;
  }
}

.page-contact-simple .footer .inner {
  justify-content: flex-end;
}

@media screen and (max-width: 47.9375em) {
  .section-contact .inner {
    border-radius: 1.1428571429rem;
    padding: 2.8571428571rem 0 1.4285714286rem;
    width: 90%;
  }
}
/* flex
**************************************** */
/* --- ブロック要素 --- */
.flex {
  display: flex;
}

/* --- インライン要素 --- */
.flex-inline {
  display: inline-flex;
}

/* --- 逆向き --- */
.flex-reverse {
  flex-direction: row-reverse;
}

/* --- 縦並び --- */
.flex-column {
  flex-direction: column;
}

/* --- 水平方向揃え --- */
.flex-j-start {
  justify-content: flex-start;
}

.flex-j-end {
  justify-content: flex-end;
}

.flex-j-ctr {
  justify-content: center;
}

.flex-j-between {
  justify-content: space-between;
}

.flex-j-around {
  justify-content: space-around;
}

/* --- 垂直方向揃え --- */
.flex-a-start {
  align-items: flex-start;
}

.flex-a-end {
  align-items: flex-end;
}

.flex-a-ctr {
  align-items: center;
}

.flex-a-baseline {
  align-items: baseline;
}

.flex-a-stretch {
  align-items: stretch;
}

/* --- 子要素の折り返し設定 --- */
.flex-c-nowrap {
  flex-wrap: nowrap;
}

.flex-c-wrap {
  flex-wrap: wrap;
}

/* --- 子要素の複数行設定 --- */
.flex-c-reverse {
  flex-wrap: wrap-reverse;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-end {
  align-content: flex-end;
}

.flex-c-ctr {
  align-content: center;
}

.flex-c-baseline {
  align-content: baseline;
}

.flex-c-stretch {
  align-content: stretch;
}

@media screen and (max-width: 47.9375em) {
  /* --- 縦並び - sp --- */
  .flex-sp-block {
    flex-direction: column;
  }
}
.flex-col2,
.flex-col3,
.flex-col4,
.flex-col5 {
  flex-wrap: wrap;
}

.flex-col1 > * {
  width: 100%;
}

.flex-col2 > * {
  width: 50%;
}

.flex-col3 > * {
  width: 33.3333333333%;
}

.flex-col4 > * {
  width: 25%;
}

.flex-col5 > * {
  width: 20%;
}

.flex-col2.gap {
  gap: 4%;
}

.flex-col3.gap {
  gap: 3%;
}

.flex-col4.gap {
  gap: 2%;
}

.flex-col5.gap {
  gap: 1%;
}

.flex-col2.gap > * {
  width: 48%;
  margin-bottom: 4%;
}

.flex-col3.gap > * {
  width: 31.33%;
  margin-bottom: 3%;
}

.flex-col4.gap > * {
  width: 23.5%;
  margin-bottom: 2%;
}

.flex-col5.gap > * {
  width: 19.2%;
  margin-bottom: 1%;
}

@media screen and (min-width: 48em) {
  .flex-col2.gap-wide {
    gap: 6%;
  }
  .flex-col3.gap-wide {
    gap: 5%;
  }
  .flex-col4.gap-wide {
    gap: 4%;
  }
  .flex-col5.gap-wide {
    gap: 3%;
  }
  .flex-col2.gap-wide > * {
    width: 47%;
    margin-bottom: 6%;
  }
  .flex-col3.gap-wide > * {
    width: 30%;
    margin-bottom: 5%;
  }
  .flex-col4.gap-wide > * {
    width: 22%;
    margin-bottom: 4%;
  }
  .flex-col5.gap-wide > * {
    width: 17.6%;
    margin-bottom: 3%;
  }
  .flex-col2.column-gap > *,
  .flex-col3.column-gap > *,
  .flex-col4.column-gap > *,
  .flex-col5.column-gap > * {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 47.9375em) {
  .flex-col2.flex-sp-block > *,
  .flex-col3.flex-sp-block > *,
  .flex-col4.flex-sp-block > *,
  .flex-col5.flex-sp-block > * {
    width: 100%;
  }
  .flex-col2.gap.flex-sp-block > *,
  .flex-col3.gap.flex-sp-block > *,
  .flex-col4.gap.flex-sp-block > *,
  .flex-col5.gap.flex-sp-block > * {
    margin-bottom: 1.7142857143rem;
  }
  .flex-col2.gap.flex-sp-block > *:last-child,
  .flex-col3.gap.flex-sp-block > *:last-child,
  .flex-col4.gap.flex-sp-block > *:last-child,
  .flex-col5.gap.flex-sp-block > *:last-child {
    margin-bottom: 0;
  }
  .flex-sp-col3 > * {
    width: 33.3333333333%;
  }
  .flex-sp-col2 > * {
    width: 50%;
  }
  .flex-sp-col2.gap {
    gap: 4%;
  }
  .flex-sp-col3.gap {
    gap: 3%;
  }
  .flex-sp-col2.gap > * {
    width: 48%;
    margin-bottom: 4%;
  }
  .flex-sp-col3.gap > * {
    width: 31.33%;
    margin-bottom: 3%;
  }
}
/* ##############################################################################

    FOOTER

############################################################################## */
/* cta_section
********************************************** */
.cta_section {
  position: relative;
  padding-bottom: 11.5625rem;
  padding-top: 5rem;
}

.cta_section .bgarea {
  width: 100%;
  height: 80%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
}
.cta_section .bgarea .bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 108%;
  margin: auto;
  background-image: url(../images/common/cta-img01.jpg);
  background-size: cover;
  background-position: center;
}

.cta--box {
  border-radius: 2rem;
  background-color: var(--bg-wht);
  padding: 6.25rem;
  width: 90%;
  max-width: 1640px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 47.9375em) {
  .cta--box {
    padding: 3.4285714286rem 2.1428571429rem 2.8571428571rem;
    border-radius: 1.1428571429rem;
  }
}

.cta--box .txt {
  margin-bottom: 3.5rem;
}
@media screen and (max-width: 47.9375em) {
  .cta--box .txt {
    margin-bottom: 2.1428571429rem;
    text-align: left;
  }
}

.cta--box .btnarea {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  max-width: 58rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .cta--box .btnarea {
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content;
    gap: 1rem;
  }
}

.cta_btn {
  width: 100%;
  max-width: 28.75rem;
  background: var(--clr-be);
  padding: 2.5rem;
  position: relative;
  text-align: center;
}
@media screen and (max-width: 47.9375em) {
  .cta_btn {
    display: block;
    padding: 1.1428571429rem 1.1428571429rem 0.8571428571rem;
  }
}
.cta_btn a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 2.5rem;
}
.cta_btn a::before {
  content: "";
  width: 2.5rem;
  height: 0.25rem;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  background: var(--bg-main);
}
.cta_btn .ttl {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  line-height: 1;
}
@media screen and (max-width: 47.9375em) {
  .cta_btn .ttl {
    font-size: 1.0714285714rem;
    margin-bottom: 1.1428571429rem;
  }
}

.cta_btn .btn-org {
  border-radius: 9999px;
  color: var(--clr-wht);
  background-color: var(--bg-cta);
  font-size: 1.25rem;
  letter-spacing: 0.06em;
  padding: 1.5rem;
  line-height: 1.4;
  height: auto;
  width: 100%;
  display: block;
  max-width: 344px;
  margin-left: auto;
  margin-right: auto;
  transition: var(--transit-default);
}
@media screen and (max-width: 47.9375em) {
  .cta_btn .btn-org {
    font-size: 1.0714285714rem;
    padding: 0.8571428571rem;
    width: 84%;
  }
}

.cta_btn:hover .btn-org {
  background-color: var(--bg-cta-hvr);
}

@media screen and (max-width: 47.9375em) {
  .cta_section {
    padding-bottom: 0;
    padding-top: 0;
  }
  .cta_section .bgarea {
    position: relative;
    aspect-ratio: 3/2;
  }
  .cta_section .bgarea .bg {
    background-image: url(../images/common/cta-img01_sp.jpg);
  }
  .cta--box {
    border-radius: 0;
    width: 100%;
    box-shadow: none;
  }
}
/* footer
********************************************** */
.footer {
  background-color: var(--bg-brn);
  color: var(--clr-wht);
  padding-top: 8.125rem;
  padding-bottom: 3.75rem;
}
.footer .inner {
  width: 100%;
  max-width: 100%;
  padding-left: 5rem;
  padding-right: 5rem;
}

@media screen and (max-width: 47.9375em) {
  .footer {
    padding-top: 3.5714285714rem;
    padding-bottom: 2.1428571429rem;
  }
  .footer .inner {
    padding-left: 1.4285714286rem;
    padding-right: 1.4285714286rem;
  }
}
/* footer_top
********************************************** */
.footer--catch {
  font-size: 2.25rem;
  letter-spacing: 0.1em;
  margin-bottom: 6.875rem;
}

.footer--logo {
  max-width: 132px;
}
.footer--logo svg {
  width: 100%;
  fill: #fff;
}

.footer--info {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  margin-bottom: 1.875rem;
}

.footer--company {
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.7;
  letter-spacing: 0.02em;
  margin-left: 6.25vw;
  padding: 1.875rem 0;
}

.footer--add .map {
  color: #fff;
  margin-left: 1rem;
  position: relative;
  display: inline-block;
  padding-right: 1rem;
}
.footer--add .map::before, .footer--add .map::after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
}
.footer--add .map::before {
  width: 11px;
  height: 1px;
  background: #fff;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.footer--add .map::after {
  right: 1px;
  width: 7px;
  height: 7px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  bottom: 2px;
}

.footer--tel {
  gap: 0.625rem;
}

.footer--tel a {
  color: var(--clr-wht);
}

.footer .sns_area {
  margin-right: 0.875rem;
}

/* ---sns_area --- */
@media screen and (max-width: 47.9375em) {
  .sns_area {
    margin-top: 1rem;
  }
}

.sns--list li:not(:last-child) {
  margin-right: 0.75rem;
}

.sns--list a {
  display: grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
}
.sns--list a:hover {
  opacity: 0.7;
}

.sns--list svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: #fff;
}

.sns--list li .ttl {
  display: none;
}

@media screen and (max-width: 47.9375em) {
  .footer--catch {
    font-size: 1.4285714286rem;
    line-height: 1.5;
    margin-bottom: 2.5714285714rem;
  }
  .footer--info {
    padding-top: 1.1428571429rem;
    margin-bottom: 0;
  }
  .footer--info--lft {
    align-items: flex-start;
  }
  .footer--logo {
    max-width: 70px;
  }
  .footer--company {
    padding: 0.1428571429rem 0 0.7142857143rem;
    font-size: 0.8571428571rem;
    margin-left: 0;
  }
  .footer--tel {
    gap: 0;
  }
}
/* footer_btm
*************************************************** */
/* ---fnav --- */
.fnav--menu a {
  display: block;
  letter-spacing: 0.05em;
  font-size: 0.875rem;
  color: var(--clr-wht);
  padding: 0 1.5em;
}
.fnav--menu a .txt--wrap {
  position: relative;
}
.fnav--menu a .txt--wrap::after {
  position: absolute;
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
  background: var(--clr-wht);
  bottom: -2px;
  transform: scale(0, 1);
  transform-origin: right top; /*変形（アンダーラインの伸長）の原点がaタグ（各メニュー）の右端*/
  transition: transform 0.3s;
}
.fnav--menu a:hover .txt--wrap::after {
  transform: scale(1, 1); /*ホバー後、x軸方向に1（相対値）伸長*/
  transform-origin: left top;
}
@media screen and (max-width: 47.9375em) {
  .fnav--menu a {
    position: relative;
    padding: 0.5em 0.5em 0.5em 0;
  }
}

.fnav--menu a:first-of-type {
  padding-left: 0;
}

.copyright {
  font-size: 0.75rem;
  font-family: "Lato", sans-serif;
  color: rgba(255, 255, 255, 0.25);
}

@media print, screen and (min-width: 48em) {
  .copyright p:not(:last-child) {
    margin-right: 1em;
  }
}
@media screen and (max-width: 47.9375em) {
  .copyright p:not(:last-child) {
    margin-bottom: 0.5em;
  }
}

.pbl a,
.pbl img {
  display: block;
  opacity: 0.4;
}

.pbl a {
  opacity: 0.5;
}
.pbl a:hover {
  opacity: 1;
}

@media screen and (max-width: 47.9375em) {
  .fnav {
    align-items: flex-start;
  }
  .footer .sns_area {
    margin-top: 0.7142857143rem;
    margin-right: 0;
    margin-left: auto;
  }
  .fnav--menu {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    grid-area: 10%;
  }
  .fnav--menu li {
    width: 45%;
  }
  .copyright {
    margin-left: 8%;
    margin-top: 4.2857142857rem;
    align-items: flex-start;
  }
}
/* ##############################################################################

    HEADER

############################################################################## */
/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 8rem;
  z-index: 9999;
  transition: var(--transit-default);
}
.header > .inner {
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding-left: 5rem;
  padding-right: 5rem;
}
@media screen and (max-width: 67.5em) {
  .header {
    height: 64px;
  }
  .header > .inner {
    padding-left: 1.7142857143rem;
  }
}

.header .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: var(--bg-wht);
  transition: var(--transit-default);
}

.page-contact-simple .header {
  position: absolute;
}

@media print, screen and (min-width: 67.5625em) {
  .header-sm {
    height: 5.5rem;
  }
}
.header-sm .bg {
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.7);
}

/* --- logo -- */
.header--logo {
  max-width: 105px;
}
.header--logo svg {
  width: 100%;
  height: 100%;
  fill: var(--clr-main);
}
@media screen and (max-width: 67.5em) {
  .header--logo {
    max-width: 70px;
    position: fixed;
    height: 4.5714285714rem;
    display: flex;
    align-items: center;
    z-index: 9999;
  }
  .header--logo svg {
    width: 5rem;
    height: 1.7142857143rem;
  }
}

@media screen and (max-width: 67.5em) {
  .header.active .header--logo svg {
    fill: #fff;
  }
}

.header--logo a,
.footer--logo a {
  display: block;
}
.header--logo a:hover,
.footer--logo a:hover {
  opacity: 0.7;
}

@media screen and (max-width: 67.5em) {
  .header--logo img {
    max-width: 4.25rem;
    max-height: 1.375rem;
  }
}

/* gnav
********************************************** */
@media print, screen and (min-width: 67.5625em) {
  .gnav,
  .gnav ul,
  .gnav li,
  .gnav a {
    height: 100%;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav {
    display: flex;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 300;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 5.5714285714rem 7% 1.7142857143rem;
    opacity: 0;
    pointer-events: none;
    overflow: scroll;
    background-color: var(--bg-brn);
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);
  }
  .gnav.active {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(0);
  }
}

.gnav li {
  display: inline-flex;
}

.gnav a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-primary);
  font-weight: 700;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 67.5em) {
  .gnav a {
    justify-content: flex-start;
    color: var(--clr-wht);
  }
}

/* --- menu --- */
@media screen and (max-width: 67.5em) {
  .gnav--menu,
  .gnav--cta {
    display: block;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav--menu li {
    position: relative;
    display: block;
    width: max-content;
  }
}

.gnav--link {
  padding: 0 1.5rem;
}
@media screen and (max-width: 1340px) {
  .gnav--link {
    padding: 0 1rem;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav--link {
    display: block;
    padding: 0.4285714286rem 1rem;
    font-size: 1.1428571429rem;
    color: var(--clr-wht);
    text-align: left;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--link {
    text-align: center;
  }
}
.gnav--link .txt--wrap {
  position: relative;
  line-height: 1.8;
  display: inline-block;
}
.gnav--link .txt--wrap::after {
  position: absolute;
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
  background: var(--bg-main);
  bottom: 0;
  transform: scale(0, 1);
  transform-origin: right top;
  transition: transform 0.3s;
}
@media screen and (max-width: 67.5em) {
  .gnav--link .txt--wrap {
    line-height: 1.6;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav--menu > li.current-menu-item > .gnav--link .txt--wrap::after {
    transform: scale(1, 1);
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav--menu li .gnav--link:not([href="javascript:void(0);"]):hover .txt--wrap::after {
    transform: scale(1, 1);
    transform-origin: left top;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav .sns--list {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav .sns--list a {
    width: max-content;
    padding: 1.125rem 1rem;
  }
}
.gnav .sns--list a svg {
  fill: var(--clr-main);
}
@media screen and (max-width: 67.5em) {
  .gnav .sns--list a svg {
    fill: var(--clr-wht);
    width: 1.7142857143rem;
    height: 1.7142857143rem;
    margin-right: 1rem;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav .sns--list a .ttl {
    display: block;
    color: var(--clr-wht);
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav--info {
    display: none;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav--info {
    text-align: right;
    color: var(--clr-wht);
    font-size: 0.8571428571rem;
    font-family: var(--font-primary);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin-top: 1.7142857143rem;
    position: absolute;
    right: 7%;
    bottom: 2%;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav--info .logo {
    max-width: 4.1428571429rem;
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 0.8571428571rem;
  }
  .gnav--info .logo svg {
    fill: var(--clr-wht);
  }
}

@media screen and (max-width: 67.5em) {
  .gnav--info .gnav--tel li + li {
    padding-left: 0.7142857143rem;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav--info .mail {
    display: inline-flex;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav--info .copyright {
    font-size: 0.7857142857rem;
    color: var(--clr-wht);
    margin-left: 0;
    margin-top: 0.1428571429rem;
  }
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
}

.head_sub_nav--wrap {
  display: block;
  width: 96%;
  position: absolute;
  top: 6.25rem;
  left: 5%;
  z-index: 100;
  transition: var(--transit-default);
  opacity: 0;
  background-color: var(--bg-wht);
  pointer-events: none;
  padding-bottom: 0.875rem;
}
@media screen and (max-width: 67.5em) {
  .head_sub_nav--wrap {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
    padding-bottom: 0;
  }
}

.header-sm .head_sub_nav--wrap {
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.7);
  top: 5.5rem;
}

.gnav .head_sub_nav--wrap li {
  display: block;
}

@media print, screen and (min-width: 67.5625em) {
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
}

@media screen and (max-width: 67.5em) {
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
}

@media screen and (max-width: 67.5em) {
  .head_sub_nav {
    position: relative;
  }
}

.head_sub_nav a {
  display: block;
  padding: 0.625rem 1.5rem 0.625rem 1.5rem;
  text-align: center;
  position: relative;
}
@media screen and (max-width: 67.5em) {
  .head_sub_nav a {
    padding-left: 2rem !important;
    font-size: 1.0714285714rem;
    padding: 0.4285714286rem 0.7142857143rem 0.4285714286rem 0.7142857143rem;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav_sub_toggle {
    display: none;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    right: -1.875rem;
    width: 2.8571428571rem;
    height: 2.8571428571rem;
    transition: var(--transit-default);
    color: var(--clr-wht);
    cursor: pointer;
  }
  .gnav_sub_toggle::before, .gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0.8571428571rem;
    height: 2px;
    border-bottom: 2px solid;
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle.active {
    transform: rotate(180deg);
  }
}

/* ---btn --- */
@media print, screen and (min-width: 67.5625em) {
  .gnav_btn {
    display: none;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav_btn {
    position: fixed;
    top: 0;
    width: 4.5714285714rem;
    height: 4.5714285714rem;
    cursor: pointer;
    z-index: 9999;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav_btn {
    right: 0;
    transition: var(--transit-default);
  }
  .gnav_btn.active {
    background-color: var(--bg-brn);
  }
}

@media screen and (max-width: 67.5em) {
  .gnav_btn--lines {
    position: relative;
    width: 1.7142857143rem;
    height: 1.1428571429rem;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav_btn--lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--clr-main);
    transition: var(--transit-default);
  }
  .gnav_btn--lines span:nth-of-type(1) {
    top: 0;
  }
  .gnav_btn--lines span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  .gnav_btn--lines span:nth-of-type(3) {
    bottom: 0;
  }
}

@media screen and (max-width: 67.5em) {
  .active .gnav_btn--lines span {
    background-color: var(--clr-wht);
  }
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(0.5rem) rotate(-45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    opacity: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-0.5rem) rotate(45deg);
  }
}

@media screen and (max-width: 67.5em) {
  .tel_btn {
    right: 4.5714285714rem;
  }
}
@media screen and (max-width: 67.5em) {
  .tel_btn a {
    display: flex !important;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 67.5em) {
  .tel_btn svg {
    width: 1.7142857143rem;
    height: 1.7142857143rem;
    fill: var(--clr-main);
  }
}

/* ---CTA --- */
@media screen and (max-width: 67.5em) {
  .gnav--cta li:not(:last-child) {
    margin-bottom: 1.1428571429rem;
  }
}

.gnav--tel,
.gnav--contact {
  margin-left: 0.5rem;
}

.gnav--tel a {
  display: flex !important;
  flex-direction: column;
}

.gnav--tel .tel {
  font-size: 1.25rem;
}

.gnav--tel .num {
  font-size: 1.5rem;
}

.gnav--tel .hours {
  font-size: 0.875rem;
}

@media screen and (max-width: 67.5em) {
  .gnav--contact {
    margin-left: 0;
  }
}

.gnav--contact a {
  padding: 1.5rem;
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--contact a {
    border-radius: 0;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav--contact a {
    padding: 0.7142857143rem 1.1428571429rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.gnav--contact svg {
  width: 1.3125rem;
  height: 1rem;
  fill: #fff;
  margin-right: 0.5rem;
}

/* hero
********************************************** */
.hero {
  position: relative;
  height: 60rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.hero--catch {
  position: absolute;
  top: 0;
  left: 0;
}

.hero_slides {
  overflow: hidden;
}

.hero_slide {
  overflow: hidden;
}

.hero_slide-sp {
  display: none !important;
}

.hero_slides,
.hero_slide,
.hero_slide .slick-list,
.hero_slide .slick-track,
.hero_slide .slick-slider,
.hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}

.hero_slide .slick-list {
  position: relative;
  overflow: hidden;
}

.hero--dots {
  position: absolute;
  left: 0;
  top: 0;
  width: 5rem;
  height: 100%;
}

.hero--dots li {
  writing-mode: vertical-rl;
  font-feature-settings: initial;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.875rem;
  font-size: 0.8125rem;
  color: var(--clr-sub);
  transition: var(--transit-default);
}
.hero--dots li.slick-active {
  color: var(--clr-main);
}
.hero--dots li .dot {
  cursor: pointer;
  transition: var(--transit-default);
}
.hero--dots li .dot:hover {
  color: var(--clr-main);
}

.hero--bar {
  height: 100%;
  width: 4px;
  position: absolute;
  left: 5rem;
  top: 0;
}
.hero--bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0, 0);
  background: var(--clr-main);
}
@media screen and (max-width: 47.9375em) {
  .hero--bar {
    height: 100%;
    width: 100%;
    height: 4px;
  }
}

.slick-active .hero--bar::before {
  animation: slider-border 5.5s linear forwards;
}

@media screen and (max-width: 47.9375em) {
  .hero {
    height: 38.5714285714rem;
    padding-left: 1.7142857143rem;
    padding-right: 1.7142857143rem;
  }
  @supports (-webkit-touch-callout: none) {
    .hero {
      height: -webkit-fill-available;
    }
  }
  .hero_slide-pc {
    display: none !important;
  }
  .hero_slide-sp {
    display: block !important;
  }
  .hero--dots-sp {
    position: relative;
    width: 100%;
    height: 2.1428571429rem;
  }
  .hero--dots-sp .slick-dots {
    display: flex;
  }
  .hero--dots-sp li {
    font-size: 0.9285714286rem;
    line-height: 2.1428571429rem;
    color: var(--clr-sub);
    transition: var(--transit-default);
  }
  .hero--dots-sp li.slick-active {
    color: var(--clr-main);
  }
  .hero--dots-sp li + li {
    margin-left: 1.4285714286rem;
  }
  .hero--bar {
    width: 100%;
    height: 2px;
    left: 0;
  }
  .slick-active .hero--bar::before {
    animation: slider-border-l 5.5s linear forwards;
  }
}
/* home
********************************************** */
.scroll_txt {
  width: 100%;
  position: relative;
  z-index: -1;
}

.scroll_txt--wrap {
  font-size: 12.5rem;
  white-space: nowrap;
  line-height: 0.8;
  color: var(--clr-be);
  text-transform: uppercase;
}
@media screen and (max-width: 47.9375em) {
  .scroll_txt--wrap {
    font-size: 6.4285714286rem;
  }
}

.home .page_ttl--bnr {
  position: fixed;
  bottom: 0;
  right: 3%;
}

@media screen and (max-width: 47.9375em) {
  .home .page_ttl--bnr {
    bottom: 0.7142857143rem;
    transition: var(--transit-default);
    opacity: 1;
  }
  .home .pin-active .page_ttl--bnr {
    opacity: 0;
    transition: var(--transit-default);
  }
}
/* home_catch
********************************************** */
.home_catch {
  padding-top: 11rem;
  padding-bottom: 1rem;
}

.home_catch .ttl {
  letter-spacing: 0.05em;
  line-height: 1.55;
  margin-bottom: 2.75rem;
}

.home_catch .txt {
  letter-spacing: 0.04em;
  line-height: 2.25;
  margin-bottom: 4.875rem;
}

@media screen and (max-width: 47.9375em) {
  .home_catch {
    padding-top: 7.1428571429rem;
  }
  .home_catch .txt {
    margin-bottom: 2.8571428571rem;
  }
}
/* home_message
********************************************** */
.home_message .ttl-01 {
  margin-bottom: 0.5em;
}

.home_message .scroll_txt {
  margin-bottom: -3.25rem;
  overflow: hidden;
}

.home_message > .inner {
  margin-right: 0;
  display: grid;
  grid-template-columns: 1fr 50vw;
  gap: 1.25rem;
  position: relative;
}

.home_message .imgarea {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.home_message .imgarea .img_wrap {
  overflow: hidden;
  aspect-ratio: 320/936;
  position: relative;
}
.home_message .imgarea .img_wrap .img {
  position: absolute;
  width: 100%;
  height: 110%;
}

.home_message .txt {
  line-height: 2;
  letter-spacing: 0.03em;
}

.home_message .btn {
  margin-top: 3.625rem;
}

.home_message .scroll_line {
  margin-top: 4.75rem;
}

@media screen and (max-width: 47.9375em) {
  .home_message > .inner {
    grid-template-columns: 1fr;
    margin-right: auto;
  }
  .home_message .txt {
    line-height: 1.8;
  }
  .home_message .btn {
    margin-top: 2.7142857143rem;
  }
  .home_message .imgarea {
    gap: 0.4285714286rem;
    margin-bottom: 2.1428571429rem;
    order: 1;
  }
  .home_message .txtarea {
    order: 2;
  }
}
/* home_feature
********************************************** */
.home_feature {
  overflow: hidden;
}

.home_feature .feature_item {
  gap: 6rem;
  margin-bottom: 8rem;
}

.home_feature .feature_item .imgarea {
  max-width: 50rem;
  position: relative;
  width: 100%;
}

.home_feature .feature_item .imgarea .num {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20.8333333333vw;
  line-height: 0.5;
  color: rgba(245, 242, 237, 0.5);
  z-index: -1;
  transform: translate(-50%, -50%);
}

.home_feature .feature_item .img_wrap {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 50rem;
  aspect-ratio: 800/532;
}
.home_feature .feature_item .img_wrap .img {
  position: absolute;
  width: 100%;
  height: 110%;
}

.home_feature .feature_item.flex-reverse .imgarea .num {
  right: 0;
  left: auto;
  transform: translate(50%, -50%);
}

.home_feature .feature_item .txtarea {
  max-width: 31.5rem;
}

.home_feature .feature_item .txtarea .ttl_en {
  font-size: 0.6875rem;
  display: block;
  line-height: 1;
  position: relative;
  padding-bottom: 1.125rem;
  margin-bottom: 1.875rem;
}
.home_feature .feature_item .txtarea .ttl_en::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 1.5rem;
  background: var(--bg-main);
}

.home_feature .feature_item .txtarea .ttl-02 {
  margin-bottom: 4rem;
}

.home_feature .feature_item .txtarea .txt {
  line-height: 2.125;
  letter-spacing: 0.03em;
}

.home_feature .btn a {
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 47.9375em) {
  .home_feature .feature_item {
    gap: 1.4285714286rem;
    margin-bottom: 6.4285714286rem;
  }
  .home_feature .feature_item:nth-child(3) {
    margin-bottom: 3.5714285714rem;
  }
  .home_feature .feature_item .img_wrap {
    width: 100%;
  }
  .home_feature .feature_item .imgarea .num {
    font-size: 7.1428571429rem;
    line-height: 0.5;
    transform: translate(0, -70%);
    left: -6%;
  }
  .home_feature .feature_item.flex-reverse .imgarea .num {
    transform: translate(0, -70%);
    right: auto;
    left: -6%;
  }
  .home_feature .feature_item .txtarea .ttl_en {
    padding-bottom: 0.7142857143rem;
    margin-bottom: 1rem;
  }
  .home_feature .feature_item .txtarea .ttl-02 {
    margin-bottom: 2.1428571429rem;
  }
}
/* home_kitchen
********************************************** */
.home_kitchen {
  opacity: 0;
  position: relative;
}

.home_kitchen .contentarea {
  height: calc(100vh - 5.5rem);
  position: relative;
}

.home_kitchen--current {
  position: absolute;
  z-index: 1;
  left: calc(50% - 5rem);
  width: 5rem;
  padding-top: 2.375rem;
}
.home_kitchen--current li {
  writing-mode: vertical-rl;
  font-feature-settings: initial;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.625rem;
  margin-bottom: 1.875rem;
  font-size: 0.8125rem;
  font-family: var(--font-en2);
  font-weight: 600;
}
.home_kitchen--current li.current {
  color: #fff;
}

.home_kitchen--bar_wrap {
  position: absolute;
  top: 0;
  left: calc(50% - 4px);
  width: 4px;
  height: 100%;
  z-index: 1;
}
.home_kitchen--bar_wrap .home_kitchen--bar {
  width: 100%;
  height: 1%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--bg-main);
}

.home_kitchen .kitchen_item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: var(--transit-default);
}

.home_kitchen .contentarea .kitchen_item.item_1 {
  opacity: 1;
}

.home_kitchen .kitchen_item .imgarea {
  height: 100%;
  position: relative;
}
.home_kitchen .kitchen_item .imgarea .img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.home_kitchen .kitchen_item .txtarea {
  position: relative;
  padding: 3.75rem 4rem 3.75rem 4rem;
}

.home_kitchen .kitchen_item .txtarea .kitchen--ttl {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.75rem;
}

.kitchen--ttl-en {
  font-size: 4rem;
  line-height: 0.93;
}

.home_kitchen .kitchen_item .txtarea .kitchen--ttl-rgt {
  display: flex;
  align-items: flex-start;
}
.home_kitchen .kitchen_item .txtarea .kitchen--ttl-rgt .kitchen--ttl-jp {
  font-size: 1.125rem;
  letter-spacing: 0.05em;
  position: relative;
  line-height: 1;
  padding-bottom: 1rem;
  margin-right: 2.5rem;
}
.home_kitchen .kitchen_item .txtarea .kitchen--ttl-rgt .kitchen--ttl-jp::before {
  content: "";
  height: 1px;
  width: 1.5rem;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: var(--clr-main);
}
.home_kitchen .kitchen_item .txtarea .kitchen--ttl-rgt .kitchen--ttl-num {
  font-size: 7.5rem;
  line-height: 0.5;
  letter-spacing: 0;
}

.home_kitchen .kitchen_item .txtarea--inner {
  margin-left: 5.625rem;
  margin-right: auto;
}

.home_kitchen .kitchen_item .txtarea .sub_ttl {
  font-size: 1rem;
  letter-spacing: 0.06;
  line-height: 2.125;
  margin-bottom: 0.5rem;
}

.home_kitchen .kitchen_item .txtarea .txt {
  margin-bottom: 3.125rem;
  max-width: 33.75rem;
}

.home_kitchen .kitchen_item .txtarea .btn + .btn {
  margin-left: 1rem;
}

.home_kitchen .scroll_line {
  position: absolute;
  right: 4.125rem;
  bottom: 12.5rem;
  z-index: 1;
  width: 0.375rem;
}

@media screen and (max-width: 1440px) {
  .home_kitchen .kitchen_item .txtarea {
    padding: 2rem 3rem 1.5rem;
  }
  .home_kitchen .kitchen_item .txtarea .kitchen--ttl {
    margin-bottom: 1.5rem;
  }
  .home_kitchen .kitchen_item .txtarea--inner {
    margin-left: 4rem;
  }
}
@media screen and (max-width: 1340px) {
  .home_kitchen .kitchen_item .txtarea {
    padding: 3rem 2rem;
  }
  .home_kitchen .kitchen_item .txtarea .kitchen--ttl-rgt .kitchen--ttl-jp {
    margin-right: 1rem;
  }
  .home_kitchen .kitchen_item .txtarea--inner {
    margin-left: 3rem;
  }
}
.ipad .home_kitchen {
  opacity: 1;
}
.ipad .home_kitchen .contentarea {
  height: auto;
  padding-top: 2.1428571429rem;
  overflow-x: initial;
}
.ipad .home_kitchen--bar_wrap {
  display: none;
}
.ipad .home_kitchen .kitchen_item {
  grid-template-columns: 1fr;
  position: relative;
  opacity: 0;
}
.ipad .home_kitchen .contentarea .kitchen_item.item_1 {
  opacity: 0;
}
.ipad .home_kitchen .kitchen_item + .kitchen_item {
  margin-top: 3.5714285714rem;
}
.ipad .home_kitchen .kitchen_item .imgarea {
  height: auto;
  width: 100%;
  aspect-ratio: 4/2;
  order: 2;
}
.ipad .home_kitchen .kitchen_item .txtarea {
  padding: 7% 5% 6%;
  height: auto;
  order: 1;
}
.ipad .home_kitchen .kitchen_item .txtarea .kitchen--ttl {
  padding: 0;
  top: 0;
  position: relative;
  margin-bottom: 2vh;
}
.ipad .home_kitchen .kitchen_item .txtarea--inner {
  margin-left: 0;
  padding-top: 0.7142857143rem;
}
.ipad .home_kitchen .scroll_line {
  display: none;
}

@media screen and (max-width: 47.9375em) {
  .home_kitchen {
    opacity: 1;
  }
  .home_kitchen .contentarea {
    height: auto;
    padding-top: 2.1428571429rem;
    overflow-x: initial;
  }
  .home_kitchen--bar_wrap {
    display: none;
  }
  .home_kitchen .kitchen_item {
    grid-template-columns: 1fr;
    position: relative;
    opacity: 0;
  }
  .home_kitchen .contentarea .kitchen_item.item_1 {
    opacity: 0;
  }
  .home_kitchen .kitchen_item + .kitchen_item {
    margin-top: 3.5714285714rem;
  }
  .home_kitchen .kitchen_item .imgarea {
    height: auto;
    width: 100%;
    aspect-ratio: 4/2;
    order: 2;
  }
  .home_kitchen .kitchen_item .txtarea {
    padding: 7% 5% 6%;
    height: auto;
    order: 1;
  }
  .home_kitchen .kitchen_item .txtarea .kitchen--ttl {
    padding: 0;
    top: 0;
    position: relative;
    margin-bottom: 2vh;
  }
  .kitchen--ttl-en {
    font-size: 2rem;
  }
  .home_kitchen .kitchen_item .txtarea .kitchen--ttl-rgt .kitchen--ttl-jp {
    font-size: 0.7857142857rem;
    padding-bottom: 1rem;
    margin-right: 0.625rem;
  }
  .home_kitchen .kitchen_item .txtarea .kitchen--ttl-rgt .kitchen--ttl-num {
    font-size: 2.8571428571rem;
  }
  .home_kitchen .kitchen_item .txtarea--inner {
    margin-left: 0;
    padding-top: 0.7142857143rem;
  }
  .home_kitchen .kitchen_item .ttl-02:not([class*=mgn-btm]):not(:last-child) {
    margin-bottom: 2.1428571429rem;
    font-size: 1.4285714286rem;
  }
  .home_kitchen .kitchen_item .txtarea .sub_ttl {
    font-size: 0.8571428571rem;
    line-height: 1;
  }
  .home_kitchen .kitchen_item .txtarea .txt {
    font-size: 0.9285714286rem;
    margin-bottom: 1.4285714286rem;
    line-height: 1.6;
  }
  .home_kitchen .kitchen_item .btn a {
    font-size: 0.9285714286rem;
    padding: 0.7142857143rem;
    margin-left: 0;
  }
  .home_kitchen .scroll_line {
    display: none;
  }
}
/* cursor
**************************************** */
#cursor {
  position: fixed;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 0;
  pointer-events: none;
  opacity: 0;
}

#cursor.is-scroll {
  padding-top: 14.0625rem;
  animation: cursor_scroll 0.2s linear forwards;
}

#cursor.is-scroll::before {
  content: "SCROLL";
  display: inline-block;
  width: 100%;
  padding-left: 7rem;
  text-align: left;
  color: #cec8bf;
  font-family: var(--font-en);
  font-weight: 600;
}

#cursor.is-scroll::after {
  content: "";
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #cec8be;
  position: absolute;
  bottom: 4.5rem;
  left: 7.875rem;
}

@keyframes cursor_scroll {
  0% {
    width: 0;
    height: 0;
    margin: 0;
  }
  100% {
    width: 21.875rem;
    height: 21.875rem;
    margin: -10.9375rem 0 0 -10.9375rem;
  }
}
@media screen and (max-width: 47.9375em) {
  #cursor {
    display: none;
  }
}
/* home_flow
********************************************** */
.home_flow .txtarea {
  margin-bottom: 4.375rem;
}

.home_flow .txtarea .txt {
  font-size: 1.125rem;
  letter-spacing: 0.03em;
  font-family: var(--font-primary);
  line-height: 2;
}
.home_flow .txtarea .txt small {
  font-size: 0.875rem;
  font-family: var(--font-primary);
}

.home_flow .flow_item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  counter-increment: number;
  align-items: center;
  padding-left: 0;
  border-top: solid 1px var(--clr-main);
  height: 11.625rem;
  position: relative;
}
.home_flow .flow_item .num {
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background: transparent;
  border: 1px solid var(--clr-main);
  color: var(--clr-main);
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  margin-right: 3.75rem;
}
.home_flow .flow_item .num::before {
  content: "STEP";
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  line-height: 1;
  font-family: "Outfit", sans-serif;
  font-family: var(--font-en2);
  font-weight: 600;
}
.home_flow .flow_item .num::after {
  content: counter(number, decimal-leading-zero);
  font-size: 2rem;
  letter-spacing: 0.08em;
  line-height: 1;
  font-family: "Cormorant", serif;
  font-family: var(--font-en3);
  font-weight: 600;
}
.home_flow .flow_item .illust {
  width: 12.5rem;
  max-height: 10.625rem;
  display: flex;
  justify-content: center;
}
.home_flow .flow_item .illust img {
  object-fit: contain;
}

.home_flow .flow_item:last-child {
  border-bottom: solid 1px var(--clr-main);
}

.home_flow .flow_item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: auto;
  bottom: -0.75rem;
  left: 0;
  right: 0;
  margin: auto;
  width: 3.75rem;
  height: 0.75rem;
  border: 0;
  background: var(--bg-main);
  clip-path: polygon(100% 0, 0 0, 50% 100%);
}

.home_flow .flow_item .item--lft {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.home_flow .flow_item .item--lft::after {
  content: "";
  width: 1px;
  height: 6rem;
  position: absolute;
  right: 0;
  top: 2.6875rem;
  background-color: rgba(97, 100, 119, 0.2);
}
.home_flow .flow_item .item--lft .ttl {
  width: 11rem;
  font-size: 1.25rem;
  letter-spacing: 0.08em;
}

.home_flow .flow_item .item--rgt {
  position: relative;
  padding: 0 5.625rem 0 4.625rem;
  height: 100%;
  display: flex;
  align-items: center;
}
.home_flow .flow_item .item--rgt::after {
  content: "";
  width: 1px;
  height: 6rem;
  position: absolute;
  right: 0;
  top: 2.6875rem;
  background-color: rgba(97, 100, 119, 0.2);
}

@media screen and (max-width: 47.9375em) {
  .home_flow .txtarea {
    margin-bottom: 2.1428571429rem;
  }
  .home_flow .txtarea .txt {
    font-size: 1rem;
    text-align: left;
  }
  .home_flow .txtarea .txt small {
    font-size: 0.8571428571rem;
  }
  .home_flow .flow_item {
    grid-template-columns: 1fr;
    height: auto;
  }
  .home_flow .flow_item .num {
    width: 3.1428571429rem;
    height: 3.1428571429rem;
    margin-right: 0.5714285714rem;
  }
  .home_flow .flow_item .num::before {
    font-size: 0.7142857143rem;
  }
  .home_flow .flow_item .num::after {
    font-size: 1.4285714286rem;
  }
  .home_flow .flow_item .illust {
    width: 6.4285714286rem;
    max-height: 4.8571428571rem;
    display: flex;
    justify-content: center;
  }
  .home_flow .flow_item .illust img {
    object-fit: contain;
  }
  .home_flow .flow_item:not(:last-child)::before {
    bottom: -0.5714285714rem;
    width: 1.8571428571rem;
    height: 0.5714285714rem;
  }
  .home_flow .flow_item .toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 2.1428571429rem;
    height: 2.1428571429rem;
    color: var(--clr-wht);
    background-color: var(--bg-main);
    border-radius: 50%;
    transition: var(--transit-default);
  }
  .home_flow .flow_item .toggle::before, .home_flow .flow_item .toggle::after {
    content: "";
    position: absolute;
    inset: 0;
    margin: auto;
    width: 0.75rem;
    height: 2px;
    border-bottom: 2px solid;
    transition: var(--transit-default);
  }
  .home_flow .flow_item .toggle::after {
    transform: rotate(90deg);
  }
  .home_flow .flow_item .toggle.active {
    transform: rotate(180deg);
    background-color: rgba(56, 58, 75, 0.5);
  }
  .home_flow .flow_item .toggle.active::after {
    opacity: 0;
  }
  .home_flow .flow_item .item--lft {
    padding-top: 0.8571428571rem;
    padding-bottom: 0.8571428571rem;
    height: 6.8571428571rem;
  }
  .home_flow .flow_item .item--lft .ttl {
    width: 9.2857142857rem;
    font-size: 1.0714285714rem;
  }
  .home_flow .flow_item .item--lft::after {
    content: none;
  }
  .home_flow .flow_item .item--rgt {
    padding: 0;
  }
  .home_flow .flow_item .item--rgt::after {
    content: none;
  }
  .home_flow .flow_item .item--rgt .text {
    padding: 0.7142857143rem;
  }
}
/* home_company
********************************************** */
.home_company {
  position: relative;
  padding-bottom: 14rem;
  padding-top: 5rem;
}

.home_company .bgarea {
  width: 100%;
  height: 86%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
}
.home_company .bgarea .bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 108%;
  margin: auto;
  background-image: url(../images/home/company-img01.jpg);
  background-size: cover;
  background-position: 60% 50%;
}

.company--cont {
  max-width: 55rem;
  width: 50%;
  border-radius: 2rem;
  margin-left: auto;
  margin-right: 0;
  color: var(--clr-wht);
  background: var(--bg-brn);
  padding: 5.2083333333vw 5.7291666667vw;
}

.company--cont .ttl-01,
.company--cont .ttl-01 .jp {
  color: var(--clr-wht);
}

.company--cont .txt {
  margin-bottom: 4rem;
}

.company--cont .btnarea {
  gap: 0.8333333333vw;
}

.company--cont .btn {
  min-width: 10.5rem;
}
.company--cont .btn a {
  color: var(--clr-wht);
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 47.9375em) {
  .home_company {
    position: relative;
    padding-bottom: 3rem;
    padding-top: 3rem;
  }
  .home_company .inner {
    width: 100%;
  }
  .home_company .bgarea {
    position: relative;
    aspect-ratio: 3/2;
  }
  .home_company .bgarea .bg {
    background-image: url(../images/home/sp/company-img01_sp.jpg);
    background-repeat: no-repeat;
  }
  .company--cont {
    padding: 2.8571428571rem 1.7142857143rem 2.1428571429rem;
    width: 100%;
    border-radius: 0;
  }
  .company--cont .txt {
    margin-bottom: 1.4285714286rem;
  }
  .company--cont .btnarea {
    flex-wrap: wrap;
    column-gap: 1rem;
  }
  .company--cont .btn {
    min-width: min(120px, 100%);
  }
  .company--cont .btn a {
    min-width: min(120px, 100%);
  }
}
/* home_works
********************************************** */
.home_works .posts-works {
  margin-bottom: 4.375rem;
  margin-top: 5rem;
}

.home_works .posts-works .post {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.home_works .posts-works .post--img {
  margin-bottom: 0;
}
.home_works .posts-works .post--img::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(41, 30, 9, 0.5);
  mix-blend-mode: multiply;
  opacity: 0;
  transition: var(--transit-default);
}

.home_works .posts-works .post--info {
  display: none;
}

.home_works .posts-works .post--txtarea {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  color: #fff;
  transition: var(--transit-default);
  max-width: 26.6875rem;
}

.home_works .posts-works .post .btn_more {
  display: block;
  font-size: 0.9375rem;
  letter-spacing: 0.26em;
}

.home_works .posts-works .post--txtarea a {
  color: #fff;
}

.home_works .posts-works .post:hover .post--img::after {
  opacity: 1;
}
.home_works .posts-works .post:hover .post--txtarea {
  opacity: 1;
}

@media screen and (max-width: 47.9375em) {
  .home_works .posts-works {
    margin-bottom: 1.4285714286rem;
    margin-top: 2.8571428571rem;
  }
  .home_works .posts-works .post {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .home_works .posts-works .post .post--ttl {
    font-size: 0.9285714286rem;
    letter-spacing: 0.02em;
  }
  .home_works .posts-works .post--img::after {
    content: none;
  }
  .home_works .posts-works .post--txtarea {
    position: initial;
    opacity: 1;
    padding: 0.2857142857rem 0 0;
    align-items: flex-start;
  }
  .home_works .posts-works .post--txtarea a {
    color: var(--clr-main);
  }
  .home_works .posts-works .post .btn_more {
    display: none;
  }
}
/* home_search
********************************************** */
.home_search {
  padding-top: 8.625rem;
  padding-bottom: 8.625rem;
  background-color: rgba(255, 255, 255, 0.75);
  background-blend-mode: lighten;
}

@media screen and (max-width: 47.9375em) {
  .home_search {
    padding-top: 2.8571428571rem;
    padding-bottom: 2.8571428571rem;
  }
}
/* home_showroom
********************************************** */
.home_showroom .ttl-01 {
  margin-bottom: 1.5em;
}

.home_showroom .contentarea {
  display: flex;
  gap: 6%;
}

.home_showroom .contentarea .imgarea {
  width: 45%;
}

.home_showroom .contentarea .txtarea {
  width: 49%;
}

.home_showroom .txtarea .ttl-02 {
  font-size: 2.25rem;
  letter-spacing: 0.02em;
  line-height: 1.4;
}

.home_showroom .txtarea .txt {
  font-size: 0.9375rem;
  line-height: 2.1;
  letter-spacing: 0.06em;
  margin-bottom: 3.125rem;
}

.home_showroom .tablearea {
  margin-bottom: 2.5rem;
}

.home_showroom .tablearea .ttl {
  font-size: 1.25rem;
  letter-spacing: 0.06em;
  line-height: 1.9;
  margin-bottom: 1.5rem;
}

.home_showroom .tablearea table {
  width: 100%;
  margin-bottom: 1.875rem;
}
.home_showroom .tablearea table tr {
  border-top: solid 1px rgba(97, 100, 119, 0.5);
}
.home_showroom .tablearea table tr:last-child {
  border-bottom: solid 1px rgba(97, 100, 119, 0.5);
}
.home_showroom .tablearea table th {
  font-size: 0.9375rem;
  text-align: left;
  width: 28%;
  padding: 0.9375rem 0.875rem;
}
.home_showroom .tablearea table td {
  font-size: 0.875rem;
  line-height: 1.85;
  padding: 0.9375rem 0.875rem;
  font-weight: 500;
}

.home_showroom .tablearea .note {
  font-size: 0.8125rem;
  letter-spacing: 0.02em;
  line-height: 1.76;
  font-weight: 500;
}

.home_showroom .tablearea .note p {
  display: flex;
}
.home_showroom .tablearea .note p::before {
  content: "※";
  font-size: 0.625rem;
  font-family: var(--font-primary);
  font-weight: 700;
  margin-top: 0.125rem;
  margin-right: 0.125rem;
}

.showroom--dots {
  position: absolute;
  left: -5rem;
  top: 0;
  width: 5rem;
  height: 100%;
}
.showroom--dots li {
  writing-mode: vertical-rl;
  font-feature-settings: initial;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.875rem;
  font-size: 0.8125rem;
  color: var(--clr-sub);
  transition: var(--transit-default);
}
.showroom--dots li.slick-active {
  color: var(--clr-main);
}

@media screen and (max-width: 47.9375em) {
  .home_showroom .contentarea .imgarea {
    width: 100%;
    margin-bottom: 1.4285714286rem;
  }
  .home_showroom .contentarea .txtarea {
    width: 100%;
  }
  .home_showroom .txtarea .ttl-02 {
    font-size: 1.5714285714rem;
    letter-spacing: 0.02em;
  }
  .home_showroom .txtarea .txt {
    margin-bottom: 2.1428571429rem;
  }
  .showroom--dots {
    position: relative;
    width: 100%;
    height: 2.1428571429rem;
    left: 0;
  }
  .showroom--dots .slick-dots {
    display: flex;
  }
  .showroom--dots li {
    writing-mode: initial;
    font-size: 0.9285714286rem;
    line-height: 2.1428571429rem;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
  }
  .showroom--dots li + li {
    margin-left: 1.4285714286rem;
  }
}
/* home_news
********************************************** */
.home_news {
  padding-top: 11.25rem;
}

.home_news .news_list {
  flex: 1;
  max-width: 900px;
}

.news_list--ttl {
  display: block;
  position: relative;
  margin-right: 2.5rem;
  padding: 0;
  line-height: 1.2;
}
@media screen and (max-width: 47.9375em) {
  .news_list--ttl {
    width: auto;
    float: none;
    height: auto;
    margin-bottom: 1.1428571429rem;
  }
}

.home_news .btn {
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -20%;
}

@media screen and (max-width: 47.9375em) {
  .home_news {
    padding-top: 5rem;
  }
  .home_news .btn {
    position: relative;
    display: block;
    margin-top: 2.1428571429rem;
  }
}
/* home_blog
********************************************** */
.home_blog .blog_list {
  flex: 1;
  max-width: 900px;
}

.home_blog .posts-blog .post--img {
  width: 9.375rem;
  margin-right: 1.625rem;
}

.home_blog .posts-blog .post--txt {
  -webkit-line-clamp: 1;
}

.home_blog .btn {
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 0;
}

@media screen and (max-width: 47.9375em) {
  .home_blog .posts-blog .post--img {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.4285714286rem;
  }
  .home_blog .btn {
    position: relative;
    display: block;
    margin-top: 2.1428571429rem;
  }
}
/* home_
********************************************** */
/* home_
********************************************** */
/* home_
********************************************** */
.js-fade,
.js-fade-wrap > .js-fade2 {
  opacity: 0 !important;
  translate: 0 30px;
  transition: var(--transit-default);
  transition-property: opacity, translate;
}

.js-fade.is-active,
.js-fade-wrap.is-active > .js-fade2 {
  opacity: 1 !important;
  translate: 0 0;
  pointer-events: auto;
}

.ipad .js-sp-fade {
  opacity: 0;
  translate: 0 30px;
  transition: var(--transit-default);
  transition-property: opacity, translate;
}
.ipad .js-sp-fade.is-active {
  opacity: 1 !important;
  translate: 0 0;
  pointer-events: auto;
}

@media screen and (max-width: 47.9375em) {
  .js-sp-fade {
    opacity: 0;
    translate: 0 30px;
    transition: var(--transit-default);
    transition-property: opacity, translate;
  }
  .js-sp-fade.is-active {
    opacity: 1 !important;
    translate: 0 0;
    pointer-events: auto;
  }
}
/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
  margin-right: 5rem;
  margin-left: 5rem;
  margin-bottom: 6.25rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl {
    margin-right: 0;
    margin-left: 1.5714285714rem;
    margin-bottom: 0;
  }
}

.page_ttl--bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 1080px;
  width: 60vw;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--bg {
    width: 87.5vw;
    top: 14.2857142857rem;
    bottom: 0;
  }
}

.page_ttl-jp {
  font-size: 1.5rem;
  color: var(--clr-brn);
  font-family: var(--font-primary);
  font-weight: 700;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-jp {
    font-size: 1rem;
    margin-bottom: 1.4285714286rem;
  }
}

.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: 6.5rem;
  line-height: 0.8;
  color: var(--clr-main);
  margin-bottom: 1.625rem;
  margin-top: 4.625rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-en {
    font-size: 3.4285714286rem;
    margin-top: 0;
  }
}

.page_ttl .scroll_line {
  position: absolute;
  bottom: 0;
  width: 5px;
  right: min(60vw + 50px, 1130px);
}
@media screen and (max-width: 47.9375em) {
  .page_ttl .scroll_line {
    left: 0;
    right: auto;
  }
}

.page_ttl_scroll {
  position: absolute;
  margin-top: -13.875rem;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl_scroll {
    display: none;
  }
}

.page_ttl--bnr {
  display: inline-block;
  position: absolute;
  right: 5rem;
  transform: translateY(-30px);
  z-index: 999;
  width: 9rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--bnr {
    max-width: 6.8571428571rem;
    position: fixed;
    right: 1rem;
    transform: translateY(0);
    bottom: 0.7142857143rem;
  }
}

.archive-works .page_ttl--bnr,
.page-contact .page_ttl--bnr {
  display: none;
}

/* --- page_ttl（タイトル画像なし） --- */
.page_ttl:not(.page_ttl-has_bg) {
  padding-top: 3.375rem;
  padding-bottom: 3.375rem;
  text-align: center;
  margin-bottom: 0;
  border-top: 1px solid rgba(97, 100, 119, 0.25);
  border-bottom: 1px solid rgba(97, 100, 119, 0.25);
}
@media screen and (max-width: 47.9375em) {
  .page_ttl:not(.page_ttl-has_bg) {
    padding-top: 2.7142857143rem;
    padding-bottom: 1.7142857143rem;
    margin-right: 1.5714285714rem;
  }
}
.page_ttl:not(.page_ttl-has_bg) .breadcrumbs {
  position: absolute;
  left: 0;
  top: 0;
}
.page_ttl:not(.page_ttl-has_bg) .page_ttl-en {
  font-size: 5rem;
  margin-top: 0;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl:not(.page_ttl-has_bg) .page_ttl-en {
    font-size: 3.4285714286rem;
    margin-bottom: 0.7142857143rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .page_ttl:not(.page_ttl-has_bg) .page_ttl-jp {
    margin-bottom: 0;
  }
}
.page_ttl:not(.page_ttl-has_bg) .scroll_line {
  display: none;
}

.page_ttl:not(.page_ttl-has_bg) + .page_ttl_scroll {
  margin-top: -12.25rem;
}

/* --- page_ttl（タイトル画像あり） --- */
.page_ttl-has_bg {
  height: 32.125rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-has_bg {
    height: 36.7142857143rem;
  }
}
.page_ttl-has_bg > .inner {
  height: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-has_bg > .inner {
    padding-top: 3.5714285714rem;
  }
}
.page_ttl-has_bg .breadcrumbs {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: var(--font-primary);
  font-weight: 700;
  color: var(--clr-brn);
}
.page_ttl-has_bg .breadcrumbs a {
  color: var(--clr-brn);
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-has_bg .breadcrumbs {
    top: 0;
    bottom: auto;
  }
}
.page_ttl-has_bg .breadcrumbs span span:not(:last-child)::after {
  background-color: var(--bg-brn);
}

/* --- ttl --- */
.ttl-01 {
  margin-bottom: 0.8em;
}

.ttl-02 {
  position: relative;
  letter-spacing: 0.04em;
}
.ttl-02:not([class*=mgn-btm]):not(:last-child) {
  margin-bottom: 1em;
}
@media screen and (max-width: 47.9375em) {
  .ttl-02:not([class*=mgn-btm]):not(:last-child) {
    margin-bottom: 2em;
  }
}

.ttl-03,
.ttl-04 {
  position: relative;
  letter-spacing: 0.04em;
}
.ttl-03:not([class*=mgn-btm]):not(:last-child),
.ttl-04:not([class*=mgn-btm]):not(:last-child) {
  margin-bottom: 1em;
}

.ttl-01 {
  font-size: 3rem;
  color: var(--clr-main);
}
.ttl-01 .sub {
  font-size: 2rem;
  letter-spacing: 0.08em;
}
.ttl-01 .en {
  font-size: 5.5rem;
  line-height: 0.8;
  letter-spacing: 0.04em;
  margin-bottom: 0.625rem;
}
.ttl-01 .jp {
  font-size: 1.125rem;
  letter-spacing: 0.05em;
  line-height: 1.666;
  color: var(--clr-sub);
}
@media screen and (max-width: 47.9375em) {
  .ttl-01 {
    font-size: 2.1428571429rem;
  }
  .ttl-01 .sub {
    font-size: 1.2857142857rem;
  }
  .ttl-01 .en {
    font-size: 2.8571428571rem;
  }
  .ttl-01 .jp {
    font-size: 0.9285714286rem;
  }
}
.ttl-01 + .ttl-01-sub {
  margin-top: -1.875rem;
}
.ttl-01.txt-wh + .ttl-01-sub {
  color: var(--clr-wht);
}
.ttl-01.txt-wh + .ttl-01-sub::after {
  background-color: var(--bg-wht);
}

.ttl-01.ttl-sm .en {
  font-size: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01.ttl-sm .en {
    font-size: 2.5714285714rem;
  }
}

.ttl-01-sub {
  color: var(--clr-sub);
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 1.125rem;
  letter-spacing: 0.05em;
  line-height: 2.3;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01-sub {
    font-size: 0.9285714286rem;
  }
}
.ttl-01-sub:not([class*=mgn-btm]):not(:last-child) {
  margin-bottom: 3em;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01-sub:not([class*=mgn-btm]):not(:last-child) {
    margin-bottom: 2em;
  }
}

.ttl-02 {
  font-size: 2.25rem;
  letter-spacing: 0.04em;
  line-height: 1.55;
}
@media screen and (max-width: 47.9375em) {
  .ttl-02 {
    font-size: 1.4285714286rem;
  }
}

.ttl-03 {
  font-size: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .ttl-03 {
    font-size: 1.1428571429rem;
  }
}

.ttl-04 {
  font-size: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .ttl-04 {
    font-size: 1.1428571429rem;
  }
}

.lps_sec .ttl-01 span {
  font-size: 5.5rem;
  line-height: 0.8;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 47.9375em) {
  .lps_sec .ttl-01 span {
    font-size: 2.8571428571rem;
  }
}

.lps_sec .ttl-02 {
  font-size: 2.25rem;
  line-height: 1.4;
}
@media screen and (max-width: 47.9375em) {
  .lps_sec .ttl-02 {
    font-size: 1.4285714286rem;
  }
}

/* anchor_link
********************************************** */
@media screen and (max-width: 47.9375em) {
  .anchor_link--list_wrap {
    display: none;
  }
}

@media screen and (max-width: 47.9375em) {
  .anchor_link--list {
    flex-wrap: inherit;
    white-space: nowrap;
    overflow: auto;
  }
}

.anchor_link--list li {
  min-height: 6rem;
}
@media screen and (max-width: 47.9375em) {
  .anchor_link--list li {
    width: auto;
    min-height: 4.5714285714rem;
  }
  .anchor_link--list li:first-child {
    margin-left: auto;
  }
  .anchor_link--list li:last-child {
    margin-right: auto;
  }
  .anchor_link--list li:first-child a {
    padding-left: 0;
  }
}

.anchor_link--list a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5em 2.5em;
  line-height: var(--line-height-hdr);
}
@media screen and (max-width: 47.9375em) {
  .anchor_link--list a {
    padding: 1em 1.5em;
  }
}
.anchor_link--list a::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  width: 0.75rem;
  height: 0.375rem;
  background-color: var(--clr-body);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  transition: var(--transit-default);
}
@media screen and (max-width: 47.9375em) {
  .anchor_link--list a::before {
    right: 0;
  }
}
.anchor_link--list a:hover {
  color: var(--clr-main);
}
.anchor_link--list a:hover::before {
  background-color: var(--bg-main);
}

/* ##############################################################################

    ARCHIVE

############################################################################## */
/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: 5rem;
  overflow: hidden;
}

/* --- post --- */
.post {
  position: relative;
}

.post--img .img-contain {
  max-width: 36%;
}

.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.post--date {
  color: var(--clr-main);
  font-family: var(--font-en2);
  font-weight: 600;
  letter-spacing: 0.1em;
}

.post--ttl {
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 1.125rem;
  letter-spacing: 0.05em;
  line-height: 1.8;
}

.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}

.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.8125rem;
  background-color: var(--clr-main);
  color: var(--clr-wht);
  font-size: 0.6875rem;
  padding: 0.1em 1em;
  float: left;
  white-space: nowrap;
  margin: 0.125rem;
}

.cat_list.tag_list a {
  background-color: transparent;
  color: var(--clr-main);
}
.cat_list.tag_list a::before {
  content: "#";
}

.cat_list a:hover {
  opacity: 0.7;
}

.post--txt {
  font-size: 0.875rem;
  color: rgba(97, 100, 119, 0.5);
  font-family: var(--font-primary);
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.6;
}

@media screen and (max-width: 68.75em) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 2.5rem;
    margin-right: 0;
  }
  .post--ttl {
    font-size: 1.2857142857rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .post--ttl {
    font-size: 1.1428571429rem;
  }
}
/* side_column
**************************************** */
.side_column {
  width: 16.5rem;
}
@media screen and (max-width: 68.75em) {
  .side_column {
    width: 100%;
  }
}

.side_section:not(:last-child) {
  margin-bottom: 4rem;
}

.side--ttl {
  letter-spacing: 0.04em;
  line-height: 1;
  margin-bottom: 1.5rem;
  color: var(--clr-main);
  position: relative;
  padding-bottom: 0.75rem;
}
.side--ttl::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1.5rem;
  height: 1px;
  background-color: #bebdb9;
}

.side--ttl small {
  letter-spacing: 0.075em;
  display: block;
  line-height: 1;
  margin-top: 0.5rem;
  color: var(--clr-brn);
  font-family: var(--font-primary);
  font-weight: 700;
}

/* --- list --- */
.side--list {
  padding-left: 10px;
}

.side--list a {
  display: block;
  font-size: 0.875rem;
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: 1rem;
}

.posts-side .post--img {
  margin-right: 1rem;
  width: 4rem;
  background-image: var(--ptn-be);
}

.posts-side .txtarea {
  flex: 1;
}

.posts-side .post--date {
  margin-bottom: 0.125rem;
  font-size: 0.6875rem;
}

.posts-side .post--ttl {
  line-height: 1.4;
  font-size: 1rem;
}

.side--tag .side--list li,
.side--tag .side--list a {
  display: inline-block;
}

.side--tag .side--list a {
  font-size: 0.75rem;
  position: relative;
}
.side--tag .side--list a::before {
  content: "#";
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  font-size: 0.875rem;
}
.archive_list--ttl::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: var(--transit-default);
}
.archive_list--ttl.active::after {
  transform: rotate(315deg);
}

.archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}

.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 1.5rem;
}

.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.archive-pulldown .archive_list a:hover {
  background-color: #eee;
}

.archive-pulldown .archive_list--label {
  margin-right: 0.5rem;
}

.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  color: inherit;
  font-family: var(--font-primary);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 0.04em;
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.4em;
  right: 0.8em;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}

.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}

.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* blog
**************************************** */
.posts-blog .post {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  border-bottom: 1px solid #e5e5e5;
}
.posts-blog .post:first-child {
  border-top: 1px solid #e5e5e5;
}

.posts-blog .post--txtarea {
  flex: 1;
  word-break: break-word;
}

.posts-blog .post--img {
  width: 17.5rem;
  margin-right: 2.5rem;
  background-image: var(--ptn-be);
}

.posts-blog .post--date {
  font-size: 0.8125rem;
}

.posts-blog .cat_list a {
  font-size: 0.6875rem;
  padding: 0.1em 1em;
}

@media screen and (max-width: 47.9375em) {
  .posts-blog .post {
    display: block;
    padding-top: 1.7142857143rem;
    padding-bottom: 1.7142857143rem;
  }
  .posts-blog .post--img {
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.1428571429rem;
    text-align: center;
  }
  .posts-blog .post--img img {
    max-width: 100%;
  }
}
/* news
**************************************** */
/* --- news--archive --- */
.news--archive {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-color: #111;
}

.news--archive li {
  margin-left: 8px;
  margin-right: 8px;
}

.news--archive a {
  color: var(--clr-wht);
}

/* --- news_list --- */
.news_list .post {
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-bottom: 0;
  border-bottom: 1px solid #dedede;
}
.news_list .post:first-child {
  border-top: 1px solid #dedede;
}

.news_list .post--txtarea {
  padding-right: 7.25rem;
}

@media print, screen and (min-width: 48em) {
  .news_list .post--info {
    margin-right: 2rem;
    width: 8.25rem;
    white-space: nowrap;
  }
}

.news_list .post--date {
  font-size: 1rem;
}

.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main);
}

.news_list .post--link:hover svg {
  fill: var(--clr-main);
}

.news_list .post--info.flex .post--date + .cat_list {
  margin-left: 1rem;
}

@media screen and (max-width: 47.9375em) {
  .news_list .post {
    display: block;
  }
  .news_list .post--txtarea {
    padding-right: 0;
  }
  .news_list .post--date {
    font-size: 0.8571428571rem;
  }
}
/* works
**************************************** */
.posts-works {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3%;
  row-gap: 2.5rem;
}

.posts-works .post--img {
  position: relative;
  background-image: var(--ptn-be);
  aspect-ratio: 428/610;
  max-width: 26.6875rem;
}

.posts-works .post--info {
  position: absolute;
  left: 4px;
  bottom: 4px;
}

.posts-works .post .btn_more {
  display: none;
}

@media screen and (max-width: 47.9375em) {
  .posts-works {
    grid-template-columns: 1fr;
    gap: 2.1428571429rem;
  }
  .posts-works .post--img {
    aspect-ratio: 4/3;
  }
}
/* works--pulldown
**************************************** */
.works--pulldown {
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem 3.5rem;
  border-radius: 8px;
}

.works--select select {
  font-size: 1.125rem;
  height: 3.5rem;
  width: 22.5rem;
  border: solid 1px #c3c2be;
  color: var(--clr-main);
  text-align: left;
  padding: 0 1.25rem;
  font-family: var(--font-primary);
  font-weight: 600;
}

.works--pulldown button {
  background: var(--bg-main);
  color: #fff;
  font-size: 1.125rem;
  border: 0;
  height: 3.5rem;
  width: 12.5rem;
  margin-left: 1rem;
  letter-spacing: 0.12em;
}
.works--pulldown button:hover {
  opacity: 0.7;
}

.works--pulldown .kakeru {
  width: 1.875rem;
  height: 1.875rem;
  position: relative;
  margin: 0 0.5rem;
}
.works--pulldown .kakeru::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  background-color: #bebdb9;
  transform: translateY(0.90625rem) rotate(-45deg);
}
.works--pulldown .kakeru::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #bebdb9;
  transform: translateY(-0.90625rem) rotate(45deg);
}

@media screen and (max-width: 47.9375em) {
  .works--pulldown {
    padding: 1.1428571429rem 4rem;
  }
  .works--select select {
    width: 14.2857142857rem;
    font-size: 1rem;
    height: 2.8571428571rem;
    text-align: center;
  }
  .works--pulldown button {
    height: 2.8571428571rem;
    width: 11.4285714286rem;
    margin-top: 1.7142857143rem;
    font-size: 1rem;
    margin-left: 0;
  }
  .works--pulldown .kakeru {
    width: 1.4285714286rem;
    height: 1.4285714286rem;
    margin: 0.5714285714rem 0.5714285714rem;
  }
  .works--pulldown .kakeru::before {
    transform: translateY(0.6785714286rem) rotate(-45deg);
  }
  .works--pulldown .kakeru::after {
    transform: translateY(-0.6785714286rem) rotate(45deg);
  }
}
/* ##############################################################################

    PAGE

############################################################################## */
/* next_page
********************************************** */
.next_page_list li {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.next_page_list li:nth-last-child(3) {
  background-color: #8a8570;
}
.next_page_list li:nth-last-child(2) {
  background-color: #8d684b;
}
.next_page_list li:nth-last-child(1) {
  background-color: #5a5c73;
}

.next_page {
  flex: 1;
}

.next_page a {
  overflow: hidden;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  color: var(--clr-wht);
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 3.375rem;
}
@media print, screen and (min-width: 48em) {
  .next_page a {
    height: 100%;
    min-height: 17.5rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .next_page a {
    padding: 1.7142857143rem;
    font-size: 1.1428571429rem;
  }
}
.next_page a:hover .next_page-bg {
  opacity: 0.2;
  transform: scale(1.1);
}
.next_page a::after {
  content: "";
  display: block;
  flex-shrink: 0;
  width: 1.5rem;
  aspect-ratio: 1;
  border-right: 2px solid;
  border-top: 2px solid;
  transform: rotate(45deg);
  position: absolute;
  right: 10%;
  top: 0;
  bottom: 0;
  margin: auto;
}

.next_page .en {
  display: block;
  margin-top: 1em;
  font-size: 1rem;
  font-family: var(--font-en2);
  line-height: var(--line-height-none);
  text-transform: uppercase;
  opacity: 0.5;
  color: var(--clr-wht);
  font-weight: 600;
  letter-spacing: 0.09em;
}
@media screen and (max-width: 47.9375em) {
  .next_page .en {
    font-size: 0.9285714286rem;
  }
}

.next_page-bg {
  position: absolute;
  inset: 0;
  z-index: -1;
  opacity: 0.1;
  transition: var(--transit-default);
}

/* ##############################################################################

    SEARCH

############################################################################## */
#search-list .section_pdg {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

#search-list .ttl-01 {
  margin: 4.375rem 0 0;
}

/* ##############################################################################

    SINGLE

############################################################################## */
/* --- アイキャッチ --- */
.eyecatch {
  margin-bottom: 2rem;
}

/* --- 目次 --- */
.ez-toc-debug-messages {
  display: none;
}

div#ez-toc-container {
  padding: 1.5rem;
  background-color: #fafafa;
}

.ez-toc-title-container {
  margin-bottom: 0.625rem;
}

div#ez-toc-container p.ez-toc-title {
  color: var(--clr-body);
  font-weight: bold;
  font-size: 1.25rem;
}

#ez-toc-container.counter-hierarchy ul {
  font-size: 1.0625rem;
}

#ez-toc-container.counter-hierarchy ul li {
  margin-top: 0.5rem;
}

#ez-toc-container.counter-hierarchy ul ul {
  margin-left: 1rem;
}

#ez-toc-container.counter-hierarchy ul ul a {
  position: relative;
  display: inline-block;
  padding-left: 1rem;
}
#ez-toc-container.counter-hierarchy ul ul a::before {
  content: "";
  position: absolute;
  top: 0.625rem;
  left: 0;
  width: 0.5rem;
  height: 0;
  border-bottom: 1px solid;
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}

#pass input[name=post_password] {
  padding: 0.3125rem;
  border: solid 1px #aaa;
  outline: none;
}

#pass input[type=submit] {
  padding: 0.25rem 0.375rem;
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: 0.25rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default);
}

#pass input[type=submit]:hover {
  opacity: 0.7;
}

/* works
**************************************** */
.single-works .works_deta {
  margin-top: 2.5rem;
}
.single-works .works_deta table {
  width: 100%;
}

.single-works .works_voice {
  margin-top: 3.75rem;
}

.works--related {
  border-top: 1px solid rgba(97, 100, 119, 0.25);
  padding-top: 1.875rem;
}

.works--related .ttl-04 {
  color: var(--clr-brn);
}

.has-text-align-center {
  text-align: center;
}

#works_btn a {
  margin-left: auto;
  margin-right: auto;
}

/* blog
**************************************** */
.single-blog .post--date {
  font-size: 0.8125rem;
}

/* blog--share
**************************************** */
.blog--share {
  margin-top: 2rem;
}

.blog--share_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.375rem;
}
@media screen and (max-width: 47.9375em) {
  .blog--share_list {
    grid-template-columns: repeat(2, 1fr);
  }
}

.blog--share_list a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  padding: 0.5em;
  line-height: 1;
  font-size: 0.75rem;
  font-weight: bold;
  border: 1px solid #ddd;
}

.blog--share_list li.twitter a {
  color: #2BA1F2;
}
.blog--share_list li.facebook a {
  color: #2477F2;
}
.blog--share_list li.line a {
  color: #27C754;
}
.blog--share_list li.pocket a {
  color: #EF4056;
}
.blog--share_list li.linkedin a {
  color: #2867B2;
}
.blog--share_list li.hatena a {
  color: #29A4DE;
}

.blog--share_list img {
  margin-right: 0.625rem;
}

.blog--share_list a:hover {
  opacity: 0.5;
}

/* blog--related
**************************************** */
.posts_scroll {
  overflow: auto;
  padding-bottom: 2rem;
}

.posts_scroll .post {
  display: block;
  width: 46%;
  flex-shrink: 0;
}
@media screen and (max-width: 47.9375em) {
  .posts_scroll .post {
    width: 70%;
  }
}
.posts_scroll .post:not(:last-child) {
  margin-right: 1rem;
}

.posts_scroll .post--img {
  margin-bottom: 1rem;
  background-image: var(--ptn-be);
}

.posts_scroll .post--txt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.posts_scroll::-webkit-scrollbar {
  height: 0.375rem;
}

.posts_scroll::-webkit-scrollbar-thumb {
  background-color: #999;
}

.posts_scroll::-webkit-scrollbar-track-piece {
  background-color: #eee;
}

/* works--related
**************************************** */
@media screen and (max-width: 47.9375em) {
  .works--related .posts-works {
    grid-template-columns: 1fr 1fr;
    gap: 0.7142857143rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .works--related .posts-works .post--img {
    aspect-ratio: 2/3;
  }
}

@media screen and (max-width: 47.9375em) {
  .works--related .posts-works .post--ttl {
    font-size: 0.8571428571rem;
  }
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi {
    margin-top: 1.7142857143rem;
  }
}

.wp-pagenavi .pages {
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
}

.wp-pagenavi a {
  position: relative;
  padding: 0.5em 1em;
  background-color: var(--bg-main);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--clr-wht);
}
.wp-pagenavi a:hover {
  background-color: var(--bg-main-hvr);
}

.archive .wp-pagenavi > *:not(.pages) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .archive .wp-pagenavi > *:not(.pages) {
    width: 2rem;
    height: 2rem;
  }
}

.wp-pagenavi-single a[rel=prev],
.wp-pagenavi-single a[rel=next] {
  width: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi-single a[rel=prev],
  .wp-pagenavi-single a[rel=next] {
    width: 2.2857142857rem;
  }
}

.wp-pagenavi > *:not(.pages) {
  margin-bottom: 0.25rem;
}

.wp-pagenavi > *:not(.pages):not(:last-child) {
  margin-right: 0.25rem;
}

.wp-pagenavi .extend {
  width: 1.5rem !important;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-top: 2px solid;
  border-right: 2px solid;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  width: 0.5rem;
  height: 0.5rem;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .first::after {
  transform: rotate(-135deg);
}

.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .last::before,
.wp-pagenavi .last::after {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=prev]::before {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=next]::before {
  transform: rotate(-135deg);
}

.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: -0.375rem;
}

.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: -0.375rem;
}

/* sitemap
*************************************************** */
@media print, screen and (min-width: 48em) {
  .sitemap--menu {
    column-count: 2;
    gap: 5%;
  }
}

.sitemap--menu > li {
  break-inside: avoid;
}

.sitemap--menu > li > a {
  font-size: 1.125rem;
  border-bottom: 1px solid #D3D3D3;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  color: var(--clr-brn);
}
.sitemap--menu > li > a:hover {
  border-bottom: 1px solid var(--clr-darkblu);
}

.sitemap--menu a,
.sitemap--menu span {
  display: block;
}

.sitemap--menu a {
  position: relative;
  color: var(--clr-brn);
}
.sitemap--menu a:hover {
  color: var(--clr-darkblu);
}

.sitemap_sub_nav--blc {
  padding-left: 1em;
}
.sitemap_sub_nav--blc:not(:last-child) {
  margin-bottom: 1em;
}

.sitemap--tax {
  border-bottom: 1px solid #eee;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  color: var(--clr-brn);
}

.sitemap_sub_nav a {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1.25em;
  color: var(--clr-brn);
}
.sitemap_sub_nav a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0.375rem;
  height: 0.375rem;
  color: var(--clr-brn);
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
}
.sitemap_sub_nav a:hover::before {
  color: var(--clr-darkblu);
}

/* lps_parts--button
********************************************** */
.lps_parts--button .btn:first-child {
  margin-left: auto;
}

.lps_parts--button .btn:last-child {
  margin-right: auto;
}

@media print, screen and (min-width: 48em) {
  .lps_parts--button.flex,
  .lps_parts--button .inner {
    width: fit-content;
    min-width: min(60rem, 90%);
  }
  .lps_parts--button.flex {
    margin-left: auto;
    margin-right: auto;
  }
  .lps_parts--button .btn:only-child {
    width: fit-content;
    min-width: min(17.5rem, 100%);
  }
  .lps_parts--column.column-2 + .lps_parts--button,
  .lps_parts--column.column-3 + .lps_parts--button,
  .lps_parts--column.column-4 + .lps_parts--button {
    margin-top: 0;
  }
}
/* lps_parts--column
********************************************** */
.lps_parts--column:not(.column-1) + .lps_parts--column {
  margin-top: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column:not(.column-1) + .lps_parts--column {
    margin-top: 1.7142857143rem;
  }
}

.lps_parts--column .column_item,
.lps_parts--column .text {
  display: flex;
  flex-direction: column;
}

.lps_parts--column:not(.column-1) .text {
  height: 100%;
}

.lps_parts--column .img {
  flex-shrink: 0;
}

.lps_parts--column .img + .text {
  padding-top: 1.5rem;
}

.lps_parts--column .bg-wh .text {
  padding: 6%;
}

.lps_parts--column:not(.column-1) table {
  width: 100%;
}

.lps_parts--column.column-1 .box {
  padding: 1.875rem 2.5rem;
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column,
  .lps_parts--column:not(.column-1) + .lps_parts--column {
    margin-top: 1.7142857143rem;
  }
  .lps_parts--column {
    width: 100%;
    max-width: 100%;
  }
  .lps_parts--column .flex-sp-block .column_item:not(:last-child) {
    margin-bottom: 2.5rem;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
  .lps_parts--column .text.bg-wh,
  .lps_parts--column.column-1 .box {
    padding: 1.7142857143rem;
  }
}
/* post-edit-link
********************************************** */
.post-edit-link {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 10;
  padding: 1rem;
  border-radius: 50%;
  border: 1px solid #999;
}
@media screen and (max-width: 47.9375em) {
  .post-edit-link {
    display: none;
  }
}
.post-edit-link:hover {
  background-color: #ddd;
}
.post-edit-link::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuODrOOCpOODpOODvF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDAxIDQwMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAxIDQwMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM5OTk5OTk7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzcwLjEsMjUxLjljLTUuNSwwLTEwLDQuNS0xMCwxMHY4OC43YzAsMTYuNS0xMy40LDI5LjktMzAsMzBINDkuOWMtMTYuNSwwLTI5LjktMTMuNC0zMC0zMFY5MC4zCgljMC0xNi41LDEzLjQtMjkuOSwzMC0zMGg4OC43YzUuNSwwLDEwLTQuNSwxMC0xMGMwLTUuNS00LjUtMTAtMTAtMTBINDkuOUMyMi40LDQwLjQsMCw2Mi43LDAsOTAuM3YyNjAuMwoJYzAsMjcuNiwyMi40LDQ5LjksNDkuOSw0OS45aDI4MC4yYzI3LjYsMCw0OS45LTIyLjQsNDkuOS00OS45di04OC43QzM4MC4xLDI1Ni40LDM3NS42LDI1MS45LDM3MC4xLDI1MS45eiIvPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzc2LjEsMTQuN2MtMTcuNi0xNy42LTQ2LTE3LjYtNjMuNiwwTDEzNC40LDE5Mi45Yy0xLjIsMS4yLTIuMSwyLjctMi42LDQuNGwtMjMuNCw4NC42Yy0xLDMuNSwwLDcuMiwyLjYsOS43CgljMi41LDIuNSw2LjMsMy41LDkuNywyLjZsODQuNi0yMy40YzEuNy0wLjUsMy4yLTEuMyw0LjQtMi42TDM4Ny45LDkwYzE3LjUtMTcuNiwxNy41LTQ2LDAtNjMuNkwzNzYuMSwxNC43eiBNMTU2LjIsMTk5LjNMMzAyLDUzLjUKCWw0Nyw0N0wyMDMuMiwyNDYuNEwxNTYuMiwxOTkuM3ogTTE0Ni44LDIxOC4ybDM3LjYsMzcuNmwtNTIsMTQuNEwxNDYuOCwyMTguMnogTTM3My43LDc1LjhsLTEwLjYsMTAuNmwtNDctNDdsMTAuNi0xMC42CgljOS43LTkuNywyNS42LTkuNywzNS4zLDBsMTEuNywxMS43QzM4My41LDUwLjMsMzgzLjUsNjYuMSwzNzMuNyw3NS44eiIvPgo8L3N2Zz4K");
}

/* lps_parts--faq
********************************************** */
/* --- 共通 --- */
.lps_parts--faq {
  max-width: 80.625rem;
  margin-left: auto;
  margin-right: auto;
}
.lps_parts--faq .ttl-03 {
  font-size: 1.5rem;
  margin-bottom: 0 !important;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03 {
    font-size: 1.0714285714rem;
  }
}
.lps_parts--faq .text {
  font-size: 0.9375rem;
  line-height: 1.86;
  letter-spacing: 0.03em;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--faq .text {
    display: flex;
    gap: 4.1666666667%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--faq .img {
    width: 39.0625%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--faq .mce-content-body {
    margin-bottom: 0 !important;
    flex: 1;
  }
}
.lps_parts--faq .editor-ttl2 {
  font-size: 1.25rem;
}

/* --- 通常 --- */
.lps_parts--faq:not(.faq-toggle) .faq_item {
  border: 1px solid var(--bg-main-hvr);
  background-color: var(--bg-wht);
}
.lps_parts--faq:not(.faq-toggle) .faq_item:not(:last-child) {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq:not(.faq-toggle) .faq_item:not(:last-child) {
    margin-bottom: 1.1428571429rem;
  }
}
.lps_parts--faq:not(.faq-toggle) .ttl-03,
.lps_parts--faq:not(.faq-toggle) .text {
  padding: 1.125rem 3rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq:not(.faq-toggle) .ttl-03,
  .lps_parts--faq:not(.faq-toggle) .text {
    padding: 1.7142857143rem;
  }
}
.lps_parts--faq:not(.faq-toggle) .ttl-03 {
  background-color: var(--bg-main-hvr);
  color: var(--clr-wht);
  font-size: 1.25rem;
  font-family: var(--font-primary);
  font-weight: 700;
  line-height: 1.8;
  letter-spacing: 0.08em;
}

/* --- 開閉式 --- */
.lps_parts--faq.faq-toggle .faq_item {
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  padding: 2.5rem 1.875rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq.faq-toggle .faq_item {
    padding: 1.1428571429rem 1rem;
  }
}
.lps_parts--faq.faq-toggle .faq_item:not(:first-child) {
  border-top: 0;
}
.lps_parts--faq.faq-toggle .ttl-03 {
  cursor: pointer;
  padding-right: 3.5rem;
}
.lps_parts--faq.faq-toggle .ttl-03:not(:last-child) {
  margin-bottom: 1.5em;
}
.lps_parts--faq.faq-toggle .ttl-03 .toggle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 3rem;
  height: 3rem;
  color: var(--clr-wht);
  background-color: var(--bg-main);
  border-radius: 50%;
  transition: var(--transit-default);
}
.lps_parts--faq.faq-toggle .ttl-03 .toggle::before, .lps_parts--faq.faq-toggle .ttl-03 .toggle::after {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  width: 0.75rem;
  height: 2px;
  border-bottom: 2px solid;
  transition: var(--transit-default);
}
.lps_parts--faq.faq-toggle .ttl-03 .toggle::after {
  transform: rotate(90deg);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq.faq-toggle .ttl-03 .toggle {
    width: 2.1428571429rem;
    height: 2.1428571429rem;
  }
}
.lps_parts--faq.faq-toggle .ttl-03:hover .toggle {
  opacity: 0.5;
}
.lps_parts--faq.faq-toggle .ttl-03.active .toggle {
  transform: rotate(180deg);
  background-color: rgba(56, 58, 75, 0.5);
}
.lps_parts--faq.faq-toggle .ttl-03.active .toggle::after {
  opacity: 0;
}
.lps_parts--faq.faq-toggle .text {
  padding-top: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq.faq-toggle .text {
    padding-top: 1.4285714286rem;
  }
}

/* lps_parts--flow
********************************************** */
/* --- 共通 --- */
.lps_parts--flow {
  max-width: 80.625rem;
  margin-left: auto;
  margin-right: auto;
}
.lps_parts--flow .flow_item {
  position: relative;
  padding-left: 6rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item {
    padding-left: 4rem;
  }
}
.lps_parts--flow .flow_item:not(:last-child) {
  padding-bottom: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child) {
    padding-bottom: 2.2857142857rem;
  }
}
.lps_parts--flow .flow_item:not(:last-child)::before {
  content: "";
  position: absolute;
  left: 1.9375rem;
  width: 0.125rem;
  border-right: 0.125rem solid var(--bg-main-hvr);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child)::before {
    left: 1.2857142857rem;
  }
}
.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: var(--clr-wht);
}
.lps_parts--flow .ttl-03 {
  color: var(--clr-main);
}
.lps_parts--flow .ttl-03::before {
  position: absolute;
  border-radius: 50%;
}
.lps_parts--flow .txt-wh .ttl-03 {
  color: var(--clr-wht);
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow .text {
    display: flex;
    gap: 4.1666666667%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow .img {
    width: 39.0625%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .img:not(:last-child) {
    margin-bottom: 1.7142857143rem;
  }
}
.lps_parts--flow .mce-content-body {
  margin-bottom: 0 !important;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow .mce-content-body {
    flex: 1;
  }
}
.lps_parts--flow .mce-content-body p {
  line-height: 1.875;
}

/* --- 通常 --- */
.lps_parts--flow:not(.flow-num) .flow_item:not(:last-child)::before {
  top: 2.5rem;
  bottom: 0.25rem;
}
.lps_parts--flow:not(.flow-num) .ttl-03::before {
  content: "";
  top: 0.375rem;
  left: -4.75rem;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.125rem solid var(--bg-main-hvr);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow:not(.flow-num) .ttl-03::before {
    left: -3.2857142857rem;
  }
}

/* --- 連番 --- */
.lps_parts--flow.flow-num .flow_item {
  counter-increment: number;
  display: flex;
  align-items: center;
  padding-left: 0;
  border-top: solid 1px var(--clr-main);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-right: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.flow-num .flow_item {
    flex-direction: column;
    padding-right: 0.7142857143rem;
    padding-top: 1.7142857143rem;
    padding-bottom: 0.8571428571rem;
    align-items: flex-start;
  }
}
.lps_parts--flow.flow-num .flow_item:last-child {
  border-bottom: solid 1px var(--clr-main);
}
.lps_parts--flow.flow-num .flow_item:not(:last-child)::before {
  top: auto;
  bottom: -0.75rem;
  width: 3.75rem;
  height: 0.75rem;
  border: 0;
  background: var(--bg-main);
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  left: 0;
  right: 0;
  margin: auto;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.flow-num .flow_item:not(:last-child)::before {
    bottom: -0.5714285714rem;
    width: 1.8571428571rem;
    height: 0.5714285714rem;
  }
}
.lps_parts--flow.flow-num .flow_item::after {
  content: "";
  width: 1px;
  height: calc(100% - 5rem);
  position: absolute;
  right: 0;
  top: 2.5rem;
  background-color: rgba(97, 100, 119, 0.2);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.flow-num .flow_item::after {
    content: none;
  }
}
.lps_parts--flow.flow-num .flow_item .num {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background: transparent;
  border: 1px solid var(--clr-main);
  color: var(--clr-main);
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  margin-right: 3.75rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.flow-num .flow_item .num {
    position: absolute;
    left: 0;
    top: 1rem;
    margin-right: 0;
    width: 3.1428571429rem;
    height: 3.1428571429rem;
  }
}
.lps_parts--flow.flow-num .flow_item .num::before {
  content: "STEP";
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  line-height: 1;
  font-family: var(--font-en2);
  font-weight: 600;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.flow-num .flow_item .num::before {
    font-size: 0.7142857143rem;
  }
}
.lps_parts--flow.flow-num .flow_item .num::after {
  content: counter(number, decimal-leading-zero);
  font-size: 2rem;
  letter-spacing: 0.08em;
  line-height: 1;
  font-family: var(--font-en3);
  font-weight: 600;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.flow-num .flow_item .num::after {
    font-size: 1.4285714286rem;
  }
}
.lps_parts--flow.flow-num .ttl-03 {
  font-family: var(--font-primary);
  font-weight: 700;
  width: 11.25rem;
  margin-right: 3.75rem;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow.flow-num .ttl-03 {
    font-size: 1.25rem;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.flow-num .ttl-03 {
    width: auto;
    padding-left: 5rem;
    margin-right: 0;
    margin-bottom: 1.5em;
  }
}
.lps_parts--flow.flow-num .txt-wh .ttl-03::after {
  color: var(--clr-main);
  background-color: var(--bg-wht);
}
.lps_parts--flow.flow-num .text {
  width: 58%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.flow-num .text {
    width: 100%;
  }
}

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transit-default);
}

.icon-new_tab {
  width: 0.875rem;
  height: 0.875rem;
}

.icon-pdf {
  width: 1.6875rem;
  height: 0.75rem;
}

.lps_sec {
  position: relative;
  overflow: hidden;
}
.lps_sec:nth-child(odd) .bg-wh {
  background-color: var(--bg-off_wht);
}
.lps_sec:nth-child(even) {
  background-image: var(--ptn-be);
}

.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}

.lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
  margin-top: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts + .ttl-02,
  .lps_parts--button + .ttl-02,
  .lps_parts--button + .lps_parts,
  .lps_parts + .lps_parts {
    margin-top: 2.8571428571rem;
  }
}

.lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
  margin-top: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column,
  .lps_parts + .lps_parts--button {
    margin-top: 1.1428571429rem;
  }
}

.lps_parts--button + .lps_parts--button {
  margin-top: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--button + .lps_parts--button {
    margin-top: 0.5714285714rem;
  }
}

.lps_parts iframe {
  width: 100%;
}

.lps_parts .text:not(:last-child) {
  margin-bottom: 1.5rem;
}

.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}

.lps_parts--column .btn a {
  margin-left: auto;
  margin-right: auto;
}

.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts .mce-content-body:not(:last-child) {
    margin-bottom: 1.7142857143rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .lps_parts .btn {
    text-align: center;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
}
/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .bg-wh .img_text--child:only-child,
.lps_parts--img_text .text,
.lps_parts--img_text .img_text--txt table {
  width: 100%;
}

.lps_parts--img_text .img_text--child .img {
  height: 100%;
}

.lps_parts--img_text .img_text--img:only-child .img {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .img_text--txt {
    padding-top: 2.5rem;
  }
}

.lps_parts--img_text .ttl-03 {
  font-size: 2.25rem;
  line-height: 1.555;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .ttl-03 {
    font-size: 1.5714285714rem;
  }
}

.lps_parts--img_text .bg-wh .ttl-03 {
  font-size: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .bg-wh .ttl-03 {
    font-size: 1.4285714286rem;
  }
}

.lps_parts--img_text .bg-wh .img_text--txt {
  padding: 5.3333333333%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .bg-wh .img_text--txt {
    padding: 1.7142857143rem;
  }
}

@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner:not(.flex-reverse) .img_text--txt {
    padding-left: 5.3333333333%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner.flex-reverse .img_text--txt {
    padding-right: 5.3333333333%;
  }
}

.lps_parts--img_text .ttl_deco--wrap {
  display: inline-flex;
  align-items: flex-start;
  color: var(--clr-main);
  font-weight: 600;
  position: absolute;
  left: 0;
  top: 0;
}
.lps_parts--img_text .ttl_deco--wrap .num {
  line-height: 0.6;
  font-size: 10rem;
  font-family: var(--font-en3);
  font-weight: 500;
  letter-spacing: 0.045em;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .ttl_deco--wrap .num {
    font-size: 5rem;
  }
}
.lps_parts--img_text .ttl_deco--wrap .txt {
  display: inline-block;
  font-size: 0.6875rem;
  letter-spacing: 0.16em;
  position: relative;
  font-family: var(--font-en);
  font-weight: 600;
  padding-bottom: 0.625rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .ttl_deco--wrap .txt {
    margin-left: 0.4285714286rem;
  }
}
.lps_parts--img_text .ttl_deco--wrap .txt::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1.5rem;
  height: 1px;
  background-color: var(--bg-main);
}

.lps_parts--img_text .ttl-03:has(.ttl_deco--wrap) {
  padding-left: 11.875rem;
  padding-top: 3.875rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .ttl-03:has(.ttl_deco--wrap) {
    padding-left: 6.4285714286rem;
    padding-top: 2.1428571429rem;
  }
}

/* --- L --- */
.lps_parts--img_text .inner-lg {
  max-width: 88.75rem;
}
.lps_parts--img_text .inner-lg .img_text--img {
  position: absolute;
  top: 0;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg .img_text--img {
    height: 100%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .img_text--img {
    position: relative;
  }
}
.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--img {
  left: 0;
}
.lps_parts--img_text .inner-lg.flex-reverse .img_text--img {
  right: 0;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg .img_text--txt {
    min-height: 37.9775rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .img_text--txt {
    margin-right: auto;
  }
}
.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
  margin-left: auto;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
    padding-left: 7.5549450549%;
    padding-right: 0;
  }
}
.lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
  margin-right: auto;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
    padding-left: 0;
    padding-right: 7.5549450549%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2.8571428571rem;
  }
}
.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: 2.25rem;
  line-height: 1.555;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .ttl-03 {
    font-size: 1.5714285714rem;
  }
}

@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .img_text--txt {
    display: flex;
    align-items: center;
  }
  .lps_parts--img_text .inner-lg .img_text--img:only-child {
    position: relative;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .btn {
    text-align: center;
  }
  .lps_parts--img_text .bg-wh + .bg-wh {
    margin-top: 0;
  }
  .lps_parts--img_text .inner-lg .img_text--child .img {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}
/* lps_parts--slide
********************************************** */
.lps_parts--slide .slick-list {
  margin-bottom: 32px;
}

.lps_parts--slide .img img {
  display: inline-block;
}

.lps_parts--slide .img + .text {
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .img + .text {
    margin-top: 16px;
  }
}

.lps_parts--slide .slick-arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  text-decoration: none;
  color: var(--clr-main);
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-arrow {
    width: 56px;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow {
    width: 40px;
  }
}
.lps_parts--slide .slick-arrow::after {
  content: "";
  display: block;
  width: 1.625rem;
  height: 1.625rem;
  transform: rotate(45deg);
  border-top: 2px solid;
  border-right: 2px solid;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-arrow::after {
    width: 1.25rem;
    height: 1.25rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow::after {
    width: 1.1428571429rem;
    height: 1.1428571429rem;
  }
}

.lps_parts--slide .slick-prev {
  left: -60px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-prev {
    left: 0;
  }
}
.lps_parts--slide .slick-prev::after {
  content: "";
  transform: rotate(-135deg);
}

.lps_parts--slide .slick-next {
  right: -60px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-next {
    right: 0;
  }
}

.lps_parts--slide .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-dots {
    position: relative;
    bottom: 4px;
  }
}

.lps_parts--slide .slick-dots li {
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--clr-main);
  opacity: 0.5;
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
  margin: 6px;
}
.lps_parts--slide .slick-dots li.slick-active {
  background-color: var(--clr-main);
  opacity: 1;
}
.lps_parts--slide .slick-dots li:hover {
  opacity: 0.7;
}

.lps_parts--slide .slide-carousel .slick-list {
  margin-inline: -1rem;
}
.lps_parts--slide .slide-carousel .slick-slide {
  margin-inline: 1.875rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slide-carousel .slick-slide {
    margin-inline: 1rem;
    width: 20rem;
  }
}
.lps_parts--slide .slide-carousel .slick-prev {
  left: calc(50% - 34.5rem);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slide-carousel .slick-prev {
    left: calc(50% - 12rem);
  }
}
.lps_parts--slide .slide-carousel .slick-next {
  right: calc(50% - 34.5rem);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slide-carousel .slick-next {
    right: calc(50% - 12rem);
  }
}

.lps_parts--slide .slide-extra_large {
  margin-inline: calc(50% - 50vw);
}
.lps_parts--slide .slide-extra_large .slick-slide {
  position: relative;
}
.lps_parts--slide .slide-extra_large .img {
  width: 100%;
  height: 800px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slide-extra_large .img {
    height: 400px;
  }
}
.lps_parts--slide .slide-extra_large .text {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: auto;
  margin: auto;
}
.lps_parts--slide .slide-extra_large .ttl-03,
.lps_parts--slide .slide-extra_large .mce-content-body {
  color: var(--clr-wht);
}
.lps_parts--slide .slide-extra_large .slick-list {
  margin-bottom: 0;
}
.lps_parts--slide .slide-extra_large .slick-arrow {
  color: var(--clr-wht);
}
.lps_parts--slide .slide-extra_large .slick-prev {
  left: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slide-extra_large .slick-prev {
    left: 0;
  }
}
.lps_parts--slide .slide-extra_large .slick-next {
  right: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slide-extra_large .slick-next {
    right: 0;
  }
}
.lps_parts--slide .slide-extra_large .slick-dots {
  position: absolute;
  bottom: 70px;
}
.lps_parts--slide .slide-extra_large .slick-dots li {
  background-color: var(--bg-wht);
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}