@use '../abstracts' as *;

/* lps_parts--flow
********************************************** */
/* --- 共通 --- */
.lps_parts--flow {
  max-width: rem(1290);
  @include auto-margin;
  .flow_item {
    position: relative;
    padding-left: rem(96);
    @include mq(sp) {
      padding-left: sprem(56);
    }
    &:not(:last-child) {
      padding-bottom: rem(64);
      @include mq(sp) {
        padding-bottom: sprem(32);
      }
      &::before {
        content: '';
        position: absolute;
        left: rem(31);
        width: rem(2);
        border-right: rem(2) solid var(--bg-main-hvr);
        @include mq(sp) {
          left: sprem(18);
        }
      }
    }
    &.txt-wh:not(:last-child)::before {
      border-color: var(--clr-wht);
    }
  }
  .ttl-03 {
    color: var(--clr-main);
    &::before {
      position: absolute;
      border-radius: 50%;
    }
  }
  .txt-wh .ttl-03 {
    color: var(--clr-wht);
  }
  .text {
    @include mq(sp, min, ps) {
      display: flex;
      gap: perc(32,768,'%');
    }
  }
  .img {
    @include mq(sp, min, ps) {
      width: perc(300,768,'%');
    }
    @include mq(sp) {
      &:not(:last-child) {
        margin-bottom: sprem(24);
      }
    }
  }
  .mce-content-body {
    margin-bottom: 0 !important;
    @include mq(sp, min, ps) {
      flex: 1;
    }
  }
  .mce-content-body p {
    line-height: 1.875;
  }
}

/* --- 通常 --- */
.lps_parts--flow:not(.flow-num) {
  .flow_item:not(:last-child)::before {
    top: rem(40);
    bottom: rem(4);
  }
  .ttl-03::before {
    content: '';
    top: rem(6);
    left: rem(-76);
    @include rect(24);
    border: rem(2) solid var(--bg-main-hvr);
    @include mq(sp) {
      left: sprem(-46);
    }
  }
}

/* --- 連番 --- */
.lps_parts--flow.flow-num {
  .flow_item {
    counter-increment: number;
    display: flex;
    align-items: center;
    padding-left: 0;
    border-top: solid 1px var(--clr-main);
    padding-top: rem(40);
    padding-bottom: rem(40);
    padding-right: rem(80);
    @include mq(sp) {
      flex-direction: column;
      padding-right: sprem(10);
      padding-top: sprem(24);
      padding-bottom: sprem(12);
      align-items: flex-start;
    }
    &:last-child {
      border-bottom: solid 1px var(--clr-main);
    }
    &:not(:last-child)::before {
      top: auto;
      bottom: rem(-12);
      width: rem(60);
      height: rem(12);
      border: 0;
      background: var(--bg-main);
      clip-path: polygon(100% 0, 0 0, 50% 100%);
      left: 0;
      right: 0;
      margin: auto;
      @include mq(sp) {
        bottom: sprem(-8);
        width: sprem(26);
        height: sprem(8);
      }
    }
    &::after {
      content: "";
      width: 1px;
      height: calc(100% - 5rem);
      position: absolute;
      right: 0;
      top: 2.5rem;
      background-color: rgba($color: $bg-main, $alpha: .2);
      @include mq(sp) {
        content: none;
      }
    }
    .num {
      @include center-flex;
      @include rect(96);
      border-radius: 50%;
      background: transparent;
      border: 1px solid var(--clr-main);
      color: var(--clr-main);
      aspect-ratio: 1/1;
      display: flex;
      flex-direction: column;
      margin-right: rem(60);
      @include mq(sp) {
        position: absolute;
        left: 0;
        top: sprem(14);
        margin-right: 0;
        width: sprem(44);
        height: sprem(44);
      }
      &::before {
        content: "STEP";
        font-size: rem(12);
        letter-spacing: .05em;
        line-height: 1;
        font-family: var(--font-en2);
        font-weight: 600;
        @include mq(sp) {
          font-size: sprem(10);
        }
      }
      &::after {
        content: counter(number, decimal-leading-zero);
        font-size: rem(32);
        letter-spacing: .08em;
        line-height: 1;
        font-family: var(--font-en3);
        font-weight: 600;
        @include mq(sp) {
          font-size: sprem(20);
        }
      }
    }
  }
  .ttl-03 {
    font-family: var(--font-primary);
    font-weight: 700;
    width: rem(180);
    margin-right: rem(60);
    @include mq(sp, min, ps) {
      font-size: rem(20);
      margin-bottom: 0;
    }
    @include mq(sp) {
      width: auto;
      padding-left: sprem(70);
      margin-right: 0;
      margin-bottom: 1.5em;
    }
  }
  .txt-wh .ttl-03::after {
    color: var(--clr-main);
    background-color: var(--bg-wht);
  }
  .text {
    width: 58%;
    @include mq(sp) {
      width: 100%;
    }
  }
}