@use '../abstracts' as *;

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: rem(8);
}
.btn a {
  overflow: hidden;
  font-size: rem(14);
  border-top: 2px solid rgba($color: #616477, $alpha: .5);
  border-bottom: 2px solid rgba($color: #616477, $alpha: .5);
  text-align: center;
  font-family: var(--font-primary);
  font-weight: 700;
  height: auto;
  line-height: 1.3;
  padding: rem(12);
  position: relative;
  z-index: 1;
  display: block;
  width: fit-content;
  min-width: min(rem(168),100%);
  color: var(--clr-main);
  @include mq(sp) {
    line-height: 1.3;
    padding: sprem(12);
    height: auto;
    min-width: min(sprem(168), 100%);
    font-size: sprem(14);
    margin-left: auto;
    margin-right: auto;
  }
  &:hover {
    background-color: var(--bg-main-hvr);
    border-top: 2px solid var(--bg-main-hvr);
    border-bottom: 2px solid var(--bg-main-hvr);
    color: var(--clr-wht);
  }
  svg {
    right: rem(8);
    fill: var(--clr-main);
  }
  &:hover svg {
    fill: var(--clr-wht);
  }
  span {
    display: block;
    line-height: rem(44);
  }
  span.sp-none {
    display: inline-block;
    @include mq(sp) {
      display: none;
    }
  }
}
.flex > .btn a { min-width: 100%; }
.btn.btn-ctr a {
  @include auto-margin;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--clr-main);
  background-color: var(--bg-wht);
  &:hover {
    color: var(--clr-wht);
    background-color: var(--bg-main);
  }
  svg {
    fill: var(--clr-main);
  }
  &:hover svg {
    fill: var(--clr-wht);
  }
}

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--bg-cta);
  &:hover {
    background-color: var(--bg-cta-hvr);
  }
}

/* --- btn-sm --- */
.btn-sm a {
  font-size: rem(13);
  width: rem(150);
  padding: rem(8);
  font-family: var(--font-en2);
  font-weight: 600;
  @include mq(sp) {
    width: sprem(150);
  }
}

@include mq(sp) {
  .flex > .btn:not(:last-child) {
    margin-bottom: sprem(8) !important;
  }
}

/* --- btn-cir --- */
.btn-cir a {
  border-radius: 9999px;
  color: var(--clr-wht);
  background-color: var(--bg-cta);
  font-size: rem(20);
  letter-spacing: .06em;
  border: 0;
  padding: rem(24);
  line-height: 1.4;
  height: auto;
  &:hover {
    background-color: var(--bg-cta-hvr);
    border: 0;
  }
}
/* --- btn-cir --- */
.btn-icon a {
  padding-right: rem(32);
}