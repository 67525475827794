@use "sass:math";
@use '../abstracts' as *;

@for $i from 0 through 150 {
  .mgn-#{$i} {margin: math.div($i, $fz-pc)+rem;}
  .mgn-top#{$i} {margin-top: math.div($i, $fz-pc)+rem;}
  .mgn-rgt#{$i} {margin-right: math.div($i, $fz-pc)+rem;}
  .mgn-btm#{$i} {margin-bottom: math.div($i, $fz-pc)+rem;}
  .mgn-lft#{$i} {margin-left: math.div($i, $fz-pc)+rem;}
}

@include mq(sp) {
  @for $i from 0 through 150 {
    $halfValue: math.div(math.div($i, 2), $fz-sp); // $iの半分の値を計算
    .mgn-#{$i} {margin: #{$halfValue}rem;}
    .mgn-top#{$i} {margin-top: #{$halfValue}rem;}
    .mgn-rgt#{$i} {margin-right: #{$halfValue}rem;}
    .mgn-btm#{$i} {margin-bottom: #{$halfValue}rem;}
    .mgn-lft#{$i} {margin-left: #{$halfValue}rem;}
  }  
}

@for $i from 0 through 15 {
  .mgn-#{$i}_em {margin: #{$i}em;}
  .mgn-top#{$i}_em {margin-top: #{$i}em;}
  .mgn-rgt#{$i}_em {margin-right: #{$i}em;}
  .mgn-btm#{$i}_em {margin-bottom: #{$i}em;}
  .mgn-lft#{$i}_em {margin-left: #{$i}em;}
}