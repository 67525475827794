@use '../abstracts' as *;

/* hero
********************************************** */
.hero {
  position: relative;
  height: rem(960);
  padding-left: rem(80);
  padding-right: rem(80);
}
.hero--catch {
  position: absolute;
  top: 0;
  left: 0;
}
.hero_slides {
  overflow: hidden;
}
.hero_slide {
  overflow: hidden;
}
.hero_slide-sp {
  display: none !important;
}
.hero_slides,
.hero_slide,
.hero_slide .slick-list,
.hero_slide .slick-track,
.hero_slide .slick-slider,
.hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}
.hero_slide .slick-list {
  position: relative;
  overflow: hidden;
}
.hero--dots {
  position: absolute;
  left: 0;
  top: 0;
  width: rem(80);
  height: 100%;
}
.hero--dots li {
  writing-mode: vertical-rl;
  font-feature-settings: initial;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: rem(30);
  font-size: rem(13);
  color: var(--clr-sub);
  transition: var(--transit-default);
  &.slick-active {
    color: var(--clr-main);
  }
  .dot {
    cursor: pointer;
    transition: var(--transit-default);
    &:hover {
      color: var(--clr-main);
    }
  }
}
.hero--bar {
  height: 100%;
  width: 4px;
  position: absolute;
  left: 5rem;
  top: 0;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0, 0);
    background: var(--clr-main);
  }
  @include mq(sp) {
    height: 100%;
    width: 100%;
    height: 4px;
  }
}
.slick-active .hero--bar::before {
  animation: slider-border 5.5s linear forwards;
}


@include mq(sp) {
  .hero {
    height: sprem(540);
    padding-left: sprem(24);
    padding-right: sprem(24);
  }
  @supports (-webkit-touch-callout: none) {
    .hero {
      height: -webkit-fill-available;
    }
  }
  .hero_slide-pc {
    display: none !important;
  }
  .hero_slide-sp {
    display: block !important;
  }
  .hero--dots-sp {
    position: relative;
    width: 100%;
    height: sprem(30);
  }
  .hero--dots-sp .slick-dots {
    display: flex;
  }
  .hero--dots-sp li {
    font-size: sprem(13);
    line-height: sprem(30);
    color: var(--clr-sub);
    transition: var(--transit-default);
    &.slick-active {
      color: var(--clr-main);
    }
    + li {
      margin-left: sprem(20);
    }
  }
  .hero--bar {
    width: 100%;
    height: 2px;
    left: 0;
  }
  .slick-active .hero--bar::before {
    animation: slider-border-l 5.5s linear forwards;
  }
}