@use '../abstracts' as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: rem($header-hgt);
  z-index: z(header);
  transition: var(--transit-default);
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: rem(80);
    padding-right: rem(80);
  }
  @include mq(med) {
    height: 64px;
    & > .inner {
      padding-left: sprem(24);
    }
  }
}
.header .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: var(--bg-wht);
  transition: var(--transit-default);
}
.page-contact-simple .header {
  position: absolute;
}
.header-sm {
  @include mq(med, min, ps) {
    height: rem($header-hgt-fx);
  }
  .bg {
    backdrop-filter: blur(8px);
    background-color: rgba($color: #fff, $alpha: .7);
  }
}

/* --- logo -- */
.header--logo {
  max-width: 105px;
  svg {
    width: 100%;
    height: 100%;
    fill: var(--clr-main);
  }
  @include mq(med) {
    max-width: 70px;
    position: fixed;
    height: sprem($header-hgt-sp);
    display: flex;
    align-items: center;
    z-index: 9999;
    svg {
      width: sprem(70);
      height: sprem(24);
    }
  }
}
.header.active .header--logo svg {
  @include mq(med) {
    fill: #fff;
  }
}
.header--logo a,
.footer--logo a {
  display: block;
  &:hover {
    opacity: .7;
  }
}
.header--logo img {
  @include mq(med) {
    max-width:  rem(68);
    max-height: rem(22);
  }
}

/* gnav
********************************************** */
.gnav,
.gnav ul,
.gnav li,
.gnav a {
  @include mq(med, min, ps) {
    height: 100%;
  }
}
.gnav {
  @include mq(med, min, ps) {
    display: flex;
  }
  @include mq(med) {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 300;
    width: 100%;
    height:100%;
    margin: 0;
    padding: sprem(78) 7% sprem(24);
    opacity: 0;
    pointer-events: none;
    overflow: scroll;
    background-color: var( --bg-brn);
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);
    &.active {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(0);
    }
  }
}
.gnav li {
  display: inline-flex;
}
.gnav a {
  @include center-flex;
  font-family: var(--font-primary);
  font-weight: 700;
  letter-spacing: .04em;
  @include mq(med) {
    justify-content: flex-start;
    color: var(--clr-wht);
  }
}

/* --- menu --- */
.gnav--menu,
.gnav--cta {
  @include mq(med) {
    display: block;
  }
}
.gnav--menu li {
  @include mq(med) {
    position: relative;
    display: block;
    width: max-content;
  }
}
.gnav--link {
  padding: 0 rem(24);
  @media screen and (max-width: 1340px) {
    padding: 0 rem(16);
  }
  @include mq(med) {
    display: block;
    padding: sprem(6) sprem(14);
    font-size: sprem(16);
    color: var(--clr-wht);
    text-align: left
  }
  @include mq(med, min, ps) {
    text-align: center;
  }
  .txt--wrap {
    position: relative;
    line-height: 1.8;
    display: inline-block;
    &::after {
      position: absolute;
      left: 0;
      content: '';
      width: 100%;
      height: 1px;
      background: var(--bg-main);
      bottom: 0;
      transform: scale(0, 1);
      transform-origin: right top;
      transition: transform 0.3s;
    }
    @include mq(med) {
      line-height: 1.6;
    }
  }
}
.gnav--menu > li.current-menu-item > .gnav--link {
  @include mq(med, min, ps) {
    .txt--wrap::after {
      transform: scale(1, 1);
    }
  }
}
.gnav--menu li .gnav--link:not([href="javascript:void(0);"]):hover {
  @include mq(med, min, ps) {
    .txt--wrap::after {
      transform: scale(1, 1);
      transform-origin: left top;
    }
  }
}
.gnav .sns--list {
  @include mq(med) {
    justify-content: flex-start;
  }
}
.gnav .sns--list a {
  @include mq(med) {
    width: max-content;
    padding: 1.125rem 1rem;
  }
  svg {
    fill: var(--clr-main);
    @include mq(med) {
      fill: var(--clr-wht);
      width: sprem(24);
      height: sprem(24);
      margin-right: sprem(14);
    }
  }
  .ttl {
    @include mq(med) {
      display: block;
      color: var(--clr-wht);
    }
  }
}
.gnav--info {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    text-align: right;
    color: var(--clr-wht);
    font-size: sprem(12);
    font-family: var(--font-primary);
    font-weight: 700;
    letter-spacing: .02em;
    margin-top: sprem(24);
    position: absolute;
    right: 7%;
    bottom: 2%;
  }
}
.gnav--info .logo {
  @include mq(med) {
    max-width: sprem(58);
    margin-left: auto;
    margin-right: 0;
    margin-bottom: sprem(12);
    svg {
      fill: var(--clr-wht);
    }
  }
}
.gnav--info .gnav--tel li + li {
  @include mq(med) {
    padding-left: sprem(10);
  }
}
.gnav--info .mail {
  @include mq(med) {
    display: inline-flex;
  }
}
.gnav--info .copyright {
  @include mq(med) {
    font-size: sprem(11);
    color: var(--clr-wht);
    margin-left: 0;
    margin-top: sprem(2);
  }
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
}
.head_sub_nav--wrap {
  display: block;
  width: 96%;
  position: absolute;
  top: rem(100);
  left: 5%;
  z-index: 100;
  transition: var(--transit-default);
  opacity: 0;
  background-color: var(--bg-wht);
  pointer-events: none;
  padding-bottom: rem(14);
  @include mq(med) {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
    padding-bottom: 0;
  }
}
.header-sm .head_sub_nav--wrap {
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.7);
  top: rem($header-hgt-fx);
}
.gnav .head_sub_nav--wrap li {
  display: block;
}
.gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
  @include mq(med, min, ps) {
    opacity: 1;
    pointer-events: auto;
  }
}
.menu-item-has-children.active .head_sub_nav--wrap {
  @include mq(med) {
    opacity: 1;
    pointer-events: auto;
  }
}
.head_sub_nav {
  @include mq(med) {
    position: relative;
  }
}
.head_sub_nav a {
  display: block;
  padding: rem(10) rem(24) rem(10) rem(24);
  text-align: center;
  position: relative;
  @include mq(med) {
    padding-left: rem(32) !important;
    font-size: sprem(15);
    padding: sprem(6) sprem(10) sprem(6) sprem(10);
  }
}
.gnav_sub_toggle {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: absolute;
    top: 0;
    right: rem(-30);
    @include sprect(40);
    transition: var(--transit-default);
    color: var(--clr-wht);
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: sprem(12);
      height: 2px;
      border-bottom: 2px solid;
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(180deg);
    }
  }
}

/* ---btn --- */
.gnav_btn {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: fixed;
    top: 0;
    @include sprect(64);
    cursor: pointer;
    z-index: 9999;
  }
}
.gnav_btn {
  @include mq(med) {
    right: 0;
    transition: var(--transit-default);
    &.active {
      background-color: var( --bg-brn);
    }
  }
}
.gnav_btn--lines {
  @include mq(med) {
    position: relative;
    @include sprect(24,16);
  }
}
.gnav_btn--lines span {
  @include mq(med) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--clr-main);
    transition: var(--transit-default);
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}
.active .gnav_btn--lines span {
  @include mq(med) {
    background-color: var(--clr-wht);
    &:nth-of-type(1) {
      transform: translateY(sprem(7)) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(sprem(-7)) rotate(45deg);
    }
  }
}
.tel_btn {
  @include mq(med) {
    right: sprem(64);
  }
  a {
    @include mq(med) {
      display: flex !important;
      width: 100%;
      height: 100%;
    }
  }
  svg {
    @include mq(med) {
      @include sprect(24);
      fill: var(--clr-main);
    }
  }
}

/* ---CTA --- */
.gnav--cta li:not(:last-child) {
  @include mq(med) {
    margin-bottom: sprem(16);
  }
}
.gnav--tel,
.gnav--contact {
  margin-left: rem(8);
}
.gnav--tel a {
  display: flex !important;
  flex-direction: column;
}
.gnav--tel .tel {
  font-size: rem(20);
}
.gnav--tel .num {
  font-size: rem(24);
}
.gnav--tel .hours {
  font-size: rem(14);
}
.gnav--contact {
  @include mq(med) {
    margin-left: 0;
  }
}
.gnav--contact a {
  padding: rem(24);
  @include mq(med, min, ps) {
    border-radius: 0;
  }
  @include mq(med) {
    padding: sprem(10) sprem(16);
    margin-left: auto;
    margin-right: auto;
  }
}
.gnav--contact svg {
  @include rect(21,16);
  fill: #fff;
  margin-right: rem(8);
}